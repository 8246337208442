<div class="accordion">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" [id]="'accordion-btn-header-team-confidence'">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#accordion-btn-collapse-team-confidence'"
        aria-expanded="true"
        [attr.aria-controls]="'accordion-btn-collapse-team-confidence'">
        <div class="container-left-accordion-title">
          <span class="material-icons-outlined color_red" aria-hidden="true" color="accent">
            {{ "map" }}
          </span>
          {{ "Team Confidence" }}
        </div>
      </button>
    </h4>
    <div
      [id]="'accordion-btn-collapse-team-confidence'"
      class="accordion-collapse show"
      [attr.aria-labelledby]="'accordion-btn-header-team-confidence'">
      <div class="accordion-body">
        <div class="spinner-container" *ngIf="dataLoading">
          <div class="spinner-border text-primary" output="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <div class="toggle-buttons no-export">
          <div class="segmented-control-container">
            <button
              *ngFor="let quarter of quarters"
              class="segmented-control-item"
              [ngClass]="{selected: selectedQuarter === quarter}"
              (click)="selectQuarter(quarter)"
              [attr.data-cypress]="'quarter-option-' + quarter">
              {{ quarter }}
            </button>
          </div>
        </div>

        <div class="table-container">
          <table class="table team-confidence-table">
            <caption>
              Team Confidence
            </caption>
            <thead>
              <tr>
                <th scope="col" style="width: 10%" class="sortable-column" (click)="onSort('productName')" (keydown)="onSort('productName')">
                  <div class="column-header">
                    Product
                    <div class="sort-icons">
                      <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'productName' && sortDirection === 'asc'}">
                        expand_less
                      </span>
                      <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'productName' && sortDirection === 'desc'}">
                        expand_more
                      </span>
                    </div>
                  </div>
                </th>
                <th scope="col" style="width: 10%" class="sortable-column" (click)="onSort('siteName')" (keydown)="onSort('siteName')">
                  <div class="column-header">
                    Site
                    <div class="sort-icons">
                      <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'siteName' && sortDirection === 'asc'}">
                        expand_less
                      </span>
                      <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'siteName' && sortDirection === 'desc'}">
                        expand_more
                      </span>
                    </div>
                  </div>
                </th>
                <th scope="col" style="width: 11%" class="sortable-column" (click)="onSort('confidence')" (keydown)="onSort('confidence')">
                  <div class="column-header">
                    Confidence
                    <div class="sort-icons">
                      <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'confidence' && sortDirection === 'asc'}">
                        expand_less
                      </span>
                      <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'confidence' && sortDirection === 'desc'}">
                        expand_more
                      </span>
                    </div>
                  </div>
                </th>
                <th scope="col" [ngStyle]="{width: isWriter ? 'calc(65% - 110px);' : '65%;'}">Comment</th>
                <th scope="col" *ngIf="isWriter" style="width: 110px"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let confidenceRow of teamConfidence[selectedQuarter]"
                [attr.data-cypress-product]="confidenceRow.productName"
                [attr.data-cypress-site]="confidenceRow.siteName">
                <td>{{ confidenceRow.productName }}</td>
                <td>{{ confidenceRow.siteName }}</td>
                <td [attr.data-cypress]="'confidence-' + confidenceRow.confidence">
                  <span
                    class="material-icons-outlined confidence-icon"
                    [ngStyle]="{color: getConfidenceColor(confidenceRow.confidence), cursor: isWriter ? 'pointer' : 'default'}"
                    [matTooltip]="confidenceRow.confidence"
                    [matMenuTriggerFor]="isWriter ? menuConfidence : null"
                    matTooltipPosition="right">
                    {{ getIconFromConfidence(confidenceRow.confidence) }}
                  </span>

                  <mat-menu #menuConfidence="matMenu" xPosition="after" yPosition="below">
                    <ng-container *ngFor="let confidence of ['On Track', 'Attention Point', 'Blocked', 'Cancelled']">
                      <button
                        *ngIf="confidenceRow.confidence != confidence"
                        mat-menu-item
                        (click)="changeTeamConfidence(confidenceRow, confidence)"
                        [matTooltip]="confidence"
                        matTooltipPosition="right"
                        [attr.data-cypress]="'menu-confidence-option-' + confidence">
                        <span class="material-icons-outlined status-icon" [ngStyle]="{color: getConfidenceColor(confidence)}">
                          {{ getIconFromConfidence(confidence) }}
                        </span>
                      </button>
                    </ng-container>
                  </mat-menu>
                </td>
                <td class="comment-cell">
                  <div *ngIf="!confidenceRow.isEditing">
                    <div *ngIf="confidenceRow.comment" [innerHTML]="confidenceRow.comment | safeHtml" data-cypress="comment-text"></div>
                  </div>
                  <div *ngIf="confidenceRow.isEditing">
                    <ngx-editor-menu [editor]="confidenceRow.editor" [toolbar]="toolbar"> </ngx-editor-menu>
                    <ngx-editor
                      class="ngx-editor"
                      [editor]="confidenceRow.editor"
                      [(ngModel)]="confidenceRow.comment"
                      [placeholder]="'Enter comment'"
                      data-cypress="comment-field"></ngx-editor>
                  </div>
                </td>
                <td *ngIf="isWriter">
                  <button
                    *ngIf="!confidenceRow.isEditing"
                    type="button"
                    class="btn btn-outline-primary btn-tertiary btn-circle no-export"
                    (click)="startEditing(confidenceRow)"
                    title="Edit Comment"
                    data-cypress="edit-comment-button">
                    <span class="material-icons-outlined">edit</span>
                  </button>
                  <button
                    *ngIf="confidenceRow.isEditing"
                    type="button"
                    class="btn btn-sm btn-outline btn-circle btn-tertiary"
                    (click)="saveEditedComment(confidenceRow)"
                    [disabled]="saveInProgress"
                    title="Save Changes"
                    data-cypress="save-comment-button">
                    <span class="material-icons-outlined">check</span>
                  </button>
                  <button
                    *ngIf="confidenceRow.isEditing"
                    type="button"
                    class="btn btn-sm btn-tertiary btn-circle"
                    (click)="cancelEditing(confidenceRow)"
                    title="Cancel Editing"
                    data-cypress="cancel-comment-button">
                    <span class="material-icons-outlined">close</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
