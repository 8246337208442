import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DeploymentProgressService {
  private readonly apiUrl = `${environment.webProtocol}://${environment.serverUrl}/api/portfolio-review/deployment-progress`;

  constructor(private readonly http: HttpClient) {
  }

  getDeploymentProgress(branch: string): Observable<any> {
    let httpParams = new HttpParams().set("branch", branch);
    return this.http.get<any[]>(`${this.apiUrl}/get`, {params: httpParams});
  }

  updateDeploymentProgressObjective(branch: string, quarter: string, type: string, objective: number): Observable<any> {
    let httpParams = new HttpParams().set("branch", branch).set("quarter", quarter).set("type", type);
    if (objective != null) httpParams = httpParams.set("objective", objective);
    return this.http.get<any[]>(`${this.apiUrl}/update-deployment-progress-objective`, {params: httpParams});
  }

  deleteRow(id: number, branch: string): Observable<any> {
    let httpParams = new HttpParams().set("id", id).set("branch", branch);
    return this.http.get<any[]>(`${this.apiUrl}/delete-row`, {params: httpParams});
  }

  siteAdded(rowId: number, status: string, siteId: number, branch: string): Observable<any> {
    let httpParams = new HttpParams().set("rowId", rowId).set("status", status).set("siteId", siteId).set("branch", branch);
    return this.http.get<any[]>(`${this.apiUrl}/site-added`, {params: httpParams});
  }

  siteRemoved(rowId: number, status: string, siteId: number, branch: string): Observable<any> {
    let httpParams = new HttpParams().set("rowId", rowId).set("status", status).set("siteId", siteId).set("branch", branch);
    return this.http.get<any[]>(`${this.apiUrl}/site-removed`, {params: httpParams});
  }

  sitesCleared(rowId: number, status: string, branch: string): Observable<any> {
    let httpParams = new HttpParams().set("rowId", rowId).set("status", status).set("branch", branch);
    return this.http.get<any[]>(`${this.apiUrl}/sites-cleared`, {params: httpParams});
  }

  saveComment(rowId: number, comment: string, branch: string): Observable<any> {
    let httpParams = new HttpParams().set("rowId", rowId).set("branch", branch);
    return this.http.post<any[]>(`${this.apiUrl}/save-comment`, comment, {params: httpParams});
  }

  createRow(productId: number, quarter: string, branch: string): Observable<any> {
    let httpParams = new HttpParams().set("productId", productId).set("quarter", quarter).set("branch", branch);
    return this.http.get<any[]>(`${this.apiUrl}/create-row`, {params: httpParams});
  }
}
