import {Component} from "@angular/core";


@Component({
  selector: "app-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.scss"],
})
export class HelpComponent {

  openInNewTab(link: string) {
    window.open(link, '_blank');
  }
}
