import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-date-fns";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import {ToastrService} from "ngx-toastr";
import {SharedStateService} from "../common/shared-state.service";
import {GRAPH_COLORS} from "../common/util/graph-colors";
import {UsageService} from "./usage.service";

Chart.register(
  BarController,
  BarElement,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  CategoryScale,
  Legend,
  Tooltip,
  Title,
  zoomPlugin,
  ChartDataLabels
);

@Component({
  selector: "app-usage",
  templateUrl: "./usage.component.html",
  styleUrls: ["./usage.component.scss"],
})
export class UsageComponent implements OnChanges {
  @Input() productId: number;
  @Input() isWriter: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() section!: string;
  @Input() viewMode: string = "7d";
  @Input() canvasId: string;
  @Input() lbus: any;

  lastUpdate: Date;

  productNameInUsageMonitoring: string;

  durationSelected: string;
  chartInstance: Chart;
  products = [];
  hasData: boolean = true;
  comment: string = "";
  loading: boolean = false;

  barColors = GRAPH_COLORS;

  constructor(private readonly usageService: UsageService,
              private readonly toastrService: ToastrService,
              private readonly sharedStateService: SharedStateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.durationSelected = this.viewMode;
    if (changes.productId?.currentValue) {
      this.getUsersCount();
      this.loadComment();
    }
  }

  setDuration(duration: string): void {
    this.durationSelected = duration;
    this.getUsersCount();
  }

  getUsersCount() {
    this.loading = true;
    this.sharedStateService.setLoadingStatus(`Usage-${this.viewMode}`, true);
    this.usageService.getUsersCount(this.productId, this.durationSelected).subscribe(
      (results) => {
        this.loading = false;
        this.sharedStateService.setLoadingStatus(`Usage-${this.viewMode}`, false);
        if (!results || results.usersCount.length === 0) {
          this.hasData = false;
          if (this.chartInstance) this.chartInstance.destroy();
        } else {
          this.hasData = true;
          this.productNameInUsageMonitoring = results.productNameInUsageMonitoring;
          this.updateGraphs(results.usersCount);
        }
      },
      () => {
        this.loading = false;
        this.sharedStateService.setLoadingStatus(`Usage-${this.viewMode}`, false);
        this.hasData = false;
        if (this.chartInstance) this.chartInstance.destroy();
        this.toastrService.error("Error when loading the usage of the product");
      }
    );
  }

  max(max: number): number {
    let step = 1;
    if (max > 100) {
      step = 10;
    } else if (max > 20) {
      step = 5;
    }
    return step * Math.ceil((max * 1.1) / step);
  }

  handleHover(evt, item, legend) {
    legend.chart.data.datasets.forEach((dataset, datasetIndex) => {
      if (dataset.type === "bar") {
        if (datasetIndex !== item.datasetIndex) {
          if (dataset.backgroundColor && dataset.backgroundColor.length === 7) {
            dataset.backgroundColor = dataset.backgroundColor + "4D";
          }
        }
      }

      if (dataset.type === "line") {
        if (datasetIndex !== item.datasetIndex) {
          if (dataset.borderColor && dataset.borderColor.length === 7) {
            dataset.borderColor = dataset.borderColor + "4D";
          }
        }
      }
    });

    legend.chart.update();
  }

  handleLeave(evt, item, legend) {
    legend.chart.data.datasets.forEach((dataset) => {
      if (dataset.type === "bar") {
        if (dataset.backgroundColor && dataset.backgroundColor.length === 9) {
          dataset.backgroundColor = dataset.backgroundColor.slice(0, -2);
        }
      }

      if (dataset.type === "line") {
        if (dataset.borderColor && dataset.borderColor.length === 9) {
          dataset.borderColor = dataset.borderColor.slice(0, -2);
        }
      }
    });

    legend.chart.update();
  }

  updateGraphs(results: any[]) {
    const allDates = [...new Set(results.map((res) => new Date(res.endDate[0], res.endDate[1] - 1, res.endDate[2]).toISOString()))].sort((a, b) => {
      const dateA = new Date(a).getTime();
      const dateB = new Date(b).getTime();
      return dateA - dateB;
    });

    const allAssets = [...new Set(results.filter((res) => res.uniqueCount > 0 || res.dailyCount > 0 || res.uniqueCount > 0).map((res) => res.asset))];

    const datasets = [];

    let maxUniqueUsers = 0;
    let maxConnectionsCount = 0;

    const stackedSumForDates = allDates.map(() => 0);
    allAssets.forEach((asset, index) => {
      const assetData = allDates.map((date, i) => {
        const result = results.find(
          (res) => new Date(res.endDate[0], res.endDate[1] - 1, res.endDate[2]).toISOString() === date && res.asset === asset
        );
        const uniqueCount = result ? result.uniqueCount : 0;
        stackedSumForDates[i] += uniqueCount;
        maxUniqueUsers = Math.max(maxUniqueUsers, stackedSumForDates[i]);
        return uniqueCount;
      });

      datasets.push({
        type: "bar",
        label: asset,
        data: assetData,
        backgroundColor: this.barColors[index % this.barColors.length],
        yAxisID: "y",
        order: 1,
        borderWidth: {
          top: 1,
          right: 0,
          bottom: 0,
          left: 0,
        },
        borderColor: "#FFF",
        borderRadius: 4,
        datalabels: {
          display: function (context) {
            return context.dataset.data[context.dataIndex] !== 0;
          },
          color: "white",
          align: "center",
          anchor: "center",
          font: {
            weight: "bold",
          },
        },
      });
    });

    const connectionData = allDates.map((date) =>
      results.reduce((sum, res) => (new Date(res.endDate[0], res.endDate[1] - 1, res.endDate[2]).toISOString() === date ? sum + res.count : sum), 0)
    );

    connectionData.forEach((count) => {
      maxConnectionsCount = Math.max(maxConnectionsCount, count);
    });

    datasets.push({
      type: "line",
      label: "Connections Count",
      data: connectionData,
      borderColor: "#374649",
      backgroundColor: "rgba(91, 26, 137, 0.2)",
      fill: false,
      tension: 0.4,
      pointHitRadius: 4,
      pointRadius: 0,
      yAxisID: "y1",
      order: 0,
      datalabels: {
        display: false,
      },
    });

    const legendMargin = {
      id: "legendMargin",
      beforeInit(chart) {
        let fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
          fitValue.bind(chart.legend)();
          this.height += 20;
          return this.height;
        };
      },
    };

    const config: any = {
      type: "bar",
      data: {
        labels: allDates.map((date) => {
          let dateNotISO = new Date(date);
          if (this.durationSelected == "7d") dateNotISO.setDate(new Date(date).getDate() - 6);
          else dateNotISO.setDate(1);
          return new Date(dateNotISO.getTime() - dateNotISO.getTimezoneOffset() * 60000).toISOString().split("T")[0];
        }),
        datasets: datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: "time",
            time: {
              unit: this.durationSelected == "7d" ? "week" : "month",
              tooltipFormat: "dd MMM yyyy",
              isoWeekday: true,
            },
            stacked: true,
            min: new Date(allDates[Math.max(allDates.length - 25, 0)]).getTime(),
            max: new Date(allDates[allDates.length]).getTime(),
            grid: {
              display: false,
            },
          },
          y: {
            min: 0,
            max: this.max(maxUniqueUsers),
            type: "linear",
            position: "left",
            title: {
              display: true,
              text: "Number of unique users",
            },
            grid: {
              drawOnChartArea: false,
              display: false,
            },
            stacked: true,
          },
          y1: {
            min: 0,
            max: this.max(maxConnectionsCount),
            type: "linear",
            position: "right",
            title: {
              display: true,
              text: "Number of connections",
            },
            grid: {
              drawOnChartArea: false,
              display: false,
            },
            stacked: false,
          },
        },
        plugins: {
          legend: {
            position: "top",
            align: "start",
            labels: {
              font: {
                size: 12,
                family: "Roboto, sans-serif",
                weight: "700",
              },
              color: "var(--Primary-Colors-Body, #374649)",
              boxWidth: 16,
              boxHeight: 16,
              useBorderRadius: true,
              borderRadius: 3,
              usePointStyle: false,
              padding: 10,
            },
            onHover: this.handleHover,
            onLeave: this.handleLeave,
          },
          tooltip: {
            callbacks: {
              title: (items) =>
                this.durationSelected == "7d"
                  ? this.computeWeekStringFromDate(new Date(items[0].label || ""))
                  : this.computeMonthStringFromDate(new Date(items[0].label || "")),
            },
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "x",
            },
            zoom: {
              mode: "x",
            },
          },
        },
      },
      plugins: [legendMargin],
    };

    if (this.chartInstance) {
      this.chartInstance.destroy();
    }

    const ctx = document.getElementById(`usersCount-${this.canvasId}`) as HTMLCanvasElement;
    this.chartInstance = new Chart(ctx, config);
  }

  computeWeekStringFromDate(startDate: Date): string {
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);

    return `${startDate.toLocaleDateString("en-GB", {day: "2-digit", month: "short"})} - ${endDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })}`;
  }

  computeMonthStringFromDate(startDate: Date): string {
    const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

    return `${startDate.toLocaleDateString("en-GB", {day: "2-digit", month: "short"})} - ${endDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    })}`;
  }

  zoomIn() {
    this.chartInstance.zoom(1.1);
  }

  reset() {
    this.chartInstance.resetZoom();
  }

  zoomOut() {
    this.chartInstance.zoom(0.9);
  }

  saveComment(comment: string) {
    this.comment = comment;
    if (this.comment) {
      if (this.comment == "<p></p>") this.comment = null; // Reset the comment

      this.usageService.saveComment(this.productId, this.comment).subscribe(
        (response) => {
          this.toastrService.success("Comment saved successfully!");
        },
        () => {
          this.toastrService.error("Error while saving the comment.");
        }
      );
    }
  }

  loadComment() {
    this.usageService.getComment(this.productId).subscribe(
      (res) => {
        this.comment = res?.comment ? res.comment : "";
        this.lastUpdate = res?.modificationDate;
      },
      () => {
        this.toastrService.error("Error while loading comments.");
      }
    );
  }
}
