import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {AuthenticationService} from "../common/authentication.service";
import {SharedStateService} from "../common/shared-state.service";
import {UserService} from "../common/user.service";
import {ProductService} from "./product.service";

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html"
})
export class ProductComponent implements OnInit {
  productId: number;
  productName: string;
  isWriter: string[] = [];
  isAdmin: boolean = false;
  showHiddenComponent = false;
  lbus: any;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly authService: AuthenticationService,
    private readonly sharedStateService: SharedStateService,
    private readonly productService: ProductService
  ) {
  }

  ngOnInit(): void {
    // Get product ID from active route
    this.route.params.subscribe((params) => {
      this.productId = +params["id"]; // Convert ID in number
      this.productName = params["name"];
      this.checkRights();

      this.productService.getAllLbusAvailable(this.productId, null).subscribe((allLBUs) => {
        this.lbus = allLBUs.map((lbu) => (
          {
            code: lbu.isoCode,
            name: lbu.name,
            flag: `assets/flags/${lbu.isoCode.toLowerCase()}.svg`
          }
        ));
      });
    });
    this.isAdmin = this.userService.getUserIsAdmin();

    this.sharedStateService.showHiddenComponent$.subscribe((show) => {
      this.showHiddenComponent = show;
    });
  }

  checkRights() {
    this.userService.editRightsBySection(this.authService.email, this.productId).subscribe((result: string[]) => {
      this.isWriter = result;
    });
  }

  // Replace spaces by "-"
  toIdFormat(section: string): string {
    return section.replace(/\s+/g, "-");
  }
}
