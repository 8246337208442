import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ToolboxService} from "../toolbox.service";
import {ToastrService} from "ngx-toastr";
import {DndDropEvent} from "ngx-drag-drop";

@Component({
  selector: "edit-delete-toolbox-item",
  templateUrl: "./edit-delete-toolbox-item.component.html",
  styleUrls: ["./edit-delete-toolbox-item.component.scss"],
})
export class EditDeleteToolboxItemComponent implements OnInit {
  popupTitle: string;

  errorMessage: string;

  selectedItem: any = {title: "0"};

  saveInProgress: boolean = false;

  existingItems: Array<any>;

  deleteMode: boolean;

  type: string;

  document: any;

  constructor(public dialogRef: MatDialogRef<EditDeleteToolboxItemComponent>,
              @Inject(MAT_DIALOG_DATA) private readonly data: any,
              private readonly toolboxService: ToolboxService,
              private readonly toastrService: ToastrService) {
    this.deleteMode = this.data.delete;
    this.type = this.data.type;
  }

  ngOnInit() {
    this.popupTitle = this.deleteMode ? "Delete a " + this.type : "Edit a " + this.type;
    this.existingItems = this.data.existingItems;
  }

  closePopup() {
    this.dialogRef.close();
  }

  save() {
    if (this.selectedItem.title == null || this.selectedItem.title == "0") this.errorMessage = "No " + this.type + " have been selected.";
    else if (this.type == "link") {
      if (this.selectedItem.link == null || this.selectedItem.link == "") this.errorMessage = "Link field is empty.";
      else {
        this.saveInProgress = true;

        this.toolboxService.saveLink(this.data.productId, this.selectedItem.title, this.selectedItem.link).subscribe(() => {
          this.saveInProgress = false;
          this.toastrService.success("Link has been saved !");
          this.dialogRef.close("success");
        }, () => {
          this.saveInProgress = false;
          this.toastrService.error("An error occurred while saving the link.");
        });
      }
    } else if (this.type == "document") {
      if (this.document == null) { // Nothing has changed, user clicked directly on save, closing the popup
        this.toastrService.success("Document has not been edited.");
        this.closePopup();
      } else {
        this.saveInProgress = true;

        this.toolboxService.saveDocument(this.data.productId, this.selectedItem.title, this.document).subscribe(() => {
          this.saveInProgress = false;
          this.toastrService.success("Document has been saved !");
          this.dialogRef.close("success");
        }, () => {
          this.saveInProgress = false;
          this.toastrService.error("An error occurred while saving the document.");
        });
      }
    }
  }

  delete() {
    if (this.selectedItem.title == null || this.selectedItem.title == "0") this.errorMessage = "No " + this.type + " have been selected.";
    else {
      this.saveInProgress = true;
      if (this.type == "link") {
        this.toolboxService.deleteLink(this.data.productId, this.selectedItem.title).subscribe(() => {
          this.saveInProgress = false;
          this.toastrService.success("Link has been deleted !");
          this.dialogRef.close("success");
        }, () => {
          this.saveInProgress = false;
          this.toastrService.error("An error occurred while deleting the link.");
        });
      } else if (this.type == "document") {
        this.toolboxService.deleteDocument(this.data.productId, this.selectedItem.title).subscribe(() => {
          this.saveInProgress = false;
          this.toastrService.success("Document has been deleted !");
          this.dialogRef.close("success");
        }, () => {
          this.saveInProgress = false;
          this.toastrService.error("An error occurred while deleting the document.");
        });
      }
    }
  }

  treatFile(file) {
    this.document = null;
    if (file.size > 100000000) {
      this.errorMessage = "Uploaded file is too large (> 100MB)."
    } else {
      this.document = file;
      this.selectedItem.documentPath = this.data.productId + "/" + this.document.name;
    }
  }

  getFile($event) {
    this.treatFile($event.target.files[0]);
  }

  onDrop(event: DndDropEvent) {
    this.treatFile(event.event.dataTransfer.files[0]);
  }

}