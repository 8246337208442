import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {saveAs} from "file-saver";
import * as htmlToImage from "html-to-image";
import {ToastrService} from "ngx-toastr";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import {firstValueFrom, Observable} from "rxjs";
import * as XLSX from "../../lib/xlsx.full.min.js";
import {ConfirmPopupComponent} from "../common/confirm-popup/confirm-popup.component";
import {ImageUtil} from "../common/ImageUtil";
import {TableService} from "../common/table.service";
import {UserDTO} from "../common/user.service.js";
import {ProductDetailsService} from "../product-details/product-details.service";
import {ActionPlanExportableDPC} from "./action-plan-exportable-dpc";
import {ActionPlanExportableProduct} from "./action-plan-exportable-product";
import {ActionPlan, ActionPlanService, DPCActionPlan} from "./action-plan.service";
import {ManageActionComponent} from "./manage-action/manage-action.component";
import {MaterialIconsBase64} from "./material-icons-base-64";

@Component({
  selector: "app-action-plan",
  templateUrl: "./action-plan.component.html",
  styleUrls: ["./action-plan.component.scss"],
})
export class ActionPlanComponent implements OnChanges {
  @Input() productId: number;
  @Input() isWriter: boolean;
  @Input() isAdmin: boolean = false;
  @Input() productName: string;
  @Input() productOwners: Array<string>;
  @Input() motherPage: string;
  @Input() branch: string = "EP";
  @Input() lbus: Array<any>;
  @Input() products: Array<any>;

  @Output() sendLastUpdate: EventEmitter<Date> = new EventEmitter<Date>();

  isDpcPage: boolean = false;
  isProductPage: boolean = false;
  actionToEdit: ActionPlan;
  dpcActionToEdit: DPCActionPlan;

  actionPlans: ActionPlan[] = [];
  dpcActionPlans: DPCActionPlan[] = [];
  filteredProductActionPlans: ActionPlan[] = [];
  filteredDpcActionPlans: DPCActionPlan[] = [];

  statusFilter: string;
  typeFilter: string;
  lbuFilter: string;
  pillarFilter: string;
  productFilter: string;
  responsibleFilter: string;
  deadlineFilter: Date = null;

  statusOptions: string[] = [];
  typeOptions: string[] = [];
  lbuOptions: {code: string; name: string; flag: string}[];
  pillarOptions: string[] = [];
  productOptions: string[] = [];
  responsibleOptions: string[] = [];
  currentAction;
  sortedColumn: string | null = null;
  sortDirection: "asc" | "desc" = "asc";

  constructor(
    private readonly actionPlanService: ActionPlanService,
    private readonly dialog: MatDialog,
    private readonly toastrService: ToastrService,
    private readonly productDetailsService: ProductDetailsService,
    private readonly tableService: TableService
  ) {}

  ngOnInit() {
    this.isProductPage = this.motherPage === "product";
    this.isDpcPage = this.motherPage === "dpc";

    if (this.isProductPage) {
      this.loadActionPlansByProductId(this.productId);
    } else {
      this.loadActionPlansByBranch(this.branch);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.productId?.previousValue != null && changes.productId?.currentValue) {
      this.loadActionPlansByProductId(this.productId);
    }
    if (changes["products"]?.previousValue != null && changes["products"]?.currentValue) {
      this.loadActionPlansByBranch(this.branch);
    }
  }

  loadActionPlansByProductId(productId: number): void {
    this.actionPlanService.getActionPlansByProductId(productId).subscribe((data) => {
      this.sendLastUpdate.emit(data.actionPlanLastUpdateDate);
      this.actionPlans = data.actionPlans
        .map((plan) => ({
          ...plan,
          pillarName: plan.pillarName || "N/A",
          isFromDpcAction: plan.title === "DPC Action",
        }))
        .sort((a, b) => {
          const dateComparison = new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime();
          return dateComparison !== 0 ? dateComparison : a.id - b.id;
        });
      this.extractUniqueOptionsProduct();
      this.filteredProductActionPlans = [...this.actionPlans];
    });
  }

  loadActionPlansByBranch(branch: string): void {
    this.actionPlanService.getActionPlanByBranch(branch).subscribe((data) => {
      this.sendLastUpdate.emit(data.dpcActionPlanLastUpdateDate);
      this.dpcActionPlans = data.dpcActionPlans.sort((a, b) => {
        const dateComparison = new Date(a.dpcDate).getTime() - new Date(b.dpcDate).getTime();
        return dateComparison !== 0 ? dateComparison : a.id - b.id;
      });
      this.extractUniqueOptionsDPC();
      this.filteredDpcActionPlans = [...this.dpcActionPlans];
    });
  }

  extractUniqueOptionsProduct(): void {
    const statuses = new Set<string>();
    const types = new Set<string>();
    const lbus = new Set<{code: string; name: string; flag: string}>();
    const pillars = new Set<string>();
    const responsibles = new Set<string>();

    lbus.add({code: null, name: "None", flag: null});

    this.actionPlans.forEach((plan) => {
      statuses.add(plan.status);
      types.add(plan.type);

      const lbusFromComment = this.extractLbusFromComment(plan.comment);
      lbusFromComment.forEach((lbu) => {
        const country = this.lbus?.find((c) => c.name.toLowerCase() === lbu.toLowerCase());
        if (country) {
          lbus.add(country);
        }
      });

      pillars.add(plan.pillarName);
      responsibles.add(plan.responsible.fullName);
    });

    this.statusOptions = Array.from(statuses);
    this.typeOptions = Array.from(types);
    this.lbuOptions = Array.from(lbus);
    this.pillarOptions = Array.from(pillars);
    this.responsibleOptions = Array.from(responsibles);
  }

  extractUniqueOptionsDPC(): void {
    const statuses = new Set<string>();
    const types = new Set<string>();
    const lbus = new Set<{code: string; name: string; flag: string}>();
    const products = new Set<string>();
    const responsibles = new Set<string>();

    lbus.add({code: null, name: "None", flag: null});

    this.dpcActionPlans.forEach((plan) => {
      statuses.add(plan.status);
      types.add(plan.type);

      const lbusFromComment = this.extractLbusFromComment(plan.comment);
      lbusFromComment.forEach((lbu) => {
        const country = this.lbus?.find((c) => c.name.toLowerCase() === lbu.toLowerCase());
        if (country) {
          lbus.add(country);
        }
      });

      const productsFromComment = this.extractProductsFromComment(plan.comment);
      productsFromComment.forEach((product) => {
        const item = this.products?.find((c) => c.name.toLowerCase() === product.toLowerCase());
        if (item) {
          products.add(item.name);
        }
      });

      responsibles.add(plan.responsible.fullName);
    });

    this.statusOptions = Array.from(statuses);
    this.typeOptions = Array.from(types);
    this.lbuOptions = Array.from(lbus);
    this.productOptions = Array.from(products);
    this.responsibleOptions = Array.from(responsibles);
  }

  extractLbusFromComment(comment: string): string[] {
    const parser = new DOMParser();
    const doc = parser.parseFromString(comment, "text/html");
    const mentions = doc.querySelectorAll("[data-mention-name]");
    return Array.from(mentions).map((el) => el.getAttribute("data-mention-name"));
  }

  extractProductsFromComment(comment: string): string[] {
    const parser = new DOMParser();
    const doc = parser.parseFromString(comment, "text/html");
    const tag = doc.querySelectorAll("[data-tag]");
    return Array.from(tag).map((el) => el.getAttribute("data-tag"));
  }

  filterActionPlans(): void {
    if (this.isProductPage) {
      this.filterProductActionPlans();
    } else {
      this.filterDpcActionPlans();
    }
  }

  filterProductActionPlans(): void {
    const deadlineTimestamp = this.deadlineFilter ? new Date(this.deadlineFilter) : null;
    this.filteredProductActionPlans = this.actionPlans.filter((plan) => {
      const isLbuFilterValid =
        this.lbuFilter == null ||
        (this.lbuFilter === "None" ? !plan.comment.includes("@") : plan.comment.toLowerCase().includes(`@${this.lbuFilter.toLowerCase()}`));

      return (
        (this.statusFilter ? plan.status.toLowerCase().includes(this.statusFilter.toLowerCase()) : true) &&
        (this.typeFilter ? plan.type.toLowerCase().includes(this.typeFilter.toLowerCase()) : true) &&
        isLbuFilterValid &&
        (this.pillarFilter
          ? (plan.pillarName || "N/A").toLowerCase().includes(this.pillarFilter.toLowerCase())
          : true) &&
        (this.responsibleFilter ? plan.responsible.fullName.toLowerCase().includes(this.responsibleFilter.toLowerCase()) : true) &&
        (this.deadlineFilter != null ? new Date(plan.deadline) <= deadlineTimestamp : true)
      );
    });
  }

  filterDpcActionPlans(): void {
    const deadlineTimestamp = this.deadlineFilter ? new Date(this.deadlineFilter) : null;
    this.filteredDpcActionPlans = this.dpcActionPlans.filter((plan) => {
      const isLbuFilterValid =
        this.lbuFilter == null ||
        (this.lbuFilter === "None" ? !plan.comment.includes("@") : plan.comment.toLowerCase().includes(`@${this.lbuFilter.toLowerCase()}`));

      const isProductFilterValid =
        this.productFilter == null ||
        (this.productFilter === "None" ? !plan.comment.includes("#") : plan.comment.toLowerCase().includes(`#${this.productFilter.toLowerCase()}`));

      return (
        (this.statusFilter ? plan.status.toLowerCase().includes(this.statusFilter.toLowerCase()) : true) &&
        (this.typeFilter ? plan.type.toLowerCase().includes(this.typeFilter.toLowerCase()) : true) &&
        isLbuFilterValid &&
        isProductFilterValid &&
        (this.responsibleFilter ? plan.responsible.fullName.toLowerCase().includes(this.responsibleFilter.toLowerCase()) : true) &&
        (this.deadlineFilter != null ? new Date(plan.deadline) <= deadlineTimestamp : true)
      );
    });
  }

  resetFilters(): void {
    if (this.isProductPage) {
      this.resetProductFilters();
    } else {
      this.resetDpcFilters();
    }
  }

  resetProductFilters(): void {
    this.statusFilter = null;
    this.typeFilter = null;
    this.lbuFilter = null;
    this.pillarFilter = null;
    this.responsibleFilter = null;
    this.deadlineFilter = null;
    this.filteredProductActionPlans = [...this.actionPlans];
  }

  resetDpcFilters(): void {
    this.statusFilter = null;
    this.typeFilter = null;
    this.productFilter = null;
    this.lbuFilter = null;
    this.responsibleFilter = null;
    this.deadlineFilter = null;
    this.filteredDpcActionPlans = [...this.dpcActionPlans];
  }

  isCountry(countryName: string): boolean {
    return this.lbus.some((country) => country.name.toLowerCase() === countryName.toLowerCase());
  }

  isProduct(productName: string): boolean {
    return this.products.some((item) => item.name.toLowerCase() === productName.toLowerCase());
  }

  getStatusColor(status: string): string {
    switch (status) {
      case "To do":
        return "#285aff"; // Blue
      case "In progress":
        return "#fb9a28"; // Orange
      case "Blocked":
        return "#ed0000"; // Red
      case "Done":
        return "#40a900"; // Green
      case "Decision":
        return "#85888a"; // Grey
      case "Info":
        return "#85888a"; // Grey
      case "Cancelled":
        return "#ed0000"; // Red
      default:
        return "#000000";
    }
  }

  getStatusIcon(status: string): string {
    switch (status) {
      case "To do":
        return "change_circle";
      case "In progress":
        return "alarm_on";
      case "Blocked":
        return "block";
      case "Done":
        return "check_circle";
      case "Decision":
        return "lightbulb_circle";
      case "Info":
        return "info";
      case "Cancelled":
        return "cancel";
      default:
        return "help_outline";
    }
  }

  formatDate(date: Date): string {
    const parsedDate = new Date(date);
    return date != null ? parsedDate.toLocaleDateString("en-GB") : "";
  }

  /** MANAGE ACTIONS **/
  setCurrentAction(action: any): void {
    this.currentAction = action;
  }

  editAction(actionId: number, motherPage: string): void {
    if (this.isProductPage) {
      this.actionToEdit = this.filteredProductActionPlans.find((action) => action.id === actionId);
    } else {
      this.dpcActionToEdit = this.filteredDpcActionPlans.find((dpcAction) => dpcAction.id === actionId);
    }

    this.dialog
      .open(ManageActionComponent, {
        width: "min-content",
        data: {
          productId: this.productId,
          action: this.actionToEdit,
          dpcAction: this.dpcActionToEdit,
          motherPage: motherPage,
          branch: this.branch,
          lbus: this.lbus,
          products: this.products,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.refreshActionPlans();
        }
      });
  }

  deleteAction(actionId: number): void {
    this.dialog
      .open(ConfirmPopupComponent, {
        width: "30vw",
        data: {
          icon: "delete",
          title: "Delete Action",
          text: "Are you sure you want to delete this action?",
        },
        position: {
          top: "20px",
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res === "confirm") {
          const deleteAction = this.isProductPage
            ? this.actionPlanService.deleteActionPlan(actionId)
            : this.actionPlanService.deleteDpcActionPlan(actionId);

          deleteAction.subscribe(
            () => {
              this.toastrService.success("Action has been deleted!");
              this.refreshActionPlans();
            },
            () => {
              this.toastrService.error("An error occurred while deleting the action.");
            }
          );
        }
      });
  }

  refreshActionPlans() {
    if (this.isProductPage) {
      this.loadActionPlansByProductId(this.productId);
    } else {
      this.loadActionPlansByBranch(this.branch);
    }
  }

  changeActionPlanStatus(actionPlan: any, status: string) {
    if (actionPlan.status != status) {
      let actionObservable: Observable<any>;

      if (this.isDpcPage) {
        actionObservable = this.actionPlanService.changeDpcActionPlanStatus(actionPlan.id, status);
      } else {
        actionObservable = this.actionPlanService.changeActionPlanStatus(actionPlan.id, status);
      }

      actionObservable.subscribe(
        () => {
          this.toastrService.success("Status has been updated !");
          this.refreshActionPlans();
        },
        () => {
          this.toastrService.error("An error occurred while updating the status.");
        }
      );
    }
  }

  exportInXlsx() {
    const workbook = XLSX.utils.book_new();
    const bookName = this.isProductPage ? "Action plan" : "DPC action plan";

    let dataToExport;
    if (this.isProductPage) {
      dataToExport = this.filteredProductActionPlans.map((actionPlan: ActionPlan) => this.actionPlanToExportableProduct(actionPlan));
    } else {
      dataToExport = this.filteredDpcActionPlans.map((dpcActionPlan: DPCActionPlan) => this.actionPlanToExportableDPC(dpcActionPlan));
    }

    let colWidth = this.getWidthOfEachColumn(dataToExport);

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    worksheet["!cols"] = colWidth;

    XLSX.utils.book_append_sheet(workbook, worksheet, bookName);
    XLSX.writeFile(workbook, `${bookName}.xlsx`, {compression: true});
  }

  actionPlanToExportableProduct(actionPlan: ActionPlan): ActionPlanExportableProduct {
    let actionPlanXlsx = new ActionPlanExportableProduct();
    actionPlanXlsx.Status = actionPlan.status;
    actionPlanXlsx.Type = actionPlan.type;
    actionPlanXlsx["Action / Decision / Info"] = actionPlan.title;
    actionPlanXlsx["Creation Date"] =
      actionPlan.committee != "No committee"
        ? actionPlan.committee + " : " + this.formatDate(actionPlan.creationDate)
        : this.formatDate(actionPlan.creationDate);
    actionPlanXlsx["Comments & LBUs"] = actionPlan.comment ? actionPlan.comment.replace(/<\/?[^>]+(>|$)/g, "") : "";
    actionPlanXlsx.Pillars = actionPlan.pillarName;
    actionPlanXlsx.Responsible = actionPlan.responsible.fullName;
    actionPlanXlsx.Deadline = this.formatDate(actionPlan.deadline);

    return actionPlanXlsx;
  }

  actionPlanToExportableDPC(dpcActionPlan: DPCActionPlan): ActionPlanExportableDPC {
    let actionPlanXlsx = new ActionPlanExportableDPC();
    actionPlanXlsx.Status = dpcActionPlan.status;
    actionPlanXlsx.Type = dpcActionPlan.type;
    actionPlanXlsx["DPC Date"] = this.formatDate(dpcActionPlan.dpcDate);
    actionPlanXlsx.Comment = dpcActionPlan.comment ? dpcActionPlan.comment.replace(/<\/?[^>]+(>|$)/g, "") : "";
    actionPlanXlsx.Responsible = dpcActionPlan.responsible.fullName;
    actionPlanXlsx.Deadline = this.formatDate(dpcActionPlan.deadline);

    return actionPlanXlsx;
  }

  getWidthOfEachColumn(dataToExport) {
    let objectMaxLength = [];
    const WIDTH_MARGIN = 3;

    let allToConsider = [...dataToExport];
    allToConsider.push(Object.keys(dataToExport[0]));

    for (const element of allToConsider) {
      let value = <any>Object.values(element);

      for (let j = 0; j < value.length; j++) {
        if (typeof value[j] == "number") {
          objectMaxLength[j] = 10;
        } else {
          let lengthOfObject = value[j].length + WIDTH_MARGIN;

          if (!objectMaxLength[j] || lengthOfObject > objectMaxLength[j]) {
            objectMaxLength[j] = lengthOfObject;
          }
        }
      }
    }

    const colWidth = [];
    for (let j = 0; j < <any>Object.values(dataToExport[0]).length; j++) {
      colWidth.push({width: objectMaxLength[j]});
    }

    return colWidth;
  }

  exportInJpeg() {
    const imageName = this.isProductPage ? "Action plan" : "DPC action plan";

    htmlToImage
      .toJpeg(document.getElementById("action-plan-table"), {
        quality: 1,
        backgroundColor: "white",
        filter: (domNode) => domNode.className == null || domNode.className.indexOf("no-export") == -1,
      })
      .then(function (dataUrl) {
        saveAs(dataUrl, `${imageName}.jpeg`);
      })
      .catch(() => {
        this.toastrService.error("Failed to export the action plan as a JPEG.");
      });
  }

  async exportInPdf() {
    (<any>pdfMake).addVirtualFileSystem(pdfFonts);

    let dataToExport;
    if (this.isProductPage) {
      dataToExport = this.filteredProductActionPlans.map((actionPlan: ActionPlan) => this.actionPlanToExportableProduct(actionPlan));
    } else {
      dataToExport = this.filteredDpcActionPlans.map((dpcActionPlan: DPCActionPlan) => this.actionPlanToExportableDPC(dpcActionPlan));
    }

    let tableBody: Array<Array<any>> = [
      Object.keys(dataToExport[0]).map((header) => {
        return {text: header, style: "tableText"};
      }),
    ];

    dataToExport.forEach((dataRow) =>
      tableBody.push(
        Object.values(dataRow).map((value, index) => {
          let base64Img = null;

          if (index === 0) {
            if (value == "To do") base64Img = MaterialIconsBase64.TO_DO;
            else if (value == "In progress") base64Img = MaterialIconsBase64.IN_PROGRESS;
            else if (value == "Blocked") base64Img = MaterialIconsBase64.BLOCKED;
            else if (value == "Done") base64Img = MaterialIconsBase64.DONE;
            else if (value == "Decision") base64Img = MaterialIconsBase64.DECISION;
            else if (value == "Info") base64Img = MaterialIconsBase64.INFO;
            else if (value == "Cancelled") base64Img = MaterialIconsBase64.CANCELLED;
          }

          if (base64Img != null) {
            return {
              image: base64Img,
              alignment: "center",
              fit: [10, 10],
              margin: [0, 2, 0, 0],
            };
          } else {
            return {text: value, style: "tableText", margin: [0, 3, 0, 3]};
          }
        })
      )
    );
    let logo;
    let productDetails: any;
    let productOwnersText;
    let tableWidth: Array<any> = [];

    if (this.isProductPage) {
      productDetails = await firstValueFrom(this.productDetailsService.getProductDetails(this.productId));
      let productOwners: Array<string> = productDetails.productOwners.map((productOwner: UserDTO) => productOwner.fullName);

      productOwnersText = "Product Owner";
      if (productOwners.length > 1) productOwnersText += "s";
      productOwnersText += " : " + productOwners.join(", ");

      logo = productDetails.logo;
      if (logo == null) logo = await ImageUtil.getBase64ImageFromUrl("assets/logo_deploy_cockpit.png");

      tableWidth = ["9.5%", "8.5%", "18%", "13%", "16%", "13.5%", "12.5%", "9%"];
    } else {
      productDetails = null;
      productOwnersText = "";
      logo = await ImageUtil.getBase64ImageFromUrl("assets/logo_deploy_cockpit.png");
      tableWidth = ["13.9%", "12.4%", "19%", "23.3%", "18.2%", "13.2%"];
    }

    const docName = this.isProductPage ? this.productName : "DPC action plan";
    const titleName = this.isProductPage ? "Action plan" : "DPC action plan";
    let docDefinition = {
      content: [
        {
          columns: [
            {
              width: 100,
              image: logo,
              fit: [100, 100],
            },
            {
              width: "*",
              text: docName,
              margin: [0, 25, 0, 25],
              fontSize: 15,
              bold: true,
            },
          ],
        },
        {
          text: productOwnersText,
          fontSize: 8,
          italics: true,
          margin: [0, 0, 0, 15],
          color: "#8C8C8C",
        },
        {
          text: titleName,
          style: "actionPlanHeader",
          margin: [0, 0, 0, 10],
          fontSize: 13,
        },
        {
          table: {
            widths: tableWidth,
            body: tableBody,
          },
          layout: "lightHorizontalLines",
        },
      ],
      styles: {
        tableText: {
          fontSize: 7,
        },
      },
    };

    pdfMake.createPdf(docDefinition).download(`${titleName}.pdf`);
  }

  onSort(column: string): void {
    if (this.sortedColumn === column) {
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    } else {
      this.sortDirection = "asc";
    }
    this.sortedColumn = column;

    if (this.isProductPage) {
      this.filteredProductActionPlans = this.tableService.sortDataWithSpecificColumnValue(
        this.filteredProductActionPlans,
        column,
        this.sortDirection,
        (item, column) => this.getColumnValue(item, column)
      );
    } else {
      this.filteredDpcActionPlans = this.tableService.sortDataWithSpecificColumnValue(
        this.filteredDpcActionPlans,
        column,
        this.sortDirection,
        (item, column) => this.getColumnValue(item, column)
      );
    }
  }

  private getColumnValue(item: any, column: string): any {
    switch (column) {
      case "deadline":
      case "creationDate":
      case "dpcDate":
        return item[column] ? new Date(item[column]) : null;
      case "responsible":
        return item.responsible?.fullName || null;
      default:
        return item[column] || null;
    }
  }
}
