import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";

export interface PortfolioStatusDTO {
  productId: number;
  productName: string;
  step: string;
  previousStep: string;
  domain: string;
  hold: boolean;
  idea: boolean;
  poc: boolean;
  addedDate: string;
  creationDate: string;
  transferDate: string;
  runDate: string;
  status: string;
  onTimeCount: number;
  totalObjective: number;
}

@Injectable({
  providedIn: "root",
})
export class PortfolioStatusService {
  private readonly apiUrl = `${environment.webProtocol}://${environment.serverUrl}/api/portfolio-status`;

  constructor(private readonly http: HttpClient) {}

  getPortfolioStatusByBranch(branch: string): Observable<PortfolioStatusDTO[]> {
    return this.http.get<PortfolioStatusDTO[]>(`${this.apiUrl}/branch/${branch}`);
  }

  updateStep(productId: number, step: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/${productId}/step?step=${step}`, {});
  }

  deleteProduct(productId: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/delete/${productId}`);
  }
}
