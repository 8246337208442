<popup-header [dialogRef]="dialogRef" [icon]="'devices'" text="Edit deployment plan"></popup-header>
<div class="popup-container">
  <div>
      <div style="font-weight: 700; margin-bottom: 4px;">Product Name</div>
      <select #selectProduct class="form-select" (change)="onProductChange($event)">
        <option *ngFor="let product of products" [ngValue]="product"
        [selected]="product == selectedProduct">{{product.name}}</option>
      </select>

      <div class="deployments-container">
        <div style="grid-column: 1; grid-row: 1;" class="fw-bold sticky-top background-white"></div>
        <div style="grid-column: 2; grid-row: 1;" class="fw-bold sticky-top background-white">Deploy Plan</div>
        <div style="grid-column: 3; grid-row: 1;" class="fw-bold sticky-top background-white">Status</div>
        <ng-container class="deployments-container-wo-header">
          <ng-container *ngFor="let site of sites; let i = index">
            <ng-container *ngIf="deploymentPlans[selectedProduct.id][site.id] != null">
              <div style="grid-column: 1;" [style]="{'grid-row': i + 2}" class="fw-bold site-name-text">{{site.name}}</div>
              <div style="grid-column: 2;" [style]="{'grid-row': i + 2}">
                <select class="form-select" name="deploymentPlan" [(ngModel)]="deploymentPlans[selectedProduct.id][site.id].deploymentPlan"
                [id]="'select-deployment-plan-' + site.name">
                  <option *ngFor="let quarter of getQuarterOptionsWithCurrent(deploymentPlans[selectedProduct.id][site.id].deploymentPlan)" [ngValue]="quarter">{{quarter}}</option>
                </select>
              </div>
              <div style="grid-column: 3;" [style]="{'grid-row': i + 2}">
                <select class="form-select" name="status" [(ngModel)]="deploymentPlans[selectedProduct.id][site.id].status"
                        [id]="'select-status-' + site.name">
                  <option [ngValue]="null"></option>
                  <option *ngFor="let status of statusOptions" [ngValue]="status">{{status}}</option>
                </select>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
  </div>
  <div class="bottom-popup">
    <div class="buttons-left-container">
      <button type="button" class="btn btn-secondary" (click)="closePopup()">Close</button>
    </div>
    <div class="buttons-container">
      <button type="button" class="btn btn-secondary save-button" (click)="save(false)" [disabled]="saveInProgress || this.getAllChangesOnProduct().length == 0">
        <span class="spinner-border spinner-border-sm" output="status" aria-hidden="true" *ngIf="saveInProgress && !saveFromClose"></span>
        Save
      </button>
      <button type="button" class="btn btn-primary save-close-button" (click)="save(true)" [disabled]="saveInProgress || this.getAllChangesOnProduct().length == 0">
        <span class="spinner-border spinner-border-sm" ouptut="status" aria-hidden="true" *ngIf="saveInProgress && saveFromClose"></span>
        Save & Close
      </button>
    </div>
  </div>
</div>
