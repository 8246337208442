import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ArrayService {
  mapArrayWithProperty(array: Array<any>, property: string, removeDuplicates: boolean = false): Array<any> {
    if (array == null) return null;
    let mappedArray: Array<any> = array.map(value => value[property]);

    return removeDuplicates ? Array.from(new Set(mappedArray)) : mappedArray;
  }

  formatArrayForDisplay(values: Array<string>, valueIfEmpty: string | null): string {
    return values == null || values.length == 0 ? valueIfEmpty : values.join(" & ");
  }

}
