<div class="accordion">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" [id]="'accordion-btn-header-deployment-plan'">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#accordion-btn-collapse-deployment-plan'"
        aria-expanded="true"
        [attr.aria-controls]="'accordion-btn-collapse-deployment-plan'">
        <div class="container-left-accordion-title">
          <span class="material-icons-outlined color_red" aria-hidden="true" color="accent">
            {{ "map" }}
          </span>
          {{ "Deployment Plan" }}
        </div>
      </button>
    </h4>
    <div
      [id]="'accordion-btn-collapse-deployment-plan'"
      class="accordion-collapse show"
      [attr.aria-labelledby]="'accordion-btn-header-deployment-plan'">
      <div class="accordion-body">
        <div class="top-container">
          <div>
            <div class="legend-container">
              <mat-checkbox [checked]="optionsSelected['Done']" class="done-checkbox" (change)="filterClicked('Done')"> Done </mat-checkbox>
              <mat-checkbox [checked]="optionsSelected['In progress']" class="in-progress-checkbox" (change)="filterClicked('In progress')">
                In progress in {{ getYear() }}
              </mat-checkbox>
              <mat-checkbox [checked]="optionsSelected['To do']" class="to-do-checkbox" (change)="filterClicked('To do')">
                To do in {{ getYear() + 1 }}+
              </mat-checkbox>
              <div class="pilot-div">Pilot site</div>
            </div>
            <div class="legend-container">
              <mat-checkbox [checked]="optionsSelected['In advance']" class="in-advance-checkbox" (change)="filterClicked('In advance')">
                In advance
              </mat-checkbox>
              <mat-checkbox [checked]="optionsSelected['On time']" class="on-time-checkbox" (change)="filterClicked('On time')">
                On time
              </mat-checkbox>
              <mat-checkbox [checked]="optionsSelected['Late']" class="late-checkbox" (change)="filterClicked('Late')"> Late </mat-checkbox>
              <mat-checkbox [checked]="optionsSelected['Blocked']" class="blocked-checkbox" (change)="filterClicked('Blocked')">
                Blocked
              </mat-checkbox>
            </div>
          </div>
          <div class="no-export">
            <div class="quarters-text">Quarters displayed</div>
            <div style="width: 200px">
              <ng-select
                [items]="quartersToDisplayOptions"
                [(ngModel)]="quarterToDisplay"
                [clearable]="false"
                (change)="this.filterWithOptions()"
                id="quarters-select"></ng-select>
            </div>
          </div>
        </div>
        <div class="spinner-container">
          <div *ngIf="dataLoading" class="spinner-border text-primary" output="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="plan-container" *ngIf="deploymentPlanListDisplayed">
          <div class="top-products-plan" style="grid-column: 1; grid-row: 1"></div>
          <ng-container *ngFor="let product of productsDisplayed; let i = index">
            <div
              class="product-cart"
              [style]="{'background-color': getBackgroundByDomain(product), 'grid-row': i + 2}"
              style="grid-column: 1"
              [attr.data-cypress]="product.name">
              <span class="product-cart-text">{{ product.name }}</span>
              <button *ngIf="isWriter" class="btn btn-no-active no-export" style="padding: 0" (click)="editProduct(product)">
                <span class="material-icons-outlined" style="margin-right: 0 !important">edit</span>
              </button>
            </div>
          </ng-container>
          <ng-container *ngFor="let site of sitesDisplayed; let i = index">
            <div [style]="{'grid-column': i + 2}" style="grid-row: 1" class="top-sites-plan-container">
              <div class="top-sites-plan">
                <div class="top-sites-plan-text">{{ site.name }}</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngFor="let product of productsDisplayed; let i = index">
            <div
              *ngFor="let site of sitesDisplayed; let j = index"
              [style]="{'grid-column': j + 2, 'grid-row': i + 2}"
              [hidden]="deploymentPlanListDisplayed[product.id] == null || deploymentPlanListDisplayed[product.id][site.id] == null"
              style="display: flex">
              <div
                class="plan-cell"
                *ngIf="deploymentPlanListDisplayed[product.id] != null && deploymentPlanListDisplayed[product.id][site.id] != null"
                [style]="{
                  'background-color': getBackgroundByYear(deploymentPlanListDisplayed[product.id][site.id].deploymentPlan),
                  background:
                    site.name == product.pilot?.name &&
                    'repeating-linear-gradient(-45deg, rgba(133, 136, 138, 1) 0px, rgba(133, 136, 138, 1) 1px, transparent 2px, transparent 10px),' +
                      getBackgroundByYear(deploymentPlanListDisplayed[product.id][site.id].deploymentPlan),
                  'border-left':
                    '5px solid ' +
                    (deploymentPlanListDisplayed[product.id][site.id].status
                      ? getBackgroundByStatus(deploymentPlanListDisplayed[product.id][site.id].status)
                      : getBackgroundByYear(deploymentPlanListDisplayed[product.id][site.id].deploymentPlan))
                }"
                [attr.data-cypress]="'cell-' + deploymentPlanListDisplayed[product.id][site.id].status"
                [attr.data-cypress-product]="product.name"
                [attr.data-cypress-site]="site.name"
                [attr.data-cypress-plan]="deploymentPlanListDisplayed[product.id][site.id].deploymentPlan">
                <span>{{ deploymentPlanListDisplayed[product.id][site.id].deploymentPlan }}</span>
              </div>
            </div>
          </ng-container>
          <div class="top-target-plan" [style]="{'grid-column': sitesDisplayed.length + 2}" style="grid-row: 1">
            <div class="top-target-plan-text">Depl. Target</div>
          </div>
          <ng-container *ngFor="let product of productsDisplayed; let i = index">
            <div [style]="{'grid-column': sitesDisplayed.length + 2, 'grid-row': i + 2}" class="target-value">
              {{ getTargetForProduct(product.id) }}
            </div>
          </ng-container>
          <div class="bottom-target-plan" [style]="{'grid-row': productsDisplayed.length + 2}" style="grid-column: 1">
            <div class="bottom-target-plan-text">Total</div>
          </div>
          <ng-container *ngFor="let site of sitesDisplayed; let i = index">
            <div [style]="{'grid-row': productsDisplayed.length + 2, 'grid-column': i + 2}" class="target-value">
              {{ getTargetForSite(site.id) }}
            </div>
          </ng-container>
          <div class="target-value" [style]="{'grid-row': productsDisplayed.length + 2, 'grid-column': sitesDisplayed.length + 2}">
            {{ getSumTarget() }} {{ anyFilterActive() ? "(filtered)" : "/ yrs" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
