import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {ProductDTO} from "./user.service";

@Injectable({
  providedIn: "root",
})
export class SharedEventsService {
  private readonly reloadHeaderSubject = new Subject<void>();
  private readonly selectedProductSubject = new BehaviorSubject<ProductDTO | null>(null);
  private readonly productUpdatedSource = new Subject<void>();
  private readonly deploymentPlanUpdatedSource = new Subject<void>();
  private readonly portfolioStatusUpdatedSource = new Subject<void>();
  private readonly dpcDataSavedSource = new Subject<void>();

  reloadHeader$ = this.reloadHeaderSubject.asObservable();
  selectedProduct$ = this.selectedProductSubject.asObservable();
  productUpdated$ = this.productUpdatedSource.asObservable();
  deploymentPlanUpdated$ = this.deploymentPlanUpdatedSource.asObservable();
  portfolioStatusUpdated$ = this.portfolioStatusUpdatedSource.asObservable();
  dpcDataSaved$ = this.dpcDataSavedSource.asObservable();

  notifyReloadHeader() {
    this.reloadHeaderSubject.next();
  }

  setSelectedProduct(product: ProductDTO): void {
    this.selectedProductSubject.next(product);
  }

  notifyProductUpdated() {
    this.productUpdatedSource.next();
  }

  notifyDeploymentPlanUpdated() {
    this.deploymentPlanUpdatedSource.next();
  }

  notifyPortfolioStatusUpdated() {
    this.portfolioStatusUpdatedSource.next();
  }

  notifyDpcDataSaved() {
    this.dpcDataSavedSource.next();
  }
}
