import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UrlRedirectionService {
  private readonly oldDomain = environment.oldDomain;
  private readonly newDomain = environment.newDomain;

  constructor(public router: Router) {}

  checkAndRedirect(): boolean {
    const currentUrl = window.location.href;
    if (currentUrl.includes(this.oldDomain)) {
      const newUrl = currentUrl.replace(this.oldDomain, this.newDomain);
      window.location.replace(newUrl + "?show-banner=true");
      return true;
    }
    return false;
  }
}
