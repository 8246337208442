import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {Chart} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {ToastrService} from "ngx-toastr";
import {DpcComment, DpcCommentService} from "src/app/common/dpc-comment.service";
import {SharedEventsService} from "src/app/common/shared-events-service";
import {SharedStateService} from "src/app/common/shared-state.service";
import {categories, quarters} from "src/app/common/util/const";
import {DeployItActivityDistributionService} from "./deploy-it-activity-distribution.service";

Chart.register(ChartDataLabels);

@Component({
  selector: "deploy-it-activity-distribution",
  templateUrl: "./deploy-it-activity-distribution.component.html",
  styleUrls: ["./deploy-it-activity-distribution.component.scss"],
})
export class DeployItActivityDistributionComponent implements OnChanges, OnInit {
  @Input() section!: string;
  @Input() isWriter: boolean;
  @Input() canvasId: string;
  @Input() branch: string;
  @Input() lbus: any;

  @Input() activityPerQuarterComment: {[branch: string]: {[domain: string]: DpcComment}};
  @Input() activityPerProductComment: {[branch: string]: {[quarter: string]: DpcComment}};

  loading: boolean = true;
  chart: any;
  meanChart: any;
  lastUpdate: Date;

  deployItActivityDistributionData: any;
  hasData: boolean;

  comment: string = "";

  barColors = ["#9FA8DA", "#90CAF9", "#CE93D8", "#FFAB91", "#FFCC80", "#80CBC4"];
  quarters = quarters;
  categories = categories;
  selectedQuarter: string = quarters[0];
  selectedDomain: string = "DrillX";
  isMinDisabled: boolean = true;
  isMaxDisabled: boolean = false;
  hiddenDrillXChart: any;
  hiddenAiOChart: any;
  showHiddenComponent: boolean = false;

  constructor(
    private readonly deployItActivityDistributionService: DeployItActivityDistributionService,
    private readonly toastrService: ToastrService,
    private readonly dpcCommentService: DpcCommentService,
    private readonly sharedEventsService: SharedEventsService,
    private readonly sharedStateService: SharedStateService
  ) {}

  ngOnInit(): void {
    this.sharedStateService.showHiddenComponentDPC$.subscribe((show) => {
      this.showHiddenComponent = show;
    });

    this.sharedEventsService.deploymentPlanUpdated$.subscribe(() => {
      this.loadData();
    });

    this.sharedEventsService.productUpdated$.subscribe(() => {
      this.loadData();
    });

    // Subscribe to changes in the selected quarter
    this.sharedStateService.getSelectedQuarterObservable("DeployIt").subscribe((quarter) => {
      this.selectedQuarter = quarter;
      if (this.hasData) {
        setTimeout(() => {
          this.createChart();
          this.updateNavigationButtons();
          this.getActivityPerProductComment();
        }, 0);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.branch?.currentValue) {
      this.loading = true;
      this.loadData();
    }
  }

  selectQuarter(quarter: string): void {
    this.sharedStateService.setSelectedQuarter("DeployIt", quarter);
    if (this.hasData) {
      setTimeout(() => {
        this.createChart();
        this.updateNavigationButtons();
        this.getActivityPerProductComment();
      }, 0);
    }
  }

  getActivityPerQuarterComment(domain: string): DpcComment | undefined {
    if (this.branch === "RC") {
      return this.activityPerQuarterComment?.[this.branch]?.[""];
    }
    if (domain === "DrillX") {
      return this.activityPerQuarterComment?.[this.branch]?.["DrillX"];
    } else if (domain === "AiO") {
      return this.activityPerQuarterComment?.[this.branch]?.["AiO"];
    }
    return undefined;
  }

  getActivityPerProductComment(): DpcComment | undefined {
    return this.activityPerProductComment?.[this.branch]?.[this.selectedQuarter];
  }

  loadData(): void {
    this.deployItActivityDistributionService.getDeployItActivityDistributionByProductByQuarterByBranch(this.branch).subscribe(
      (data) => {
        this.deployItActivityDistributionData = data;
        this.hasData =
          this.deployItActivityDistributionData[this.selectedQuarter] &&
          Object.keys(this.deployItActivityDistributionData[this.selectedQuarter]).length > 0;

        // Calculate total deployments and new products by domain
        this.calculateTotalsByDomain();

        this.createChart();
        this.loading = false;
      },
      () => {
        this.toastrService.error("An error occurred while fetching the activity data");
        this.hasData = false;
        this.loading = false;
      }
    );
  }

  calculateTotalsByDomain(): void {
    for (let quarter of this.quarters) {
      let quarterData = this.deployItActivityDistributionData[quarter];
      if (quarterData) {
        let totalDeploymentsDrillX = 0;
        let totalDeploymentsAiO = 0;
        let newProductsDrillX: string[] = [];
        let newProductsAiO: string[] = [];
        let multiProductDrillX: string[] = [];
        let multiProductAiO: string[] = [];

        for (let productName in quarterData) {
          if (quarterData.hasOwnProperty(productName)) {
            let productData = quarterData[productName];
            let domains = productData.domains || [];
            let nbrDeployment = productData.nbr_deployment || 0;
            let isNewProduct = productData.new || false;
            let isMultiProduct = productData.multi_product || false;

            if (domains.includes("Drilling & Wells")) {
              totalDeploymentsDrillX += nbrDeployment;
              if (isNewProduct) {
                newProductsDrillX.push(productName);
              }
              if (isMultiProduct) {
                multiProductDrillX.push(productName);
              }
            } else {
              totalDeploymentsAiO += nbrDeployment;
              if (isNewProduct) {
                newProductsAiO.push(productName);
              }
              if (isMultiProduct) {
                multiProductAiO.push(productName);
              }
            }
          }
        }

        quarterData.nbr_deployment_drillx = totalDeploymentsDrillX;
        quarterData.nbr_deployment_aio = totalDeploymentsAiO;
        quarterData.new_products_drillx = newProductsDrillX;
        quarterData.new_products_aio = newProductsAiO;
        quarterData.multi_product_drillx = multiProductDrillX;
        quarterData.multi_product_aio = multiProductAiO;
      }
    }
  }

  createChart(): void {
    if (this.chart) this.chart.destroy();

    let datasets = [];
    let products = Object.keys(this.deployItActivityDistributionData[this.selectedQuarter])
      .filter((key) => {
        const productData = this.deployItActivityDistributionData[this.selectedQuarter][key];
        const metrics = productData.metrics || {};
        const hasNonZeroMetric = Object.values(metrics).some((value) => value !== 0);
        return (
          !key.startsWith("nbr_deployment") &&
          !key.startsWith("new_products") &&
          !key.startsWith("multi_product") &&
          Object.keys(metrics).length > 0 &&
          hasNonZeroMetric
        );
      })
      .sort((a, b) => a.localeCompare(b));

    for (let category of this.categories) {
      let datasetData = products.map((product) => this.deployItActivityDistributionData[this.selectedQuarter][product].metrics[category.key]);

      datasets.push({
        label: category.name,
        data: datasetData,
        backgroundColor: this.barColors[this.categories.indexOf(category)],
        stack: "stack1",
        type: "bar",
        borderWidth: {
          top: 2,
          right: 0,
          bottom: 0,
          left: 0,
        },
        borderColor: "#FFF",
        borderRadius: 4,
      });
    }

    this.chart = new Chart(`activityDistributionChart-${this.selectedQuarter}`, {
      data: {
        labels: products,
        datasets: datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              callback: function (value, index) {
                const label = this.getLabelForValue(index);
                return label.length > 10 ? label.slice(0, 10) + "..." : label; // Shorten labels
              },
              font: {
                weight: "bold",
              },
            },
            min: 0,
            max: 9,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: (value) => {
                return value;
              },
              font: {
                weight: "bold",
              },
            },
            min: 0,
            max: 100,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },

          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const datasetLabel = tooltipItem.dataset.label;
                const value = parseFloat(tooltipItem.raw.toString());

                return `${datasetLabel}: ${value} %`;
              },
            },
          },
          datalabels: {
            color: "white",
            display: (context) => {
              return context.dataset.data[context.dataIndex] !== 0;
            },
            font: {
              weight: "bold",
            },
            formatter: (value) => {
              return value;
            },
          },
        },
      },
    });
    if (this.chart.scales["x"].max === this.chart.data.labels.length - 1) {
      this.isMaxDisabled = true;
    }
  }

  getMultiProductCount(quarter: string): number {
    return Object.values(this.deployItActivityDistributionData[quarter] || {}).filter((product: any) => product.multi_product).length;
  }

  getMultiProductNames(quarter: string): string {
    return Object.keys(this.deployItActivityDistributionData[quarter] || {})
      .filter((productName) => {
        return this.deployItActivityDistributionData[quarter][productName].multi_product;
      })
      .join(" - ");
  }

  getNewProductName(quarter: string): string {
    return (
      Object.keys(this.deployItActivityDistributionData[quarter] || {}).find((productName) => {
        return this.deployItActivityDistributionData[quarter][productName].new;
      }) || "None"
    );
  }

  saveComment(commentText: string): void {
    let commentPayload: DpcComment;

    commentPayload = this.activityPerProductComment?.[this.branch]?.[this.selectedQuarter];

    if (commentText === "<p></p>") {
      commentText = null;
      commentPayload.comment = null;
    }

    const updatedCommentPayload: DpcComment = {
      ...commentPayload,
      comment: commentText,
      postedOn: new Date(),
      quarter: this.selectedQuarter,
      branch: this.branch,
      title: "Activity per Product Comment",
    };

    this.dpcCommentService.saveComment(updatedCommentPayload).subscribe(
      () => {
        this.toastrService.success(`Comment saved successfully!`);
        // Update the comment locally

        this.activityPerProductComment[this.branch][this.selectedQuarter].comment = commentText;
      },
      () => {
        this.toastrService.error(`Error while saving the comment.`);
      }
    );
  }

  getCategoryColor(category: string): string {
    const index = this.categories.indexOf(category);
    return index !== -1 ? this.barColors[index] : "#000000";
  }

  isQuarterInPastOrCurrent(quarter: string): boolean {
    const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
    const quarterNumber = quarter.split("Q")[1];
    return parseInt(quarterNumber) <= currentQuarter;
  }

  navigateLeft(): void {
    const chart = this.chart;
    const xScale = chart.scales["x"];
    if (xScale.min > 0) {
      chart.options.scales.x.min = xScale.min - 1;
      chart.options.scales.x.max = xScale.max - 1;
      chart.update();
      this.updateNavigationButtons();
    }
  }

  navigateRight(): void {
    const chart = this.chart;
    const xScale = chart.scales["x"];
    if (xScale.max < chart.data.labels.length - 1) {
      chart.options.scales.x.min = xScale.min + 1;
      chart.options.scales.x.max = xScale.max + 1;
      chart.update();
      this.updateNavigationButtons();
    }
  }

  updateNavigationButtons(): void {
    const xScale = this.chart.scales["x"];
    this.isMinDisabled = xScale.min === 0;
    this.isMaxDisabled = xScale.max === this.chart.data.labels.length - 1;
  }
}
