import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "app-select-quarter-dialog",
  templateUrl: "./select-quarter-dialog.component.html",
})
export class SelectQuarterDialogComponent {
  quarters: string[] = ["Q1", "Q2", "Q3", "Q4"];
  selectedQuarter: string;

  constructor(public dialogRef: MatDialogRef<SelectQuarterDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
    this.selectedQuarter = `Q${currentQuarter}`;
  }
}
