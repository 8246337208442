import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Editor, Toolbar} from "ngx-editor";
import {ToastrService} from "ngx-toastr";
import {UserDTO} from "src/app/common/user.service";
import {DateUtil} from "src/app/common/util/date-util";
import schema from "src/app/common/util/schema";
import {EightPillarsService, Pillar} from "src/app/eight-pillars/eight-pillars.service";
import {ActionPlan, ActionPlanService, DPCActionPlan} from "../action-plan.service";
import {Product} from "../../product-details/product-details.service";
import {ProductService} from "../../product/product.service";
import {Observable} from "rxjs";
import {DCUnifiedPlugin} from "src/app/common/util/plugins";

@Component({
  selector: "app-manage-action",
  templateUrl: "./manage-action.component.html",
  styleUrls: ["./manage-action.component.scss"],
})
export class ManageActionComponent implements OnInit {
  actionForm: FormGroup = this.fb.group({});
  title: string;
  isEditMode: boolean;
  pillars: Pillar[] = [];
  products: Product[] = [];
  statusOptions: string[] = ["To do", "In progress", "Blocked", "Done", "Decision", "Info"];
  activeMentionIndex: number = 0;
  saveInProgress: boolean = false;
  isProduct: boolean = false;
  isDpc: boolean = false;
  lbus: any;

  public editor: Editor;
  public toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["ordered_list", "bullet_list"],
    [{heading: ["h1", "h2", "h3", "h4", "h5", "h6"]}],
    ["text_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
  ];

  constructor(
    readonly fb: FormBuilder,
    readonly actionPlanService: ActionPlanService,
    public dialogRef: MatDialogRef<ManageActionComponent>,
    readonly toastrService: ToastrService,
    readonly pillarService: EightPillarsService,
    private readonly productService: ProductService,
    @Inject(MAT_DIALOG_DATA) public data: {
      productId: number;
      action?: ActionPlan;
      dpcAction?: DPCActionPlan;
      motherPage: string;
      branch: string;
      lbus: any;
      products: any}
  ) {;
    this.isEditMode = this.data.motherPage == "product" ? !!this.data.action : !!this.data.dpcAction;
    this.title = this.isEditMode ? "Edit Action" : "Create New Action";
    this.lbus = this.data.lbus;
    this.products = this.data.products;
  }

  updateStatusOptions(type: string) {
    let statusControl;
    let deadlineControl;

    statusControl = this.actionForm.get("status");
    deadlineControl = this.actionForm.get("deadline");

    if (type === "Info") {
      this.statusOptions = ["Info"];
      statusControl?.setValue("Info");
      statusControl?.disable();
    } else if (type === "Decision") {
      this.statusOptions = ["Decision"];
      statusControl?.setValue("Decision");
      statusControl?.disable();
    } else { // type === "Action"
      statusControl?.enable();
      if (statusControl.value === "Infos" || statusControl.value === "Decision") {
        statusControl.setValue("To do");
      }
      this.statusOptions = ["To do", "In progress", "Blocked", "Done"];
    }

    if (type === "Action") {
      deadlineControl?.enable();
    } else {
      deadlineControl?.disable();
      deadlineControl.setValue(null);
    }
  }

  ngOnInit(): void {
    let plugins = DCUnifiedPlugin.getPlugins(this.lbus, this.products);
    this.editor = new Editor({
      schema,
      plugins
    });

    this.isProduct = this.data.motherPage === "product";
    this.isDpc = this.data.motherPage === "dpc";

    if (this.isProduct) {
      this.createFormProduct();
    } else if (this.isDpc) {
      this.createFormDPC();
    }

    this.listenToFormChanges();
  }

  private createFormProduct() {
    const isInfoType = this.isEditMode && this.data.action?.type === "Info";
    const isDecisionType = this.isEditMode && this.data.action?.type === "Decision";
    const isActionType = this.isEditMode && this.data.action?.type === "Action";
    let defaultStatus = "To do";
    if (isInfoType) defaultStatus = "Info";
    else if (isDecisionType) defaultStatus = "Decision";

    this.actionForm = this.fb.group({
      title: ["", Validators.required],
      type: ["Action", Validators.required],
      status: [{value: defaultStatus, disabled: isInfoType || isDecisionType}, Validators.required],
      creationDate: [{value: new Date().toISOString().split("T")[0], disabled: this.isEditMode}, Validators.required],
      responsible: ["", Validators.required],
      deadline: [{value: "", disabled: isActionType}],
      comment: [""],
      committee: ["No committee"],
      pillarCard: [{ id: null }, Validators.required],
      pillarName: ["", Validators.required],
    });

    this.pillarService.getPillarsByProductId(this.data.productId).subscribe((data) => {
      this.pillars = data;
      if (this.pillars.length > 0) {
        this.actionForm.patchValue({
          pillarCard: {id: this.pillars[0].id},
          pillarName: this.pillars[0].name,
        });

        if (this.isEditMode && this.data.action) {
          let pillar = this.pillars.find((pillar) => pillar.name === this.data.action.pillarName);
          let pillarId = pillar.id;
          this.actionForm.patchValue({
            title: this.data.action.title,
            type: this.data.action.type,
            status: this.data.action.status,
            pillarCard: {id: pillarId},
            pillarName: this.data.action.pillarName,
            creationDate: DateUtil.dateWithoutTimezone(this.data.action.creationDate),
            responsible: this.data.action.responsible,
            deadline: this.data.action.deadline ? DateUtil.dateWithoutTimezone(this.data.action.deadline) : "",
            comment: this.data.action.comment,
            committee: this.data.action.committee,
          });
        }
      }
    });
  }

  private createFormDPC() {
    const isInfoType = this.isEditMode && this.data.dpcAction?.type === "Info";
    const isDecisionType = this.isEditMode && this.data.dpcAction?.type === "Decision";
    const isActionType = this.isEditMode && this.data.dpcAction?.type === "Action";
    let defaultStatus = "To do";
    if (isInfoType) defaultStatus = "Info";
    else if (isDecisionType) defaultStatus = "Decision";

    this.actionForm = this.fb.group({
      type: ["Action", Validators.required],
      status: [{value: defaultStatus, disabled: isInfoType || isDecisionType}, Validators.required],
      dpcDate: [{ value: new Date().toISOString().split("T")[0], disabled: this.isEditMode }, Validators.required],
      responsible: ["", Validators.required],
      deadline: [{ value: "", disabled: isActionType }],
      comment: [""],
    });

    if (this.isEditMode && this.data.dpcAction) {
      this.actionForm.patchValue({
        type: this.data.dpcAction.type,
        status: this.data.dpcAction.status,
        dpcDate: DateUtil.dateWithoutTimezone(this.data.dpcAction.dpcDate),
        responsible: this.data.dpcAction.responsible,
        deadline: this.data.dpcAction.deadline ? DateUtil.dateWithoutTimezone(this.data.dpcAction.deadline) : "",
        comment: this.data.dpcAction.comment,
      });
    }
  }

  private listenToFormChanges() : void {
    if (this.isProduct) {
      this.actionForm.get("pillarName")?.valueChanges.subscribe((newPillarName: string) => {
        const selectedPillar = this.pillars.find((pillar) => pillar.name === newPillarName);
        if (selectedPillar) {
          this.actionForm.patchValue({pillarCard: {id: selectedPillar.id}}, {emitEvent: false});
        }
      });
    }

    this.actionForm.get("type")?.valueChanges.subscribe((type: string) => {
      this.updateStatusOptions(type);
    });

    const initialType = this.actionForm.get("type")?.value;
    this.updateStatusOptions(initialType);
  }

  private loadProducts(): void {
    this.productService.getAllProductsByBranch(this.data.branch).subscribe((products) => {
      this.products = products;
    });
  }

  onSubmit(): void {
    if (this.actionForm.valid) {
      const rawFormData = this.actionForm.getRawValue();
      const formData = {
        ...rawFormData,
        ...(this.isProduct && { pillarCard: { id: rawFormData.pillarCard?.id } }),
        product: this.isProduct ? this.data.productId : null,
        responsible: {email: rawFormData.responsible.email, fullName: rawFormData.responsible.fullName},
        branch: this.data.branch,
        lastUpdateDate: new Date().toISOString().split("T")[0],
      };
      this.saveInProgress = true;

      let actionObservable: Observable<any>;

      if (this.isEditMode) {
        actionObservable = this.isDpc
          ? this.actionPlanService.updateDpcActionPlan(this.data.dpcAction.id, formData)
          : this.actionPlanService.updateActionPlan(this.data.action.id, formData);
      } else {
        actionObservable = this.isDpc
          ? this.actionPlanService.createDpcActionPlan(formData)
          : this.actionPlanService.createActionPlan(formData);
      }

      actionObservable.subscribe(
        () => {
          this.saveInProgress = false;
          this.toastrService.success(`Action has been ${this.isEditMode ? "updated" : "created"}!`);
          this.dialogRef.close(true);
        },
        () => {
          this.saveInProgress = false;
          this.toastrService.error(`Failed to ${this.isEditMode ? "update" : "create"} action.`);
        }
      );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onUsersSelected(selectedUsers: UserDTO[], key: string): void {
    this.actionForm.patchValue({
      [key]: selectedUsers[0],
    });
  }
}
