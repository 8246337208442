<div class="accordion" id="product-details-container">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" [id]="'accordion-btn-header-' + section">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#accordion-btn-collapse-' + section"
        aria-expanded="true"
        [attr.aria-controls]="'accordion-btn-collapse-' + section">
        <div class="container-left-accordion-title">
          <img *ngIf="productDetails.logo; else defaultLogo" [src]="productDetails.logo" alt="{{ productDetails.name }}" class="product-logo" />
          <ng-template #defaultLogo>
            <span class="material-icons-outlined color_red" aria-hidden="true" color="accent"> devices_fold </span>
          </ng-template>
          {{ productDetails?.name }}
        </div>
      </button>
      <div class="container-right">
        <span class="last-update-text" *ngIf="productDetails?.productDetailsLastUpdateDate">
          Last Update: {{ productDetails.productDetailsLastUpdateDate | date : "dd/MM/yyyy" : "UTC" }}
        </span>
        <div class="additional-btn no-export" *ngIf="isWriter || isAdmin">
          <button type="button" class="btn btn-secondary" data-cypress="edit-button" (click)="openEditDialog()">
            <span class="material-icons-outlined" aria-hidden="true">edit</span>Edit
          </button>
        </div>
      </div>
    </h4>
    <div [id]="'accordion-btn-collapse-' + section" class="accordion-collapse show" [attr.aria-labelledby]="'accordion-btn-header-' + section">
      <div class="accordion-body">
        <!-- View Mode -->
        <div class="row">
          <div class="col-md-9">
            <div class="row">
              <ng-container *ngFor="let field of viewFields">
                <div
                  class="col-md-3 mb-2"
                  *ngIf="field.label != 'Sites' || productDetails.branches.indexOf('RC') > -1"
                  attr.data-cypress="{{ field.label }}">
                  <strong>{{ field.label }}</strong>
                  <!-- LBU Field with Flags in View Mode -->
                  <div *ngIf="field.label === 'LBU' && field.value != [] && field.value != 'No Data'; else defaultView">
                    <ng-container *ngFor="let country of field.value">
                      <img [src]="country.flag" title="{{ country.name }}" alt="{{ country.name }}" class="flag-icon me-1" />
                    </ng-container>
                  </div>
                  <ng-template #defaultView>
                    <div [innerHTML]="field.value || 'No Data'"></div>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col-md-3">
            <div class="row mb-2">
              <div class="col-12">
                <strong>Status</strong>
              </div>
            </div>
            <ng-container *ngFor="let statusField of statusFields">
              <div class="status-col d-flex align-items-center" *ngIf="statusField.label !== 'Decommissioning' || statusField.value">
                <strong class="me-2">{{ statusField.label }}:</strong>
                <div *ngIf="statusField.value; else noData">
                  {{ statusField.value | date : "dd/MM/yyyy" : "UTC" }}
                </div>
                <ng-template #noData>
                  <div>No Data</div>
                </ng-template>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- Product Description Section -->
        <div class="product-description">
          <button class="btn btn-link d-flex align-items-center" (click)="toggleDescription()">
            <span class="material-icons-outlined me-2 rotate-icon" [class.rotated]="isDescriptionVisible"> chevron_right </span>
            <span>Product Description</span>
          </button>
          <div *ngIf="isDescriptionVisible" class="description-content">
            <p [innerHTML]="productDetails?.description || 'No description available.' | safeHtml"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
