<popup-header [dialogRef]="dialogRef" icon="grading" text="{{ popupTitle }}"></popup-header>
<div class="popup-container">
  <div class="segmented-control-container {{type}}" *ngIf="availablePredefinedItems.length > 0">
    <button
      class="segmented-control-item"
      [ngClass]="{selected: selectedSegment == 0}"
      (click)="selectedSegment = 0"
      (keydown)="$event.key == 'Enter' ? (selectedSegment = 0) : null">
      Predefined {{this.type}}s
    </button>
    <button
      class="segmented-control-item"
      [ngClass]="{selected: selectedSegment == 1}"
      (click)="selectedSegment = 1"
      (keydown)="$event.key == 'Enter' ? (selectedSegment = 1) : null"
      data-cypress="custom-name">
      Custom {{this.type}} name
    </button>
  </div>
  <div class="segmented-control-content">
    <div class="form-group" *ngIf="selectedSegment == 0">
      <select class="form-select" [(ngModel)]="predefinedItemName">
        <option [value]="0" disabled selected>Choose a predefined {{this.type}}</option>
        <option *ngFor="let item of availablePredefinedItems" [value]="item">{{item}}</option>
      </select>
    </div>

    <div class="form-group" *ngIf="selectedSegment == 1">
      <input type="text" class="form-control" placeholder="Custom {{this.type}} name" [(ngModel)]="title" data-cypress="custom-name-input"/>
    </div>

    <div class="form-group item-container" *ngIf="type == 'link'">
      <input type="text" class="form-control" placeholder="Link" [(ngModel)]="link"/>
    </div>

    <div class="item-container"
         dndDropzone
         (dndDrop)="onDrop($event)"
         [dndAllowExternal]="true"
         *ngIf="type == 'document'">
      <form class="upload-file">
        <input id="upload-input-add-toolbox-item" type="file" name="fileUpload" (change)="getFile($event)"/>

        <label for="upload-input-add-toolbox-item" class="upload-label align-items-center">
          <span class="btn btn-primary">
            <span class="material-icons" aria-hidden="true">cloud_upload</span> Select file
          </span>
          <span class="ms-3">...or drag and drop file here</span>
        </label>
        <div class="uploaded-name" *ngIf="document">Uploaded file : {{document.name}}</div>
      </form>
    </div>
  </div>

  <div class="error-message" *ngIf="errorMessage">{{ errorMessage }}</div>

  <div class="bottom-popup">
    <div class="buttons-container">
      <button type="button" class="btn btn-secondary" (click)="closePopup()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="saveLink()" [disabled]="saveInProgress" data-cypress="save-item-button">
        <span class="spinner-border spinner-border-sm" output="status" aria-hidden="true" *ngIf="saveInProgress"></span>
        Save
      </button>
    </div>
  </div>
</div>
