import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {Editor, Toolbar} from "ngx-editor";
import {ToastrService} from "ngx-toastr";
import {SharedStateService} from "src/app/common/shared-state.service";
import {TableService} from "src/app/common/table.service";
import {SharedEventsService} from "../../common/shared-events-service";
import {quarters} from "../../common/util/const";
import {DCMentionsPlugin} from "../../common/util/plugins";
import schema from "../../common/util/schema";
import {PortfolioReviewService} from "../portfolio-review.service";

@Component({
  selector: "team-confidence",
  templateUrl: "./team-confidence.component.html",
  styleUrls: ["./team-confidence.component.scss"],
})
export class TeamConfidenceComponent implements OnInit, OnChanges {
  @Input() branch: string;
  @Input() isWriter: boolean;

  teamConfidence: any = {};

  dataLoading: boolean = false;
  saveInProgress: boolean = false;

  quarters: Array<string> = quarters;
  selectedQuarter: string = quarters[0];

  sortedColumn: string | null = null;
  sortDirection: "asc" | "desc" = "asc";

  public toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["ordered_list", "bullet_list"],
    [{heading: ["h1", "h2", "h3", "h4", "h5", "h6"]}],
    ["text_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
  ];

  currentYear: string;

  constructor(
    private readonly portfolioReviewService: PortfolioReviewService,
    private readonly toastrService: ToastrService,
    private readonly sharedEventsService: SharedEventsService,
    private readonly sharedStateService: SharedStateService,
    private readonly tableService: TableService
  ) {
    this.currentYear = new Date().getFullYear().toString().substring(2, 4);
  }

  ngOnInit() {
    this.sharedEventsService.productUpdated$.subscribe(() => {
      this.loadTeamConfidence();
    });
    this.sharedEventsService.deploymentPlanUpdated$.subscribe(() => {
      this.loadTeamConfidence();
    });

    // Subscribe to changes in the selected quarter
    this.sharedStateService.getSelectedQuarterObservable("TeamConfidence").subscribe((quarter) => {
      this.selectedQuarter = quarter;
      this.loadTeamConfidence();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.branch?.currentValue) {
      this.loadTeamConfidence();
    }
  }

  selectQuarter(quarter: string): void {
    this.selectedQuarter = quarter;
    this.sharedStateService.setSelectedQuarter("TeamConfidence", quarter);
  }

  loadTeamConfidence() {
    this.dataLoading = true;

    this.portfolioReviewService.getTeamConfidence(this.branch).subscribe(
      (infoRes) => {
        this.teamConfidence = infoRes;

        this.dataLoading = false;
      },
      () => {
        this.toastrService.error("An error occurred while retrieving the team confidence");

        this.dataLoading = false;
      }
    );
  }

  startEditing(confidenceRow: any) {
    if (confidenceRow.editor == null) {
      let plugins = DCMentionsPlugin.getPlugins(null);
      confidenceRow.editor = new Editor({
        schema,
        plugins,
      });
    }

    confidenceRow.isEditing = true;
    confidenceRow.originalComment = confidenceRow.comment;
    if (confidenceRow.comment == null) confidenceRow.comment = "";
  }

  cancelEditing(confidenceRow: any) {
    confidenceRow.isEditing = false;
    confidenceRow.comment = confidenceRow.originalComment;
  }

  saveEditedComment(confidenceRow: any) {
    this.saveInProgress = true;
    this.portfolioReviewService
      .saveTeamConfidenceComment(
        this.branch,
        confidenceRow.productName,
        confidenceRow.siteName,
        this.selectedQuarter.split("Q")[1] + "Q" + this.currentYear,
        confidenceRow.comment
      )
      .subscribe(
        () => {
          this.toastrService.success("Comment saved successfully !");
          confidenceRow.isEditing = false;

          this.saveInProgress = false;
        },
        () => {
          this.toastrService.error("An error occurred while saving the comment.");

          this.saveInProgress = false;
        }
      );
  }

  getConfidenceColor(confidence: string): string {
    switch (confidence) {
      case "On Track":
        return "#FFBE0B"; // Yellow
      case "Attention Point":
        return "#6E7781"; // Grey
      case "Blocked":
        return "#222B2D"; // Black
      case "Cancelled":
        return "#D10000"; // Red
      case "Neutral":
        return "#2196F3"; // Blue
      default:
        return "";
    }
  }

  getIconFromConfidence(confidence: string) {
    switch (confidence) {
      case "On Track":
        return "wb_sunny";
      case "Attention Point":
        return "filter_drama";
      case "Blocked":
        return "thunderstorm";
      case "Cancelled":
        return "cancel";
      case "Neutral":
        return "not_listed_location";
      default:
        return "";
    }
  }

  changeTeamConfidence(confidenceRow: any, confidence: string) {
    this.portfolioReviewService
      .changeTeamConfidence(
        this.branch,
        confidenceRow.productName,
        confidenceRow.siteName,
        this.selectedQuarter.split("Q")[1] + "Q" + this.currentYear,
        confidence
      )
      .subscribe(
        () => {
          this.toastrService.success("Confidence updated successfully !");
          confidenceRow.confidence = confidence;
        },
        () => {
          this.toastrService.error("An error occurred while updating the confidence.");
        }
      );
  }

  onSort(column: string): void {
    if (this.sortedColumn === column) {
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    } else {
      this.sortedColumn = column;
      this.sortDirection = "asc";
    }

    this.teamConfidence[this.selectedQuarter] = this.tableService.sortData(this.teamConfidence[this.selectedQuarter], column, this.sortDirection);
  }
}
