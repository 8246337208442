<div class="accordion">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" [id]="'accordion-btn-header-' + 'status'">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#accordion-btn-collapse-' + 'status'"
        aria-expanded="true"
        [attr.aria-controls]="'accordion-btn-collapse-' + 'status'">
        <div class="container-left-accordion-title">
          <span class="material-icons-outlined color_red" aria-hidden="true" color="accent"> map </span>
          Portfolio Status & KPI
        </div>
      </button>
      <div class="container-right">
        <span *ngIf="lastUpdate" class="last-update-text">Last Update: {{ lastUpdate | date : "dd/MM/yyyy" : "UTC" }}</span>
        <!-- Edit Mode Switch -->
        <div class="form-check form-switch no-export" *ngIf="isWriter || isAdmin">
          <input class="form-check-input edit-switch" type="checkbox" id="defaultSwitch1" checked="" [(ngModel)]="isEditMode" />
          <label class="form-check-label" for="defaultSwitch1">Edit</label>
        </div>
      </div>
    </h4>
    <div id="accordion-btn-collapse-status" class="accordion-collapse show">
      <div class="accordion-body">
        <div class="domain-filters">
          <!-- Dynamically generate checkboxes based on active domains -->
          <label class="form-check-label" *ngFor="let domain of activeDomains">
            <input
              type="checkbox"
              class="form-check-input"
              [checked]="isDomainSelected(domain)"
              (change)="toggleDomain(domain)"
              [style.background-color]="isDomainSelected(domain) ? getDomainFlagColor(domain) : ''"
              [style.border-color]="isDomainSelected(domain) ? getDomainFlagColor(domain) : ''" />
            {{ domain }}
          </label>
        </div>
        <div class="kanban-board">
          <div
            *ngFor="let step of steps"
            class="kanban-column"
            [ngClass]="{'operational-product-column': step.name === 'OPERATIONAL PRODUCT (Transferred / Run)'}"
            dndDropzone
            (dndDrop)="onDrop($event, step.name)">
            <div class="kanban-header" [style.background]="step.color">
              <h3>
                {{ step.name }}
              </h3>
              <span>{{ productsByStep[step.name]?.length || 0 }} use cases</span>
            </div>
            <div class="kanban-items" [ngClass]="{'is-edit-mode': isEditMode}">
              <ng-container *ngFor="let product of productsByStep[step.name]">
                <!-- Edit mode draggable -->
                <div
                  *ngIf="isEditMode; else noDrag"
                  class="kanban-card draggable"
                  [style.background]="getDomainColorCards(product.domain)"
                  dndDraggable
                  [dndDraggable]="product"
                  (click)="toggleProductDetails($event, product.productId); $event.stopPropagation()"
                  (keydown)="toggleProductDetails($event, product.productId)"
                  [attr.data-cypress]="product.step != product.previousStep && product.previousStep ? 'hasChanged' : ''">
                  <ng-container *ngTemplateOutlet="cardContent; context: {product: product}"></ng-container>
                </div>

                <!-- View mode non-draggable  -->
                <ng-template #noDrag>
                  <div
                    class="kanban-card"
                    [style.background]="getDomainColorCards(product.domain)"
                    (click)="toggleProductDetails($event, product.productId); $event.stopPropagation()"
                    (keydown)="toggleProductDetails($event, product.productId)"
                    [attr.data-cypress]="product.step != product.previousStep && product.previousStep ? 'hasChanged' : ''">
                    <ng-container *ngTemplateOutlet="cardContent; context: {product: product}"></ng-container>
                  </div>
                </ng-template>
              </ng-container>

              <ng-template #cardContent let-product="product">
                <div class="product-infos">
                  <div class="name-arrow-container">
                    <strong>{{ product.productName }}</strong>
                    <span
                      *ngIf="product.step != product.previousStep && product.previousStep"
                      class="material-icons-outlined step-arrow"
                      [style.background-color]="getDomainArrowColor(product.domain)"
                      matTooltip="Previous Step: {{ product.previousStep }}"
                      >{{
                        productStepIsBeforePreviousStep(product.step, product.previousStep)
                          ? "keyboard_double_arrow_left"
                          : "keyboard_double_arrow_right"
                      }}</span
                    >
                  </div>

                  <div class="flags-actions-container">
                    <span class="objective" *ngIf="product.step == 'OPERATIONAL PRODUCT (Transferred / Run)'"
                      >{{ product.onTimeCount }}/{{ product.totalObjective }}</span
                    >
                    <span class="objective" *ngIf="product.step != 'OPERATIONAL PRODUCT (Transferred / Run)'">{{ product.totalObjective }}</span>
                    <!-- Flags -->
                    <span class="flag" *ngIf="product.hold" [style.background-color]="getDomainFlagColor(product.domain)">Hold</span>
                    <span class="flag" *ngIf="product.idea" [style.background-color]="getDomainFlagColor(product.domain)">Idea</span>
                    <span class="flag" *ngIf="product.poc" [style.background-color]="getDomainFlagColor(product.domain)">Poc</span>
                    <span
                      class="flag"
                      *ngIf="product.addedDate && isRecent(product.addedDate)"
                      [style.background-color]="getDomainFlagColor(product.domain)"
                      matTooltip="Added date: {{ product.addedDate | date : 'dd/MM/yyyy' }}"
                      >New</span
                    >
                    <!-- View Button -->
                    <button
                      *ngIf="product.step != 'PRE-FRAMING' && product.step != 'FRAMING'"
                      (click)="viewProduct(product.productId, product.productName)"
                      class="btn btn-sm view-button no-export"
                      [ngClass]="{'always-visible': !isEditMode}">
                      <span class="material-icons-outlined">visibility</span>
                    </button>

                    <div class="actions" [ngClass]="{'is-edit-mode': isEditMode}">
                      <!-- View Button -->
                      <button
                        *ngIf="product.step != 'PRE-FRAMING' && product.step != 'FRAMING'"
                        (click)="viewProduct(product.productId, product.productName)"
                        class="btn btn-sm view-button">
                        <span class="material-icons-outlined">visibility</span>
                      </button>

                      <!-- Edit & Delete Buttons -->
                      <button
                        *ngIf="isEditMode"
                        (click)="openEditDialog(product); $event.stopPropagation()"
                        class="btn btn-sm btn-danger edit-delete-buttons">
                        <span class="material-icons-outlined">edit</span>
                      </button>

                      <button
                        *ngIf="isEditMode"
                        (click)="deleteProduct(product.productId); $event.stopPropagation()"
                        class="btn btn-sm btn-danger edit-delete-buttons">
                        <span class="material-icons-outlined">delete</span>
                      </button>
                    </div>
                  </div>
                </div>

                <!-- Product Details -->
                <div *ngIf="isProductOpened(product.productId)" class="product-details">
                  <p>
                    <strong>{{ product.domain }}</strong>
                  </p>
                  <p *ngIf="product.pilot?.name">
                    <strong>{{ product.pilot.name }}</strong>
                  </p>
                  <p *ngIf="product.productOwners?.length > 0">
                    <strong>{{ arrayService.formatArrayForDisplay(product.productOwners, null) }}</strong>
                  </p>
                  <p class="grey-text" *ngIf="product.creationDate">Creation Date: {{ product.creationDate | date : "dd/MM/yyyy" }}</p>
                  <p class="grey-text" *ngIf="product.transferDate">Transfer Date: {{ product.transferDate | date : "dd/MM/yyyy" }}</p>
                  <p class="grey-text" *ngIf="product.runDate">Run Date: {{ product.runDate | date : "dd/MM/yyyy" }}</p>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <!-- Comment Section -->
        <div class="comment-section">
          <app-comment
            [title]="'Highlights Preframing & Framing'"
            [date]="commentPreFramingFraming?.postedOn"
            [comment]="commentPreFramingFraming?.comment || ''"
            [isEditable]="isWriter || isAdmin"
            [lbus]="lbus"
            (saveComment)="saveComment('preFraming', $event)">
          </app-comment>
          <app-comment
            [title]="'Highlights Build/Buy & Operational'"
            [date]="commentBuildBuyOperational?.postedOn"
            [comment]="commentBuildBuyOperational?.comment || ''"
            [isEditable]="isWriter || isAdmin"
            [lbus]="lbus"
            (saveComment)="saveComment('buildOperational', $event)">
          </app-comment>
        </div>
      </div>
    </div>
  </div>
</div>
