import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  private readonly baseUrl = `${environment.webProtocol}://${environment.serverUrl}/api/products`;

  constructor(private readonly http: HttpClient) {}

  saveProduct(product: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/save`, product);
  }

  getAllProducts(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/list`);
  }

  getAllProductsByBranch(branch: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/branch/${branch}`);
  }

  getAllSitesByBranches(branches: Array<string>): Observable<any> {
    let httpParams = new HttpParams();
    branches.forEach((b) => (httpParams = httpParams.append("branches", b)));
    return this.http.get<any[]>(`${this.baseUrl}/sites`, {params: httpParams});
  }

  getAllLbusAvailable(productId: number, branch: string): Observable<any> {
    let httpParams = new HttpParams();
    if (productId != null) httpParams = httpParams.set("productId", productId);
    if (branch != null) httpParams = httpParams.set("branch", branch);
    return this.http.get<any[]>(`${this.baseUrl}/lbus-available`, {params: httpParams});
  }
}
