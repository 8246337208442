import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {MsalGuard} from "@azure/msal-angular";
import {ProductComponent} from "./product/product.component";
import {PortfolioReviewComponent} from "./portfolio-review/portfolio-review.component";
import {SearchProductComponent} from "./search-product/search-product.component";
import {HelpComponent} from "./help/help.component";
const routes: Routes = [
  {
    path: "product/:name/:id",
    component: ProductComponent,
    canActivate: [MsalGuard],
  }, // Route to the products
  {
    path: "portfolio-review/:branch",
    component: PortfolioReviewComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "search",
    component: SearchProductComponent,
    canActivate: [MsalGuard],
  }, // Add Search Component
 {
    path: "help",
    component: HelpComponent,
    canActivate: [MsalGuard],
  }, // Route to the help page
  {
    path: "",
    redirectTo: "",
    pathMatch: "full",
    canActivate: [MsalGuard],
  }, // Redirect to the default product
  {path: "**", redirectTo: "", canActivate: [MsalGuard]}, // Manage route not founds
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
