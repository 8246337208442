import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ToolboxService {
  private readonly apiUrl = `${environment.webProtocol}://${environment.serverUrl}/api/toolbox`;

  constructor(private readonly http: HttpClient) {
  }

  loadToolbox(productId: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/load-toolbox", {
      params: {
        productId: productId,
      },
    });
  }

  loadFocus(productId: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/load-focus", {
      params: {
        productId: productId,
      },
    });
  }

  saveLink(productId: number, title: string, link: string): Observable<any> {
    let httpParams: HttpParams = new HttpParams().set("productId", productId).set("title", title);
    return this.http.post<any>(this.apiUrl + "/save-link", link, {
      params: httpParams
    });
  }

  deleteLink(productId: number, title: string): Observable<any> {
    let httpParams: HttpParams = new HttpParams().set("productId", productId).set("title", title);
    return this.http.post<any>(this.apiUrl + "/delete-link", null, {
      params: httpParams
    });
  }

  getDocument(documentPath: string): Observable<any> {
    let httpParams: HttpParams = new HttpParams().set("documentPath", documentPath);

    return this.http.get<any>(this.apiUrl + "/get-document", {
      params: httpParams,
      responseType: 'blob' as 'json'
    });
  }

  saveDocument(productId: number, title: string, document: any): Observable<any> {
    let formData: FormData = new FormData();
    formData.append("file", document, document['name']);

    let httpParams: HttpParams = new HttpParams().set("productId", productId).set("title", title);
    return this.http.post<any>(this.apiUrl + "/save-document", formData, {
      params: httpParams
    });
  }

  saveFocus(productId: number, document: any): Observable<any> {
    let formData: FormData = new FormData();
    formData.append("file", document, document['name']);

    let httpParams: HttpParams = new HttpParams().set("productId", productId);
    return this.http.post<any>(this.apiUrl + "/save-focus", formData, {
      params: httpParams
    });
  }

  deleteDocument(productId: number, title: string): Observable<any> {
    let httpParams: HttpParams = new HttpParams().set("productId", productId).set("title", title);
    return this.http.post<any>(this.apiUrl + "/delete-document", null, {
      params: httpParams
    });
  }

  deleteFocus(productId: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams().set("productId", productId);
    return this.http.post<any>(this.apiUrl + "/delete-focus", null, {
      params: httpParams
    });
  }
}
