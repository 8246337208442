import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {Chart} from "chart.js";
import {ToastrService} from "ngx-toastr";
import {SharedStateService} from "src/app/common/shared-state.service";
import {SharedEventsService} from "../../common/shared-events-service";
import {quarters} from "../../common/util/const";
import {PortfolioReviewService} from "../portfolio-review.service";
import {ConfirmPopupComponent} from "../../common/confirm-popup/confirm-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {DeploymentProgressService} from "./deployment-progress.service";
import {ProductService} from "../../product/product.service";
import {Editor, Toolbar} from "ngx-editor";
import {DCMentionsPlugin} from "../../common/util/plugins";
import schema from "../../common/util/schema";
import {TableService} from "../../common/table.service";


@Component({
  selector: "deployment-progress",
  templateUrl: "./deployment-progress.component.html",
  styleUrls: ["./deployment-progress.component.scss"],
})
export class DeploymentProgressComponent implements OnInit, OnChanges {
  @Input() branch: string;
  @Input() isWriter: boolean;
  @Input() isWriterPotentials: boolean;
  @Input() products: Array<any>;

  deploymentProgress: any = {};
  deploymentObjectives: any = {};

  dataLoading: boolean = false;

  quarters: Array<string> = quarters;
  selectedQuarter: string = quarters[0];

  currentYear: string;

  chartNonCumulative: any;
  chartCumulative: any;


  sortedColumn: string | null = null;
  sortDirection: "asc" | "desc" = "asc";

  saveInProgress: boolean;

  isAdding: boolean;
  isEditing: boolean;

  sitesForBranch: any = [];
  saveCommentTimeout: any;

  legendCategories = [{name: "Plan for Deploy", color: '#B39DDB', editable: true}, {
    name: "Potential",
    color: '#CE93D8',
    editable: true
  },
    {name: "Ready for site", color: '#90CAF9'},
    {name: "1st Usage", color: '#80CBC4'},
    {name: "Target", color: '#C5E1A5', type: "line", editable: true}];

  public toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["ordered_list", "bullet_list"],
    [{heading: ["h1", "h2", "h3", "h4", "h5", "h6"]}],
    ["text_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
  ];

  constructor(private readonly portfolioReviewService: PortfolioReviewService,
              private readonly deploymentProgressService: DeploymentProgressService,
              private readonly toastrService: ToastrService,
              private readonly sharedEventsService: SharedEventsService,
              private readonly sharedStateService: SharedStateService,
              private readonly tableService: TableService,
              private readonly dialog: MatDialog,
              private readonly productService: ProductService) {
    this.currentYear = new Date().getFullYear().toString().substring(2, 4);
  }

  ngOnInit() {
    this.sharedEventsService.productUpdated$.subscribe(() => {
      this.loadDeploymentProgress();
    });
    this.sharedEventsService.deploymentPlanUpdated$.subscribe(() => {
      this.loadDeploymentProgress();
    });

    // Subscribe to changes in the selected quarter
    this.sharedStateService.getSelectedQuarterObservable("DeploymentProgress").subscribe((quarter) => {
      this.selectedQuarter = quarter;
    });
  }

  selectQuarter(quarter: string): void {
    this.selectedQuarter = quarter;
    this.sharedStateService.setSelectedQuarter("DeploymentProgress", quarter);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.branch?.currentValue) {
      this.loadDeploymentProgress();
    }
  }

  loadDeploymentProgress() {
    this.dataLoading = true;

    this.deploymentProgressService.getDeploymentProgress(this.branch).subscribe((infoRes) => {
        this.deploymentProgress = infoRes.progress;
        this.deploymentObjectives = infoRes.objectives;

        for (let quarter of this.quarters) {
          if (!this.deploymentObjectives[quarter]) {
            this.deploymentObjectives[quarter] = {};
          }
          if (!this.deploymentProgress[quarter]) {
            this.deploymentProgress[quarter] = [];
          }

          for (let row of this.deploymentProgress[quarter]) {
            let plugins = DCMentionsPlugin.getPlugins(null);
            row.editor = new Editor({
              schema,
              plugins,
            });
            row.comment = row.comment || "";
          }
        }

        this.createNonCumulativeChart();
        this.createCumulativeChart();

        this.dataLoading = false;
      },
      () => {
        this.toastrService.error("An error occurred while retrieving the deployment progress");

        this.dataLoading = false;
      }
    );
  }

  createNonCumulativeChart(): void {
    if (this.chartNonCumulative) this.chartNonCumulative.destroy();

    let datasets = [];

    // Static categories
    let categories = this.legendCategories.filter((category) => ["Plan for Deploy", "Potential"].includes(category.name));

    for (let category of categories) {
      let datasetData = Object.keys(this.deploymentObjectives).map((key) => {
        return this.deploymentObjectives[key][category.name];
      });

      datasets.push(this.createBar(category.name, datasetData, category.color, 0));
    }

    // Calculated categories
    categories = this.legendCategories.filter((category) => ["Ready for site", "1st Usage"].includes(category.name));

    for (let category of categories) {
      let datasetData = Object.keys(this.deploymentProgress).map((key) => {
        return this.deploymentProgress[key].map((row) => row.sites[category.name]?.length || 0).reduce((a, b) => a + b, 0);
      });

      datasets.push(this.createBar(category.name, datasetData, category.color, 0));
    }

    this.chartNonCumulative = new Chart(`deploymentProgressNonCumulative`, this.generateChartOptions(this.quarters, datasets, null, null));
  }

  createCumulativeChart(): void {
    if (this.chartCumulative) this.chartCumulative.destroy();

    let datasets = [];

    // Static categories
    let categories = this.legendCategories.filter((category) => ["Plan for Deploy", "Potential"].includes(category.name));

    for (let category of categories) {
      let datasetData = Object.keys(this.deploymentObjectives).map((key, index) => {
        return Object.keys(this.deploymentObjectives)
          .slice(0, index + 1)
          .map((key) => {
            return this.deploymentObjectives[key][category.name];
          })
          .reduce((a, b) => a + b, 0);
      });

      datasets.push(this.createBar(category.name, [0].concat(datasetData).concat([0]), category.color, 1));
    }

    // Calculated categories
    categories = this.legendCategories.filter((category) => ["1st Usage"].includes(category.name));

    for (let category of categories) {
      let datasetData = Object.keys(this.deploymentObjectives).map((key, index) => {
        return Object.keys(this.deploymentObjectives)
          .slice(0, index + 1)
          .map((key) => {
            return this.deploymentProgress[key].map((row) => row.sites[category.name]?.length || 0).reduce((a, b) => a + b, 0);
          })
          .reduce((a, b) => a + b, 0);
      });

      datasets.push(this.createBar(category.name, [0].concat(datasetData).concat([0]), category.color, 1));
    }

    let lineData = Object.keys(this.deploymentObjectives).map((key, index) => {
      return Object.keys(this.deploymentObjectives)
        .slice(0, index + 1)
        .map((key) => {
          return this.deploymentObjectives[key]["Target"];
        })
        .reduce((a, b) => a + b, 0);
    });

    // Target line
    datasets.push({
      type: "line",
      label: "Target",
      data: [Math.max(-1 * lineData[0], lineData[0] - (lineData[1] - lineData[0]))]
        .concat(lineData)
        .concat([lineData[lineData.length - 1] + (lineData[lineData.length - 1] - lineData[lineData.length - 2])]),
      borderColor: this.legendCategories.find((category) => category.name === "Target").color,
      backgroundColor: "rgba(91, 26, 137, 0.2)",
      fill: false,
      pointHitRadius: 4,
      pointRadius: 0,
      order: 0,
      datalabels: {
        display: false,
      },
    });

    // Some dummy data is added before and at the end of the data to make the line fill all the graph visually

    this.chartCumulative = new Chart(
      `deploymentProgressCumulative`,
      this.generateChartOptions(["dummy1"].concat(this.quarters).concat(["dummy2"]), datasets, 1, this.quarters.length)
    );
  }

  createBar(label: string, data: any, color: string, order: number): any {
    return {
      label: label,
      data: data,
      backgroundColor: color,
      type: "bar",
      borderWidth: {
        top: 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      order: order,
      borderColor: "#FFF",
      borderRadius: 4,
    };
  }

  generateChartOptions(labels: Array<any>, datasets: Array<any>, min: number, max: number): any {
    return {
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              font: {
                weight: "bold",
              },
            },
            grid: {
              display: false,
            },
            min: min,
            max: max,
          },
          y: {
            beginAtZero: true,
            ticks: {
              callback: (value) => {
                return value;
              },
              font: {
                weight: "bold",
              },
            },
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },

          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const datasetLabel = tooltipItem.dataset.label;
                const value = parseFloat(tooltipItem.raw.toString());

                return `${datasetLabel}: ${value}`;
              },
            },
          },
          datalabels: {
            color: "white",
            display: (context) => {
              return context.dataset.data[context.dataIndex] !== 0;
            },
            font: {
              weight: "bold",
            },
            formatter: (value) => {
              return value;
            },
          },
        },
      },
    };
  }

  editableCategoryChanged(quarter: string, categoryName: string): void {
    this.deploymentProgressService.updateDeploymentProgressObjective(this.branch, quarter.split("Q")[1], categoryName, this.deploymentObjectives[quarter][categoryName])
      .subscribe(() => {
        this.createNonCumulativeChart();
        this.createCumulativeChart();
      }, () => {
        this.toastrService.error("An error has occurred while updating the deployment progress objective.");
      });
  }

  onSort(column: string): void {
    if (this.sortedColumn === column) {
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    } else {
      this.sortedColumn = column;
      this.sortDirection = "asc";
    }

    this.deploymentProgress[this.selectedQuarter] = this.tableService.sortData(
      this.deploymentProgress[this.selectedQuarter],
      column,
      this.sortDirection
    );
  }

  startEditing(): void {
    if (this.sitesForBranch.length === 0) {
      this.productService.getAllSitesByBranches([this.branch]).subscribe((sites) => {
        this.sitesForBranch = sites;
      });
    }
  }

  stopEditing(): void {
    this.isAdding = false;
    for (let quarter in this.deploymentProgress) {
      for (let row of this.deploymentProgress[quarter]) {
        row.manuallyAdded = false;
      }
    }
  }

  siteAdded(rowId: number, status: string, event: any): void {
    this.deploymentProgressService.siteAdded(rowId, status, event.id, this.branch).subscribe(
      () => {
        this.createNonCumulativeChart();
        this.createCumulativeChart();
      },
      () => {
        this.toastrService.error("An error occurred while updating the deployment progress row.");
      }
    );
  }

  siteRemoved(rowId: number, status: string, event: any): void {
    this.deploymentProgressService.siteRemoved(rowId, status, event.id, this.branch).subscribe(
      () => {
        this.createNonCumulativeChart();
        this.createCumulativeChart();
      },
      () => {
        this.toastrService.error("An error occurred while updating the deployment progress row.");
      }
    );
  }

  sitesCleared(rowId: number, status: string): void {
    this.deploymentProgressService.sitesCleared(rowId, status, this.branch).subscribe(
      () => {
        this.createNonCumulativeChart();
        this.createCumulativeChart();
      },
      () => {
        this.toastrService.error("An error occurred while updating the deployment progress row.");
      }
    );
  }

  deleteRow(deploymentProgressRow: any): void {
    if (deploymentProgressRow.id) {
      this.dialog.open(ConfirmPopupComponent, {
        width: "30vw",
        data: {
          icon: "delete",
          title: "Delete deployment progress",
          text: "Are you sure you want to delete this row ?",
        },
        position: {
          top: "20px",
        },
      })
        .afterClosed()
        .subscribe((res) => {
          if (res === "confirm") {
            this.deploymentProgressService.deleteRow(deploymentProgressRow.id, this.branch).subscribe(
              () => {
                this.toastrService.success("Deployment progress row deleted successfully!");
                this.deploymentProgress[this.selectedQuarter] = this.deploymentProgress[this.selectedQuarter].filter((dp) => dp.id != deploymentProgressRow.id);
                this.createNonCumulativeChart();
                this.createCumulativeChart();
              },
              () => {
                this.toastrService.error("Error deleting the deployment progress row.");
              }
            );
          }
        });
    } else this.deploymentProgress[this.selectedQuarter] = this.deploymentProgress[this.selectedQuarter].filter((dp) => dp.id != deploymentProgressRow.id);
  }

  saveComment(row: any, $event: any) {
    if (row.comment != $event) { // Prevents save launch at start
      if (this.saveCommentTimeout) clearTimeout(this.saveCommentTimeout);
      this.saveCommentTimeout = setTimeout(() => {
        this.deploymentProgressService.saveComment(row.id, row.comment, this.branch).subscribe(
          () => {
            this.saveCommentTimeout = null;
          },
          () => {
            this.toastrService.error("An error occurred while updating the deployment progress row comment.");
          }
        );
      }, 500);
    }
  }

  addOneRow() {
    if (this.sitesForBranch.length === 0) {
      this.productService.getAllSitesByBranches([this.branch]).subscribe((sites) => {
        this.sitesForBranch = sites;
      });
    }

    let plugins = DCMentionsPlugin.getPlugins(null);

    this.deploymentProgress[this.selectedQuarter].push({
      manuallyAdded: true, sites: {}, editor: new Editor({
        schema,
        plugins,
      })
    });
    this.isAdding = true;
  }

  productSelected(row: any, $event: any): void {
    row.type = this.products.find((p) => p.name == $event)?.step;

    let productId = this.products.find((p) => p.name == $event)?.id;

    if (productId != null) {
      this.deploymentProgressService.createRow(productId, this.selectedQuarter.split("Q")[1], this.branch).subscribe(
        (rowId) => {
          row.id = rowId;
        },
        () => {
          this.toastrService.error("An error occurred while updating the deployment progress row comment.");
        }
      );
    }
  }
}