import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {ActionPlanComponent} from "../action-plan/action-plan.component";
import {ManageActionComponent} from "../action-plan/manage-action/manage-action.component";
import {AuthenticationService} from "../common/authentication.service";
import {DpcComment, DpcCommentService} from "../common/dpc-comment.service";
import {UserService} from "../common/user.service";
import {ProductService} from "../product/product.service";

@Component({
  selector: "portfolio-review",
  templateUrl: "./portfolio-review.component.html",
})
export class PortfolioReviewComponent implements OnInit {
  @Input() selectedProductId!: number;
  @Input() selectedProductName: string;
  @Input() selectedProductOwners: Array<string>;
  lastUpdate: Date;
  branch: string;
  lbus: any;
  products: any;

  isWriter: string[] = [];
  isAdmin: boolean = false;

  comments: DpcComment[];
  commentPreFramingFraming: DpcComment;
  commentBuildBuyOperational: DpcComment;

  // Comments for Deploy IT - Activity Distribution
  activityPerQuarterComment: {[branch: string]: {[domain: string]: DpcComment}} = {};
  activityPerProductComment: {[branch: string]: {[quarter: string]: DpcComment}} = {};

  @ViewChild(ActionPlanComponent) actionPlanComponent: ActionPlanComponent;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly authService: AuthenticationService,
    private readonly dpcCommentService: DpcCommentService,
    private readonly toastrService: ToastrService,
    private readonly dialog: MatDialog,
    private readonly productService: ProductService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.branch = params["branch"].toUpperCase();
      this.checkRights();
      this.loadComments();

      this.productService.getAllLbusAvailable(null, this.branch).subscribe((allLBUs) => {
        this.lbus = allLBUs.map((lbu) => ({
          code: lbu.isoCode,
          name: lbu.name,
          flag: `assets/flags/${lbu.isoCode.toLowerCase()}.svg`,
        }));
      });

      this.productService.getAllProductsByBranch(this.branch).subscribe((allProducts) => {
        this.products = allProducts.map((product) => (
          {
            id: product.id,
            name: product.name,
            step: product.step
          }
        ));
        this.products.sort((a, b) => a.name.localeCompare(b.name));
      });
    })

    this.isAdmin = this.userService.getUserIsAdmin();
  }

  checkRights() {
    this.userService.dpcEditRightsBySectionAndBranch(this.authService.email, this.branch).subscribe((result: string[]) => {
      this.isWriter = result;
    });
  }

  createDefaultComment(title: string, branch: string, quarter: string = ""): DpcComment {
    return {
      id: undefined,
      comment: "",
      title: title,
      quarter: quarter,
      postedOn: new Date(),
      branch: branch,
    };
  }

  loadComments(): void {
    this.dpcCommentService.getComments(this.branch).subscribe(
      (comments: DpcComment[]) => {
        this.comments = comments;
        this.commentPreFramingFraming = this.comments.find((comment) => comment.title === "Highlights Preframing & Framing");
        this.commentBuildBuyOperational = this.comments.find((comment) => comment.title === "Highlights Build/Buy & Operational");

        // Define quarters and domains
        const quarters = ["Q1", "Q2", "Q3", "Q4"];
        const domains = this.branch === "RC" ? [""] : ["DrillX", "AiO"];

        // Initialize activityPerQuarterComment for the current branch
        this.activityPerQuarterComment[this.branch] = {};
        domains.forEach((domain) => {
          const title = this.branch === "RC" ? "Activity per Quarter Comment" : `Activity per Quarter ${domain} Comment`;
          this.activityPerQuarterComment[this.branch][domain] =
            this.comments.find((comment) => comment.branch === this.branch && comment.title === title) ||
            this.createDefaultComment(title, this.branch);
        });

        // Initialize activityPerProductComment for the current branch
        this.activityPerProductComment[this.branch] = {};
        quarters.forEach((quarter) => {
          this.activityPerProductComment[this.branch][quarter] =
            this.comments.find(
              (comment) => comment.branch === this.branch && comment.quarter === quarter && comment.title === "Activity per Product Comment"
            ) || this.createDefaultComment("Activity per Product Comment", this.branch, quarter);
        });
      },
      (error) => {
        this.toastrService.error(`Error while loading the comments.`);
      }
    );
  }

  // Replace spaces by "-"
  toIdFormat(section: string): string {
    return section.replace(/\s+/g, "-");
  }

  /**ACTION PLAN **/
  openAddActionDialog(): void {
    const dialogRef = this.dialog.open(ManageActionComponent, {
      data: {
        motherPage: "dpc",
        branch: this.branch,
        lbus: this.lbus,
        products: this.products
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshActionPlan();
      }
    });
  }

  refreshActionPlan() {
    if (this.actionPlanComponent) {
      this.actionPlanComponent.loadActionPlansByBranch(this.branch);
    }
  }

  exportInXlsx() {
    this.actionPlanComponent.exportInXlsx();
  }

  exportInJpeg() {
    this.actionPlanComponent.exportInJpeg();
  }

  exportInPdf() {
    this.actionPlanComponent.exportInPdf();
  }
}
