<div class="accordion">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" id="accordion-btn-header-usage">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#accordion-btn-collapse-' + section"
        aria-expanded="true"
        [attr.aria-controls]="'accordion-btn-collapse-' + section">
        <div class="container-left-accordion-title">
          <span class="material-icons-outlined color_red" aria-hidden="true">feedback</span>
          Product Usage & Feedback from End-Users
        </div>
      </button>
      <div class="container-right">
        <a
          *ngIf="productNameInUsageMonitoring"
          href="https://aiousagemonitoringfront.z6.web.core.windows.net/users-count/{{ productNameInUsageMonitoring }}"
          target="_blank"
          class="usage-link no-export">
          <span class="material-icons-outlined" matTooltip="Go to Deploy Usage Monitoring">launch</span>
        </a>
      </div>
    </h4>

    <div [id]="'accordion-btn-collapse-' + section" class="accordion-collapse collapse show" aria-labelledby="accordion-btn-header-usage">
      <div class="accordion-body d-flex usage-container">
        <!-- Graph Section -->
        <div class="graph-container">
          <ng-container>
            <div *ngIf="hasData && !loading" class="noMarginRightLeft users-count">
              <div>
                <div class="action-menu-container">
                  <div>
                    <div style="display: inline-block; margin-right: 15px">Show count :</div>
                    <div class="segmented-control-container">
                      <button
                        class="segmented-control-item"
                        [ngClass]="{selected: durationSelected === '7d'}"
                        (click)="setDuration('7d')"
                        (keydown)="$event.key === 'Enter' ? setDuration('7d') : null">
                        Weekly
                      </button>
                      <button
                        class="segmented-control-item"
                        [ngClass]="{selected: durationSelected === '1month'}"
                        (click)="setDuration('1month')"
                        (keydown)="$event.key === 'Enter' ? setDuration('1month') : null">
                        Monthly
                      </button>
                    </div>
                  </div>
                  <div>
                    <button class="btn btn-outline-primary btn-circle reset-btn no-export" (click)="reset()">
                      <span class="material-icons-outlined">replay</span>
                    </button>
                    <button class="btn btn-outline-primary btn-circle zoom-btn no-export" (click)="zoomIn()">
                      <span class="material-icons-outlined">add_circle_outline</span>
                    </button>
                    <button class="btn btn-outline-primary btn-circle zoom-btn no-export" (click)="zoomOut()">
                      <span class="material-icons-outlined">remove_circle_outline</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="loading" class="spinner-border text-primary" output="status">
              <span class="visually-hidden">Loading...</span>
            </div>

            <div [style]="hasData && !loading ? 'height: 50vh; width: 100%' : 'display:none'">
              <canvas [style]="hasData && !loading ? '' : 'height:0'" id="usersCount-{{ canvasId }}"></canvas>
            </div>
          </ng-container>
          <span *ngIf="!hasData && !loading" style="font-style: italic"> No Data (this product may not exist on Deploy Usage Monitoring). </span>
        </div>
        <!-- Comment Section -->
        <div class="comment-section" [style]="hasData && !loading ? 'margin-top: 32px;' : ''">
          <app-comment
            [title]="'Adoption/Change Comment'"
            [date]="lastUpdate"
            [comment]="comment"
            [isEditable]="isWriter || isAdmin"
            [lbus]="lbus"
            (saveComment)="saveComment($event)"></app-comment>
        </div>
      </div>
    </div>
  </div>
</div>
