export const branches: string[] = ["EP", "GRP", "HD", "HUT", "MS", "OT", "RC", "TGS", "TS"];

export const domainsEP: any[] = [
  {name: "Enhanced Operations", color: "#E2F3CF", flag_color: "#B2DE7E", arrow_color: "#447C00"},
  {name: "Supply Chain", color: "#FEEBD7", flag_color: "#FBC68F", arrow_color: "#A85B0C"},
  {name: "Production & Emissions", color: "#F2EAFD", flag_color: "#DCC8F8", arrow_color: "#8B47E9"},
  {name: "Drilling & Wells", color: "#ECEDEF", flag_color: "#CED0D5", arrow_color: "#686F7C"},
  {name: "Subsurface", color: "#D5F3EE", flag_color: "#8EDED0", arrow_color: "#0F6455"},
  {name: "Project & Construction", color: "#FBE9EF", flag_color: "#F3C3D3", arrow_color: "#D3235F"},
];

export const domainsRC: any[] = [
  {name: "Planning & Scheduling", color: "#E2F3CF", flag_color: "#B2DE7E", arrow_color: "#447C00"},
  {name: "Supply Chain & Recycling", color: "#ECEDEF", flag_color: "#CED0D5", arrow_color: "#686F7C"},
  {name: "Process Optimization & Availability", color: "#F2EAFD", flag_color: "#DCC8F8", arrow_color: "#8B47E9"},
  {name: "HSE & Operations", color: "#D5F3EE", flag_color: "#8EDED0", arrow_color: "#0F6455"},
  {name: "Enablers", color: "#E8EEFE", flag_color: "#C2CFFC", arrow_color: "#1F49D2"},
  {name: "Transverse", color: "#FBE9EF", flag_color: "#F3C3D3", arrow_color: "#D3235F"},
  {name: "Support Functions", color: "#C2CFFC", flag_color: "#7997F9", arrow_color: "#5B79DA"},
];

export const squadProfiles: string[] = ["Mono Product", "Multi Product", "Hybrid"];

export const categories: any[] = [
  {key: "func_evo", name: "Func. Evo"},
  {key: "func_adapt", name: "Func. Adapt"},
  {key: "tech", name: "Tech."},
  {key: "cyber", name: "Cyber"},
  {key: "deploy", name: "Deploy"},
  {key: "run", name: "Run"},
];

export const quarters: string[] = ["Q1", "Q2", "Q3", "Q4"];

export const steps: any[] = [
  {name: "PRE-FRAMING", color: "#203864"},
  {name: "FRAMING", color: "#4472C4"},
  {name: "BUILD/BUY", color: "#C5D2EA"},
  {name: "OPERATIONAL PRODUCT (Transferred / Run)", color: "#FFC000"},
];
