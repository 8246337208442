import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PortfolioReviewService {
  private readonly apiUrl = `${environment.webProtocol}://${environment.serverUrl}/api/portfolio-review`;

  constructor(private readonly http: HttpClient) {
  }

  getQuartersToSave(branch: string): Observable<any> {
    let httpParams: HttpParams = new HttpParams().set("branch", branch);

    return this.http.get<any>(`${this.apiUrl}/quarters-to-save`, {params: httpParams});
  }

  saveDPCData(branch: string, quarter: number, year: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams().set("branch", branch).set("quarter", quarter).set("year", year);

    return this.http.get<any>(`${this.apiUrl}/save-dpc-data`, {params: httpParams});
  }

  getPipelineEvolution(branch: string): Observable<any> {
    let httpParams = new HttpParams().set("branch", branch);
    return this.http.get<any[]>(`${this.apiUrl}/pipeline-evolution`, {params: httpParams});
  }

  getTeamConfidence(branch: string): Observable<any> {
    let httpParams = new HttpParams().set("branch", branch);
    return this.http.get<any[]>(`${this.apiUrl}/team-confidence`, {params: httpParams});
  }

  changeTeamConfidence(branch: string, productName: string, siteName: string, quarter: string, confidence: string): Observable<any> {
    let httpParams = new HttpParams().set("branch", branch).set("productName", productName).set("siteName", siteName).set("quarter", quarter).set("confidence", confidence);
    return this.http.get<any[]>(`${this.apiUrl}/change-team-confidence`, {params: httpParams});
  }

  saveTeamConfidenceComment(branch: string, productName: string, siteName: string, quarter: string, comment: string): Observable<any> {
    let httpParams = new HttpParams().set("branch", branch).set("productName", productName).set("siteName", siteName).set("quarter", quarter);
    return this.http.post<any[]>(`${this.apiUrl}/save-team-confidence-comment`, comment, {params: httpParams});
  }
}
