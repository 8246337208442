<div *ngIf="!isEditingComment; else editCommentTemplate">
  <h6 class="comment-title">
    {{ title }} <span *ngIf="date" class="last-update-text">Posted on : {{ date | date : "dd/MM/yyyy" : "UTC" }}</span>
  </h6>
  <p class="comment" *ngIf="comment" [innerHTML]="comment | safeHtml"></p>
  <p class="comment" *ngIf="!comment">No comment provided.</p>
  <button *ngIf="isEditable" class="btn btn-primary no-export" (click)="startEdit()">
    <span class="material-icons-outlined">comment</span> Comment
  </button>
</div>

<ng-template #editCommentTemplate>
  <label for="commentInput" class="form-label">{{ title }}</label>
  <div *ngIf="editor">
    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
    <ngx-editor
      class="ngx-editor"
      [editor]="editor"
      [(ngModel)]="comment"
      [disabled]="false"
      [placeholder]="'Type your comment here...'"></ngx-editor>
  </div>
  <div class="d-flex justify-content-end mt-2">
    <button class="btn btn-primary me-2" (click)="saveCommentAction()">Save</button>
    <button class="btn btn-secondary" (click)="cancelEditAction()">Cancel</button>
  </div>
</ng-template>
