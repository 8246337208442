<div class="accordion">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" [id]="'accordion-btn-header-deployment-progress'">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#accordion-btn-collapse-deployment-progress'"
        aria-expanded="true"
        [attr.aria-controls]="'accordion-btn-collapse-deployment-progress'">
        <div class="container-left-accordion-title">
          <span class="material-icons-outlined color_red" aria-hidden="true" color="accent">
            {{ "moving" }}
          </span>
          {{ "Deployment Progress" }}
        </div>
      </button>
      <div class="container-right">
        <div class="form-check form-switch" *ngIf="isWriter">
          <input class="form-check-input edit-switch-dp" type="checkbox" id="defaultSwitch1" checked="" [(ngModel)]="isEditing" (ngModelChange)="isEditing ? startEditing() : stopEditing()" />
          <label class="form-check-label" for="defaultSwitch1">Edit</label>
        </div>
      </div>
    </h4>
    <div
      [id]="'accordion-btn-collapse-deployment-progress'"
      class="accordion-collapse show"
      [attr.aria-labelledby]="'accordion-btn-header-deployment-progress'">
      <div class="accordion-body">
        <div class="spinner-container" *ngIf="dataLoading">
          <div class="spinner-border text-primary" output="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <div class="graphs-container">
          <div class="graph-container">
            <h4 class="graph-title">Qty of Deployment (Non cumulative)</h4>
            <div class="chart-container" [style]="!dataLoading ? 'height: 35vh' : 'display:none'">
              <canvas id="deploymentProgressNonCumulative"></canvas>
            </div>
          </div>
          <div class="graph-container">
            <h4 class="graph-title">Qty of Deployment (Cumulative)</h4>
            <div class="chart-container" [style]="!dataLoading ? 'height: 35vh' : 'display:none'">
              <canvas id="deploymentProgressCumulative"></canvas>
            </div>
          </div>
        </div>

        <div class="legend-container">
          <div class="legend-item-container" *ngFor="let category of legendCategories">
            <div *ngIf="isWriterPotentials && category.editable" class="potential-edit-input-container no-export">
              <ng-container *ngFor="let quarter of this.quarters">
                <ng-container *ngIf="deploymentObjectives[quarter] != null">
                  <input
                    type="number"
                    [(ngModel)]="deploymentObjectives[quarter][category.name]"
                    class="potential-edit-input"
                    [placeholder]="quarter"
                    (ngModelChange)="editableCategoryChanged(quarter, category.name)" />
                </ng-container>
              </ng-container>
            </div>
            <span class="legend-color" [class]="{'legend-color-line': category.type == 'line'}" [style.background-color]="category.color"></span>
            <span>{{ category.name }}</span>
          </div>
        </div>

        <div class="toggle-buttons no-export">
          <div class="segmented-control-container">
            <button
              *ngFor="let quarter of quarters"
              class="segmented-control-item"
              [ngClass]="{selected: selectedQuarter === quarter}"
              (click)="selectQuarter(quarter)"
              [attr.data-cypress]="'quarter-option-' + quarter">
              {{ quarter }}
            </button>
          </div>
        </div>

        <div class="table-container">
          <table class="table deployment-progress-table">
            <caption>
              Deployment Progress
            </caption>
            <thead>
            <tr>
            <th scope="col" style="width: 10%" class="sortable-column" (click)="onSort('productName')" (keydown)="onSort('productName')">
                <div class="column-header">
              Product
                  <div class="sort-icons">
                    <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'productName' && sortDirection === 'asc'}">
                  expand_less
                </span>
                    <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'productName' && sortDirection === 'desc'}">
                  expand_more
                </span>
              </div>
                </div>
              </th>
              <th scope="col" style="width: 10%" class="sortable-column" (click)="onSort('type')" (keydown)="onSort('type')">
                <div class="column-header">
                Type
                  <div class="sort-icons">
                    <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'type' && sortDirection === 'asc'}">
                      expand_less
                    </span>
                    <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'type' && sortDirection === 'desc'}">
                  expand_more
                    </span>
                  </div>
                </div>
              </th>
              <th scope="col" style="width: 10%;">Target {{selectedQuarter}}</th>
              <th scope="col" style="width: 10%;">Ready for site</th>
              <th scope="col" style="width: 10%;">1st Usage</th>
              <th scope="col" style="width: 10%;">Postponed</th>
              <th scope="col" [ngStyle]="{'width': isWriter ? 'calc(35% - 110px);' : '35%;'}">Comment</th>
              <th scope="col" *ngIf="isWriter" style="width: 110px;">
                <button type="button" class="btn btn-outline btn-circle" (click)="addOneRow()" title="Add row" data-cypress="add-row">
                  <span class="material-icons-outlined">add</span>
                </button>
              </th>
            </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let deploymentProgressRow of deploymentProgress[selectedQuarter]"
                [attr.data-cypress-product]="deploymentProgressRow.productName">
              <td>
                <ng-container *ngIf="deploymentProgressRow.manuallyAdded">
                  <div class="form-group">
                    <ng-select
                    [items]="products"
                    placeholder="Select product"
                    bindLabel="name"
                    bindValue="name"
                    [(ngModel)]="deploymentProgressRow.productName"
                    (ngModelChange)="productSelected(deploymentProgressRow, $event)"
                    data-cypress="select-products"></ng-select>
                  </div>
                </ng-container>
                <ng-container *ngIf="!deploymentProgressRow.manuallyAdded">
                  {{deploymentProgressRow.productName}}
                </ng-container>
              </td>
              <td>{{deploymentProgressRow.type}}</td>
              <td>{{deploymentProgressRow.sites["planned"]?.join('; ')}}</td>
              <td *ngFor="let status of ['Ready for site', '1st Usage', 'Postponed']">
                <ng-container *ngIf="!isEditing && (!isAdding || !deploymentProgressRow.manuallyAdded || !deploymentProgressRow.productName)">
                  {{deploymentProgressRow.sites[status]?.join('; ')}}
                </ng-container>
                <ng-container *ngIf="(isEditing || (isAdding && deploymentProgressRow.manuallyAdded && deploymentProgressRow.productName)) && sitesForBranch">
                  <div class="form-group">
                    <ng-select
                      class="select-control-multiple"
                      [items]="sitesForBranch"
                      [multiple]="true"
                      placeholder="Select sites"
                      bindLabel="name"
                      bindValue="name"
                      [attr.data-cypress]="'select-sites-' + status"
                      [(ngModel)]="deploymentProgressRow.sites[status]"
                      (add)="siteAdded(deploymentProgressRow.id, status, $event)"
                      (remove)="siteRemoved(deploymentProgressRow.id, status, $event)"
                      (clear)="sitesCleared(deploymentProgressRow.id, status)"></ng-select>
                  </div>
                </ng-container>
              </td>
              <td>
                <div *ngIf="!isEditing && (!isAdding || !deploymentProgressRow.manuallyAdded || !deploymentProgressRow.productName) && deploymentProgressRow.comment" [innerHTML]="deploymentProgressRow.comment | safeHtml"></div>
                <div *ngIf="isEditing || (isAdding && deploymentProgressRow.manuallyAdded && deploymentProgressRow.productName)">
                  <ngx-editor-menu [editor]="deploymentProgressRow.editor" [toolbar]="toolbar"> </ngx-editor-menu>
                  <ngx-editor
                    class="ngx-editor"
                    [editor]="deploymentProgressRow.editor"
                    (ngModelChange)="saveComment(deploymentProgressRow, $event)"
                    [(ngModel)]="deploymentProgressRow.comment"
                    [placeholder]="'Enter comment'"
                    data-cypress="comment-field"></ngx-editor>
                </div>
              </td>
              <td *ngIf="isWriter">
                <button
                  type="button"
                  class="btn btn-sm btn-danger btn-tertiary btn-circle"
                  (click)="deleteRow(deploymentProgressRow)"
                  data-cypress="delete-row"
                  title="Delete row">
                  <span class="material-icons-outlined">delete</span>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
