import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {PortfolioStatusService} from "../../portfolio-status/portfolio-status.service";
import {ToastrService} from "ngx-toastr";
import {steps} from "src/app/common/util/const";
import {SharedEventsService} from "../../common/shared-events-service";
import {PortfolioReviewService} from "../portfolio-review.service";

@Component({
  selector: "pipeline-evolution",
  templateUrl: "./pipeline-evolution.component.html",
  styleUrls: ["./pipeline-evolution.component.scss"],
})
export class PipelineEvolutionComponent implements OnInit, OnChanges {
  @Input() branch: string;

  info: any;
  lastQuarterDisplay: string;

  steps = steps;

  dataLoading: boolean = false;

  constructor(private readonly portfolioReviewService: PortfolioReviewService, private readonly portfolioStatusService: PortfolioStatusService,
              private readonly toastrService: ToastrService, private readonly sharedEventsService: SharedEventsService) {
  }

  ngOnInit() {
    this.sharedEventsService.productUpdated$.subscribe(() => {
      this.loadPipelineEvolution();
    });
    this.sharedEventsService.portfolioStatusUpdated$.subscribe(() => {
      this.loadPipelineEvolution();
    });
    this.sharedEventsService.dpcDataSaved$.subscribe(() => {
      this.loadPipelineEvolution();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.branch?.currentValue) {
      this.loadPipelineEvolution();
    }
  }

  loadPipelineEvolution() {
    this.dataLoading = true;

    this.portfolioReviewService.getPipelineEvolution(this.branch).subscribe((infoRes) => {
      this.lastQuarterDisplay = infoRes.lastQuarter;

      this.info = infoRes.info;

      this.dataLoading = false;
    }, () => {
      this.toastrService.error("An error occurred while retrieving the pipeline evolution");

      this.dataLoading = false;
    });
  }

  getWidthOfStep(step: string, property: string): string {
    let sumOfHistorical = Object.values(this.info).map(i => i["historicalCount"]).reduce((prev, next) => prev + next, 0);
    let sumOfCurrent = Object.values(this.info).map(i => i["currentCount"]).reduce((prev, next) => prev + next, 0);

    return (this.info[step][property] / Math.max(sumOfHistorical, sumOfCurrent)) * 100 + "%";
  }

  stepHasNoChanges(step: string): boolean {
    return this.info[step] == null ||
      (this.info[step].newProducts.length == 0
        && Object.keys(this.info[step].movedProductsFrom).length == 0
        && Object.keys(this.info[step].movedProductsTo).length == 0
        && this.info[step].removedProducts.length == 0);
  }

  filterSteps(property: string): Array<any> {
    return steps.filter(s => this.info[s.name] != null && this.info[s.name][property] > 0);
  }
}
