import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {SharedEventsService} from "src/app/common/shared-events-service";
import {domainsEP, domainsRC} from "../../common/util/const";
import {DateUtil} from "../../common/util/date-util";
import {DeploymentPlanService} from "./deployment-plan.service";
import {EditDeploymentPlanComponent} from "./edit-deployment-plan/edit-deployment-plan.component";

@Component({
  selector: "deployment-plan",
  templateUrl: "./deployment-plan.component.html",
  styleUrls: ["./deployment-plan.component.scss"],
})
export class DeploymentPlanComponent implements OnInit, OnChanges {
  @Input() isWriter: boolean;
  @Input() branch: string;

  lastUpdate: Date;

  quartersToDisplayOptions: Array<string> = ["Display All"];
  quarterToDisplay: string;

  dataLoading: boolean = false;

  products: Array<any> = [];
  sites: Array<any> = [];
  deploymentPlanList: any;

  productsDisplayed: Array<any> = [];
  sitesDisplayed: Array<any> = [];
  deploymentPlanListDisplayed: any;

  optionsSelected: any = {
    Done: true,
    "In progress": true,
    "To do": true,
    "In advance": true,
    "On time": true,
    Late: true,
    Blocked: true,
  };

  constructor(
    private readonly deploymentPlanService: DeploymentPlanService,
    private readonly toastr: ToastrService,
    private readonly dialog: MatDialog,
    private readonly sharedEventsService: SharedEventsService
  ) {}

  ngOnInit() {
    this.quarterToDisplay = this.quartersToDisplayOptions[0];

    this.sharedEventsService.productUpdated$.subscribe(() => {
      this.loadTableData();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.branch?.currentValue) {
      this.loadTableData();
    }
  }

  loadTableData() {
    this.deploymentPlanList = null;
    this.deploymentPlanListDisplayed = null;
    this.dataLoading = true;

    this.deploymentPlanService.getDeploymentPlanTableData(this.branch).subscribe(
      (deploymentPlan) => {
        this.dataLoading = false;
        this.products = deploymentPlan.products;
        this.sites = deploymentPlan.sites;

        this.deploymentPlanList = {};

        for (let oneDeploymentPlan of deploymentPlan.deploymentPlanList) {
          if (this.deploymentPlanList[oneDeploymentPlan.product.id] == null) this.deploymentPlanList[oneDeploymentPlan.product.id] = {};

          this.deploymentPlanList[oneDeploymentPlan.product.id][oneDeploymentPlan.site.id] = {
            deploymentPlan: oneDeploymentPlan.deploymentPlan,
            status: oneDeploymentPlan.status,
          };

          if (!this.quartersToDisplayOptions.includes(oneDeploymentPlan.deploymentPlan))
            this.quartersToDisplayOptions.push(oneDeploymentPlan.deploymentPlan);
        }

        this.quartersToDisplayOptions.sort((a, b) => DateUtil.sortQuartersByYear(a, b));

        this.quartersToDisplayOptions = [...this.quartersToDisplayOptions]; // Persist the value in the options

        this.filterWithOptions();
      },
      () => {
        this.toastr.error("An error occurred while retrieving the deployment plan.");
      }
    );
  }

  getYear(): number {
    return new Date().getFullYear();
  }

  getBackgroundByDomain(product) {
    let domains = this.branch == "EP" ? domainsEP : domainsRC;

    let domainObj = domains.find((d) => d.name == product.domains.find(domain => domain.branch == this.branch)?.name);

    return domainObj?.color || "#F5F5F5";
  }

  getBackgroundByYear(deploymentPlanDate: string): string {
    if (this.deploymentPlanIsInCurrentYear(deploymentPlanDate)) {
      // In progress
      return "rgba(128, 203, 196, 1)";
    } else if (this.deploymentPlanIsInNextYears(deploymentPlanDate)) {
      // To do
      return "rgba(144, 202, 249, 1)";
    } else {
      // Done
      return "rgba(197, 225, 165, 1)";
    }
  }

  getBackgroundByStatus(status: string): string {
    if (status == "In advance") return "#5279F7";
    else if (status == "On time") return "#40A900";
    else if (status == "Late") return "#FB9A28";
    else if (status == "Blocked") return "#ED0000";
  }

  getTargetForProduct(productId: number): number {
    let count = 0;

    for (let siteDeployed in this.deploymentPlanListDisplayed[productId]) {
      let deploymentPlanDate = this.deploymentPlanListDisplayed[productId][siteDeployed].deploymentPlan;

      if (this.anyFilterActive() || this.deploymentPlanIsInCurrentYear(deploymentPlanDate)) count++;
    }

    return count;
  }

  getTargetForSite(siteId: number): number {
    let count = 0;

    for (let productDeployed in this.deploymentPlanListDisplayed) {
      for (let siteDeployed in this.deploymentPlanListDisplayed[productDeployed]) {
        let deploymentPlanDate = this.deploymentPlanListDisplayed[productDeployed][siteDeployed].deploymentPlan;

        if (parseInt(siteDeployed) == siteId && (this.anyFilterActive() || this.deploymentPlanIsInCurrentYear(deploymentPlanDate))) count++;
      }
    }

    return count;
  }

  getSumTarget() {
    let count = 0;

    for (let productId in this.deploymentPlanListDisplayed) {
      count += this.getTargetForProduct(parseInt(productId));
    }

    return count;
  }

  anyFilterActive(): boolean {
    return !Object.values(this.optionsSelected).every((option) => option) || this.quarterToDisplay != "Display All";
  }

  deploymentPlanIsInCurrentYear(deploymentPlanDate: string): boolean {
    return (
      deploymentPlanDate.endsWith("Q" + this.getYear().toString().substring(2, 4)) ||
      deploymentPlanDate == this.getYear().toString().substring(2, 4) + "+"
    );
  }

  deploymentPlanIsInNextYears(deploymentPlanDate: string): boolean {
    return (
      (deploymentPlanDate.includes("Q") && parseInt(deploymentPlanDate.split("Q")[1]) > parseInt(this.getYear().toString().substring(2, 4))) ||
      parseInt(deploymentPlanDate.split("+")[0]) > parseInt(this.getYear().toString().substring(2, 4))
    );
  }

  filterClicked(filter: string) {
    this.optionsSelected[filter] = !this.optionsSelected[filter];
    this.filterWithOptions();
  }

  filterWithOptions(): any {
    this.productsDisplayed = [];
    this.sitesDisplayed = [];

    this.deploymentPlanListDisplayed = {};

    if (!this.anyFilterActive()) {
      // If everything is selected, reset to all values displayed
      this.productsDisplayed = [...this.products];
      this.sitesDisplayed = [...this.sites];
      this.deploymentPlanListDisplayed = JSON.parse(JSON.stringify(this.deploymentPlanList));
    } else {
      for (let productDeployed in this.deploymentPlanList) {
        for (let siteDeployed in this.deploymentPlanList[productDeployed]) {
          this.treatCell(productDeployed, siteDeployed);
        }
      }

      this.productsDisplayed = [...new Set(this.productsDisplayed)].sort((a, b) => {
        return this.products.findIndex(p => p.id == a.id) - this.products.findIndex(p => p.id == b.id);
      });
      this.sitesDisplayed = [...new Set(this.sitesDisplayed)].sort((a, b) => {
        return this.sites.findIndex(s => s.id == a.id) - this.products.findIndex(s => s.id == b.id);
      });
    }
  }

  treatCell(productDeployed: string, siteDeployed: string): void {
    let cell = this.deploymentPlanList[productDeployed][siteDeployed];
    let toDisplay = true;

    if (
      !this.deploymentPlanIsInCurrentYear(cell.deploymentPlan) &&
      !this.deploymentPlanIsInNextYears(cell.deploymentPlan) &&
      !this.optionsSelected["Done"]
    ) {
      toDisplay = false;
    }

    if (this.deploymentPlanIsInCurrentYear(cell.deploymentPlan) && !this.optionsSelected["In progress"]) {
      toDisplay = false;
    }

    if (this.deploymentPlanIsInNextYears(cell.deploymentPlan) && !this.optionsSelected["To do"]) {
      toDisplay = false;
    }

    if (cell.status != null && !this.optionsSelected[cell.status]) {
      toDisplay = false;
    }

    if (this.quarterToDisplay != "Display All" && cell.deploymentPlan != this.quarterToDisplay) {
      toDisplay = false;
    }

    if (toDisplay) {
      this.productsDisplayed.push(this.products.filter((p) => p.id.toString() == productDeployed)[0]);
      this.sitesDisplayed.push(this.sites.filter((s) => s.id.toString() == siteDeployed)[0]);

      if (this.deploymentPlanListDisplayed[productDeployed] == null) this.deploymentPlanListDisplayed[productDeployed] = {};
      this.deploymentPlanListDisplayed[productDeployed][siteDeployed] = this.deploymentPlanList[productDeployed][siteDeployed];
    }
  }

  editProduct(product) {
    this.dialog
      .open(EditDeploymentPlanComponent, {
        width: "30vw",
        minWidth: "400px",
        data: {
          deploymentPlans: JSON.parse(JSON.stringify(this.deploymentPlanList)),
          products: this.products,
          initialProductId: product.id,
          sites: this.sites,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res == "success") {
          this.loadTableData();
        }
      });
  }
}
