import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedStateService {
  private readonly showHiddenComponentSubject = new BehaviorSubject<boolean>(false);
  private readonly showHiddenComponentDPCSubject = new BehaviorSubject<boolean>(false);
  private readonly isLoadingSubject = new BehaviorSubject<{[key: string]: boolean}>({
    Roadmap: true,
    "Squad-0": true,
    "Squad-gauges-0": true,
    Finops: true,
    "Usage-7d": true,
    "Squad-1": true,
    "Squad-gauges-1": true,
    "Usage-1month": true,
  });

  private readonly selectedQuarters: {[component: string]: BehaviorSubject<string>} = {};

  showHiddenComponent$ = this.showHiddenComponentSubject.asObservable();
  showHiddenComponentDPC$ = this.showHiddenComponentDPCSubject.asObservable();
  isLoading$ = this.isLoadingSubject.asObservable();

  constructor() {
    // Initialize the current quarter for all components
    const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
    const defaultQuarter = `Q${currentQuarter}`;
    this.setSelectedQuarter("DeployIt", defaultQuarter);
    this.setSelectedQuarter("TeamConfidence", defaultQuarter);
    this.setSelectedQuarter("DeploymentProgress", defaultQuarter);
  }

  setShowHiddenComponent(show: boolean): void {
    this.showHiddenComponentSubject.next(show);
  }

  setShowHiddenComponentDPC(show: boolean): void {
    this.showHiddenComponentDPCSubject.next(show);
  }

  setLoadingStatus(section: string, isLoading: boolean): void {
    const currentState = this.isLoadingSubject.value;
    const newState = {...currentState, [section]: isLoading};
    this.isLoadingSubject.next(newState);
  }

  clearLoadingStatus(): void {
    this.isLoadingSubject.next({});
  }

  setSelectedQuarter(component: string, quarter: string): void {
    if (!this.selectedQuarters[component]) {
      this.selectedQuarters[component] = new BehaviorSubject<string>(quarter);
    } else {
      this.selectedQuarters[component].next(quarter);
    }
  }

  getSelectedQuarter(component: string): string {
    return this.selectedQuarters[component]?.value || "";
  }

  getSelectedQuarterObservable(component: string): BehaviorSubject<string> {
    if (!this.selectedQuarters[component]) {
      this.selectedQuarters[component] = new BehaviorSubject<string>("");
    }
    return this.selectedQuarters[component];
  }
}
