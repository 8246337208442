export const GRAPH_COLORS = [
  "#ed9a9a", // $data-red-200
  "#f48fb1", // $data-pink-200
  "#ce93d8", // $data-purple-200
  "#b39ddb", // $data-deep-purple-200
  "#9fa8da", // $data-indigo-200
  "#90caf9", // $data-blue-200
  "#80deea", // $data-cyan-200
  "#80cbc4", // $data-teal-200
  "#c5e1a5", // $data-grass-200
  "#e6ee9c", // $data-lime-200
  "#fff59d", // $data-yellow-200
  "#ffcc80", // $data-orange-200
  "#ffab91", // $data-deep-orange-200
  "rgba(233, 30, 99, 0.70)", // $data-pink-500
  "rgba(156, 39, 176, 0.70)", // $data-purple-500
  "rgba(103, 58, 183, 0.70)", // $data-deep-purple-500
  "rgba(63, 81, 181, 0.70)", // $data-indigo-500
  "rgba(33, 150, 243, 0.70)", // $data-blue-500
  "rgba(0, 158, 179, 0.70)", // $data-cyan-500
  "rgba(128, 203, 196, 0.70)", // $data-teal-500
  "rgba(111, 161, 54, 0.70)", // $data-grass-500
  "rgba(135, 147, 26, 0.70)", // $data-lime-500
  "rgba(215, 193, 0, 0.70)", // $data-yellow-500
  "rgba(214, 129, 0, 0.70)", // $data-orange-500
  "rgba(255, 87, 34, 0.70)", // $data-deep-orange-500
  "rgba(163, 0, 0, 0.70)", // $data-red-800
  "rgba(173, 20, 87, 0.70)", // $data-pink-800
  "rgba(106, 27, 154, 0.70)", // $data-purple-800
  "rgba(69, 39, 160, 0.70)", // $data-deep-purple-800
  "rgba(40, 53, 147, 0.70)", // $data-indigo-800
  "rgba(21, 101, 192, 0.70)", // $data-blue-800
  "rgba(0, 131, 143, 0.70)", // $data-cyan-800
  "rgba(0, 105, 92, 0.70)", // $data-teal-800
  "rgba(84, 122, 41, 0.70)", // $data-grass-800
  "rgba(92, 100, 17, 0.70)", // $data-lime-800
  "rgba(155, 139, 0, 0.70)", // $data-yellow-800
  "rgba(170, 102, 0, 0.70)", // $data-orange-800
  "rgba(221, 52, 0, 0.70)", // $data-deep-orange-800
];
