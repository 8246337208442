import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {PillarDTO} from "./pillar-dto";

export interface Pillar {
  id: number;
  name: string;
}
@Injectable({
  providedIn: "root",
})
export class EightPillarsService {
  private readonly apiUrl = `${environment.webProtocol}://${environment.serverUrl}/api/pillars`;

  constructor(private readonly http: HttpClient) {}

  getAllPillarsByProduct(productId: number): Observable<PillarDTO[]> {
    return this.http.get<PillarDTO[]>(this.apiUrl + "/by-product", {
      params: {
        productId: productId,
      },
    });
  }

  changePillarStatus(pillarId: number, status: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/change-pillar-status", {
      params: {
        pillarId: pillarId,
        status: status,
      },
    });
  }

  getPillarsByProductId(productId: number): Observable<Pillar[]> {
    return this.http.get<Pillar[]>(`${this.apiUrl}/product/${productId}`);
  }

  updateCommentOrder(updatedComments: { id: number, commentOrder: number }[]): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/update-comment-order`, updatedComments);
  }
}
