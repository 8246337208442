import {Component, OnInit, ViewChild} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {Chart} from "chart.js";
import * as htmlToImage from "html-to-image";
import moment from "moment";
import {ToastrService} from "ngx-toastr";
import {Subscription} from "rxjs";
import {ArrayService} from "../common/array.service";
import {AuthenticationService} from "../common/authentication.service";
import {SharedEventsService} from "../common/shared-events-service";
import {SharedStateService} from "../common/shared-state.service";
import {ProductDTO, UserService} from "../common/user.service";
import {NotificationService} from "../notification/notification.service";
import {ConfirmSaveDpcDataComponent} from "../portfolio-review/confirm-save-dpc-data/confirm-save-dpc-data.component";
import {PortfolioReviewService} from "../portfolio-review/portfolio-review.service";
import {SelectQuarterDialogComponent} from "../portfolio-review/select-quarter-dialog/select-quarter-dialog.component";
import {ProductDetailsService} from "../product-details/product-details.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  userProducts: ProductDTO[] = [];
  selectedProductId: number;
  temporaryProduct: ProductDTO | null = null;
  isAllLoaded = true;
  loading: boolean = false;
  public username: string;
  private loadingSubscription: Subscription | null = null;

  portfolioReviewRights: Array<string> = [];
  isAdmin: boolean = this.userService.getUserIsAdmin();

  @ViewChild("notificationMenuRef") notificationMenuRef;

  seeMore: boolean;
  notifications: any;
  notificationsToDisplay: any;
  notificationDisplaySelected: number = 0;
  lastOpenedNotifications: number;
  notificationsNotOpenedCount: number = 0;

  portfolioReviewBranch: string;

  constructor(
    public authenticationService: AuthenticationService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly sharedEventsService: SharedEventsService,
    public sharedStateService: SharedStateService,
    private readonly notificationService: NotificationService,
    private readonly toastrService: ToastrService,
    private readonly productDetailsService: ProductDetailsService,
    private readonly portfolioReviewService: PortfolioReviewService,
    private readonly arrayService: ArrayService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.authenticationService.userIsAuthenticated) {
      this.username = this.authenticationService.username;
      this.loadUserProducts();

      this.sharedEventsService.reloadHeader$.subscribe(() => {
        this.loadUserProducts();
      });

      this.sharedEventsService.selectedProduct$.subscribe((product) => {
        if (!this.userProducts.some((userProduct) => userProduct.id === product.id)) {
          this.temporaryProduct = product;
        }
        this.selectedProductId = product?.id || null;
      });

      this.userService.getLastOpenedNotifications().subscribe((lastOpened) => {
        this.lastOpenedNotifications = lastOpened.result;
        this.userService.getNotifications().subscribe((notifications) => {
          this.notifications = notifications;
          this.notificationsToDisplay = notifications;
          this.notificationsNotOpenedCount = this.notifications.filter((notification) => notification.date > this.lastOpenedNotifications).length;
        });
      });
    }
  }

  loadUserProducts() {
    this.userService.getProductsForUserWithEditRights(this.authenticationService.email).subscribe(
      (products) => {
        this.userProducts = products;

        // Redirect to first product if no route active
        if (!this.isOnRoute("product") && !this.isOnRoute("portfolio-review") && !this.isOnRoute("search") && !this.isOnRoute("help")) {
          if (products.length == 0) {
            this.router.navigate(["/search"]);
          } else {
            const firstProduct = products[0];
            this.router.navigate(["/product", firstProduct.name, firstProduct.id]); // Redirect to first product
            this.selectedProductId = firstProduct.id;
          }
        } else {
          const productIdParam = this.route.snapshot.firstChild?.paramMap.get("id");
          this.selectedProductId = parseInt(productIdParam, 10);

          if (!this.userProducts.some((userProduct) => userProduct.id === this.selectedProductId) && this.isOnRoute("product")) {
            this.productDetailsService.getBaseProductInfo(this.selectedProductId).subscribe((product) => {
              this.temporaryProduct = product;
            });
          }
        }
      },
      (error) => {
        console.error("Error when getting the products :", error);
      }
    );

    this.userService.getPortfolioReviewRights().subscribe((portfolioReviewRights) => {
      this.portfolioReviewRights = portfolioReviewRights;
    });
  }

  onProductSelect(productId: number): void {
    this.selectedProductId = productId;
    this.temporaryProduct = null;
  }

  onSearchClick(): void {
    this.selectedProductId = null;
    this.temporaryProduct = null;
  }

  onPortfolioReviewClick(): void {
    this.selectedProductId = null;
    this.temporaryProduct = null;
  }

  isOnRoute(routeIncludes: string): boolean {
    const currentRoute = this.route.snapshot.firstChild; // Get active route
    // Check if the active route is a 'product' route
    return currentRoute?.routeConfig?.path?.includes(routeIncludes);
  }

  savePage(type: "DPR" | "DPC"): void {
    if (type === "DPR") {
      this.loading = true;
      this.sharedStateService.clearLoadingStatus();
      this.sharedStateService.setShowHiddenComponent(true);

      const loadingObservable = this.sharedStateService.isLoading$;

      this.loadingSubscription = loadingObservable.subscribe((loadingState) => {
        this.isAllLoaded = Object.values(loadingState).every((status) => status === false) && Object.keys(loadingState).length > 0;

        if (this.isAllLoaded) {
          if (this.loadingSubscription) {
            this.loadingSubscription.unsubscribe();
          }
          this.capturePage(type);
        }
      });
    } else if (type === "DPC") {
      // Open the popup to select the quarter
      const dialogRef = this.dialog.open(SelectQuarterDialogComponent, {
        width: "400px",
      });

      dialogRef.afterClosed().subscribe((selectedQuarter) => {
        if (selectedQuarter) {
          this.loading = true;

          // Save original quarters to revert back after the capture
          const originalQuarters = {
            DeployIt: this.sharedStateService.getSelectedQuarter("DeployIt"),
            TeamConfidence: this.sharedStateService.getSelectedQuarter("TeamConfidence"),
            DeploymentProgress: this.sharedStateService.getSelectedQuarter("DeploymentProgress"),
          };
          this.sharedStateService.setSelectedQuarter("DeployIt", selectedQuarter);
          this.sharedStateService.setSelectedQuarter("TeamConfidence", selectedQuarter);
          this.sharedStateService.setSelectedQuarter("DeploymentProgress", selectedQuarter);

          this.sharedStateService.setShowHiddenComponentDPC(true);
          setTimeout(() => {
            this.capturePage(type, selectedQuarter, originalQuarters);
          }, 3000); // Delay of 3 seconds for everything to load
        }
      });
    }
  }

  private capturePage(type: "DPR" | "DPC", selectedQuarter?: string, originalQuarters?: any): void {
    const element = document.body;
    const hiddenComponent = document.getElementById("hidden-component");

    if (hiddenComponent) {
      hiddenComponent.style.visibility = "visible";
      hiddenComponent.style.height = "auto";
    }

    const visibleComponent = document.getElementById("visible-component");

    if (visibleComponent) {
      visibleComponent.style.visibility = "hidden";
      visibleComponent.style.height = "0";
    }

    const noExportElements = document.getElementsByClassName("no-export");
    Array.from(noExportElements).forEach((element) => {
      (element as HTMLElement).style.display = "none";
    });

    const chartElement = Chart.getChart(`activityDistributionChart-${selectedQuarter}`);
    if (chartElement) {
      chartElement.options.scales.x.max = null;
      chartElement.update("none");
    }

    const actionPlanContainer = document.getElementById("action-plan-table-container");
    actionPlanContainer.style.overflow = "hidden";
    actionPlanContainer.style.maxHeight = "none";

    const commentElements = document.getElementsByClassName("comment");
    Array.from(commentElements).forEach((commentElement) => {
      (commentElement as HTMLElement).style.overflow = "hidden";
      (commentElement as HTMLElement).style.maxHeight = "none";
    });

    htmlToImage
      .toPng(element, {
        quality: 1,
        filter: (domNode) => {
          const className = typeof domNode.className === "string" ? domNode.className : "";
          return className.indexOf("no-export") === -1 && className.indexOf("no-export-loader") === -1;
        },
      })
      .then((dataUrl) => {
        if (type === "DPR") {
          const selectedProduct = this.temporaryProduct || this.userProducts.find((product) => product.id === this.selectedProductId);
          const branches = this.arrayService.formatArrayForDisplay(
            selectedProduct.branches?.map((branch) => branch.branchName),
            ""
          );

          const productName = selectedProduct.name || "";
          const currentDate = new Date().toISOString().split("T")[0];

          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = `${currentDate} DPR ${branches} ${productName}.png`;
          link.click();
        } else if (type === "DPC") {
          const branch = this.route.firstChild.snapshot.paramMap.get("branch")?.toUpperCase() || "UnknownBranch";
          const currentDate = new Date().toISOString().split("T")[0];

          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = `${currentDate} DPC ${branch} ${selectedQuarter}.png`;
          link.click();
        }

        if (hiddenComponent) {
          hiddenComponent.style.visibility = "hidden";
          hiddenComponent.style.height = "0";
        }

        if (visibleComponent) {
          visibleComponent.style.visibility = "visible";
          visibleComponent.style.height = "auto";
        }

        Array.from(noExportElements).forEach((element) => {
          (element as HTMLElement).style.removeProperty("display");
        });

        if (chartElement) {
          chartElement.options.scales.x.max = 9;
          chartElement.update("none");
        }

        if (type === "DPR") {
          this.sharedStateService.setShowHiddenComponent(false);
        } else {
          // Revert back to the original quarter
          this.sharedStateService.setSelectedQuarter("DeployIt", originalQuarters.DeployIt);
          this.sharedStateService.setSelectedQuarter("TeamConfidence", originalQuarters.TeamConfidence);
          this.sharedStateService.setSelectedQuarter("DeploymentProgress", originalQuarters.DeploymentProgress);
          this.sharedStateService.setShowHiddenComponentDPC(false);
        }
        this.loading = false;
      })
      .catch((error) => {
        console.error("Error during the screen capture :", error);

        if (hiddenComponent) {
          hiddenComponent.style.visibility = "hidden";
          hiddenComponent.style.height = "0";
        }

        if (visibleComponent) {
          visibleComponent.style.visibility = "visible";
          visibleComponent.style.height = "auto";
        }

        Array.from(noExportElements).forEach((element) => {
          (element as HTMLElement).style.removeProperty("display");
        });

        if (type === "DPR") {
          this.sharedStateService.setShowHiddenComponent(false);
        } else {
          this.sharedStateService.setShowHiddenComponentDPC(false);
        }
        this.loading = false;
      });
  }

  getDateDiff(notificationDate: number) {
    let daysDiff = Math.floor(moment().diff(moment(notificationDate)) / 1000 / 60 / 60 / 24);
    return daysDiff == 0 ? "today" : daysDiff + "d";
  }

  updateNotificationsDisplayed(optionSelected: number) {
    this.notificationDisplaySelected = optionSelected;
    this.notificationsToDisplay = optionSelected == 1 ? this.notifications.filter((notif) => !notif.read) : this.notifications;
  }

  invertNotificationRead(notification: any) {
    this.notificationService.invertNotificationRead(notification.id).subscribe(
      () => {
        notification.read = !notification.read;
        this.notificationsToDisplay = this.notificationDisplaySelected == 1 ? this.notifications.filter((notif) => !notif.read) : this.notifications;
      },
      () => {
        this.toastrService.error("An error occurred.");
      }
    );
  }

  deleteNotification(notification: any) {
    this.notificationService.deleteNotification(notification.id).subscribe(
      () => {
        this.notifications.splice(this.notifications.indexOf(notification), 1);
      },
      () => {
        this.toastrService.error("An error occurred.");
      }
    );
  }

  notificationsOpened() {
    this.notificationService.notificationsOpened().subscribe(() => {
      this.lastOpenedNotifications = new Date().valueOf();
      this.notificationsNotOpenedCount = 0;
    });
  }

  goToNotification(notification) {
    this.notificationMenuRef.close();
    this.onProductSelect(notification.productId);
    this.notificationService.markAsRead(notification.id).subscribe(() => {
      notification.read = true;
    });

    if (notification.topic.startsWith("actionPlanReminder")) {
      this.router.navigate(["/product", notification.productName, notification.productId], {fragment: "action-plan"});
    } else if (notification.topic == "eventReminder" || notification.topic == "groupReminder") {
      this.router.navigate(["/product", notification.productName, notification.productId]);

      let fragment = "roadmap-" + (notification.topic.startsWith("event") ? "event" : "group");
      if (notification.topic.startsWith("event")) fragment += "-" + notification.roadmapEvent.id;
      else fragment += "-" + notification.roadmapGroup.id;

      this.router.navigate(["/product", notification.productName, notification.productId], {
        fragment: fragment,
      });
    } else if (notification.topic == "deploymentPlanChanges") {
      this.router.navigate(["/product", notification.productName, notification.productId], {
        fragment: "roadmap",
      });
    }
  }

  seeMoreClicked() {
    this.seeMore = true;
    this.updateNotificationsDisplayed(this.notificationDisplaySelected);
  }

  saveDPCData() {
    let branch = this.route.firstChild.snapshot.paramMap.get("branch").toUpperCase();

    if (branch) {
      this.portfolioReviewService.getQuartersToSave(branch).subscribe((qToSave) => {
        if (qToSave.length > 0) {
          this.dialog
            .open(ConfirmSaveDpcDataComponent, {
              width: "40vw",
              position: {
                top: "50px",
              },
              data: {
                quartersToSave: qToSave,
              },
            })
            .afterClosed()
            .subscribe((res) => {
              if (res && res[0] == "confirm") {
                this.portfolioReviewService.saveDPCData(branch, res[1].quarter, res[1].year).subscribe(
                  () => {
                    this.toastrService.success("DPC data saved !");
                    this.sharedEventsService.notifyDpcDataSaved();
                  },
                  () => {
                    this.toastrService.error("An error occurred while saving the DPC data.");
                  }
                );
              }
            });
        } else {
          this.toastrService.info("All quarters have already been saved.");
        }
      });
    }
  }
}
