<div *ngIf="showBanner" class="alert alert-dismissible alert-warning d-flex wrong-url-banner" role="alert">
  <span class="material-icons">error</span>
  <button type="button" class="btn-close mt-2" data-bs-dismiss="alert"></button>
  <div>
    <div>The URL you used is no longer valid. You have been redirected to the correct URL.</div>
  </div>
</div>
<app-header *ngIf="userExist && initialized"></app-header>
<div class="body">
  <router-outlet *ngIf="initialized"></router-outlet>
</div>
