import {Component, ElementRef, Inject, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Editor, Toolbar} from "ngx-editor";
import {ToastrService} from "ngx-toastr";
import {SharedEventsService} from "../common/shared-events-service";
import {UserDTO} from "../common/user.service";
import {branches, domainsEP, domainsRC, squadProfiles, steps} from "../common/util/const";
import {DateUtil} from "../common/util/date-util";
import {ProductService} from "../product/product.service";

@Component({
  selector: "app-add-product",
  templateUrl: "./add-product.component.html",
  styleUrls: ["./add-product.component.scss"],
})
export class AddProductComponent implements OnInit {
  @ViewChild("fileInput") fileInput!: ElementRef;
  addProductForm: FormGroup;
  branches = branches;
  lbu = [];
  domains = [...domainsEP.map((d) => d.name), ...domainsRC.map((d) => d.name)];
  domainsEP = domainsEP;
  domainsRC = domainsRC;
  squadProfiles = squadProfiles;
  steps = steps.map((step) => step.name);
  showSiteField = false;
  specialFieldIsRequired: any = {};
  sites: Array<string> = [];
  products: any[] = []; // List of products for the associated product dropdown

  selectedProductOwners: any = [];
  selectedSponsors: any = [];
  selectedDomainLeads: any = [];
  selectedChangeCoordinators: any = [];
  selectedItScaler: any;

  public editor: Editor;
  public toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["ordered_list", "bullet_list"],
    [{heading: ["h1", "h2", "h3", "h4", "h5", "h6"]}],
    ["text_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
  ];

  domainFields: any = [];
  oldSelectedBranches: Array<string> = [];
  description: string = "";

  constructor(
    private readonly fb: FormBuilder,
    private readonly toastr: ToastrService,
    private readonly productService: ProductService,
    public dialogRef: MatDialogRef<AddProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly sharedEventsService: SharedEventsService
  ) {
    this.addProductForm = this.fb.group({
      logo: [null],
      name: ["", Validators.required],
      branches: [null, Validators.required],
      lbu: [[]],
      sites: [[]],
      step: ["", Validators.required],
      pilot: [null],
      hold: [false],
      ideaOrPoc: [""],
      sponsors: [[]],
      productOwners: [[]],
      domainLeads: [[]],
      itScaler: [""],
      changeCoordinators: [[]],
      squadProfile: [""],
      statusCreationDate: new Date().toISOString().split("T")[0],
      statusTransferDate: null,
      statusRunDate: null,
      statusDecommissioningDate: null,
      jiraProjectKey: [""],
      finopsBsName: [""],
      description: [""],
      associatedProductId: [null],
    });

    if (this.data?.product?.productOwners) {
      this.selectedProductOwners = this.data.product.productOwners;
    }
    if (this.data?.product?.sponsors) {
      this.selectedSponsors = this.data.product.sponsors;
    }
    if (this.data?.product?.changeCoordinators) {
      this.selectedChangeCoordinators = this.data.product.changeCoordinators;
    }
    if (this.data?.product?.domainLeads) {
      this.selectedDomainLeads = this.data.product.domainLeads;
    }
    if (this.data?.product?.itScaler) {
      this.selectedItScaler = this.data.product.itScaler;
    }
  }

  ngOnInit(): void {
    this.editor = new Editor();

    // Fetch the list of products for the associated product dropdown
    this.productService.getAllProducts().subscribe((products) => {
      this.products = products;
    });

    if (this.data?.product?.id) {
      let ideaOrPoc: string = "";
      if (this.data.product.idea) ideaOrPoc = "idea";
      else if (this.data.product.poc) ideaOrPoc = "poc";

      this.productService.getAllSitesByBranches(this.data.product.branches).subscribe((sites) => {
        this.lbu = [];
        sites.forEach((site) => {
          if (!this.lbu.find((lbu) => lbu.name == site.lbu.name)) {
            this.lbu.push({name: site.lbu.name, flag: `assets/flags/${site.lbu.isoCode.toLowerCase()}.svg`});
          }
        });
      });

      this.description = this.data.product.description || "";

      this.addProductForm.patchValue({
        logo: this.data.product.logo,
        name: this.data.product.name,
        branches: this.data.product.branches,
        lbu: this.data.product.lbus,
        sites: this.data.product.sites,
        pilot: this.data.product.pilot,
        step: this.data.product.step,
        statusCreationDate: this.data.product.statusCreationDate ? DateUtil.dateWithoutTimezone(this.data.product.statusCreationDate) : "",
        statusTransferDate: this.data.product.statusTransferDate ? DateUtil.dateWithoutTimezone(this.data.product.statusTransferDate) : "",
        statusRunDate: this.data.product.statusRunDate ? DateUtil.dateWithoutTimezone(this.data.product.statusRunDate) : "",
        statusDecommissioningDate: this.data.product.statusDecommissioningDate
          ? DateUtil.dateWithoutTimezone(this.data.product.statusDecommissioningDate)
          : "",
        description: this.description,
        hold: this.data.product.hold,
        ideaOrPoc: ideaOrPoc,
        domainLeads: this.data.product.domainLeads,
        itScaler: this.data.product.itScaler,
        changeCoordinators: this.data.product.changeCoordinators,
        squadProfile: this.data.product.squadProfile,
        jiraProjectKey: this.data.product.jiraProjectKey,
        finopsBsName: this.data.product.finopsBsName,
        productOwners: this.data.product.productOwners,
        sponsors: this.data.product.sponsors,
        associatedProductId: this.data.product.associatedProductId || null, // Set the associated product if it exists
      });

      this.oldSelectedBranches = this.data.product.branches;

      if (this.data.product.branches != null) {
        this.createDomainDropdowns();
      }

      this.getSitesByBranches(this.addProductForm.get("branches").value);

      this.showSiteField = this.addProductForm.get("branches").value.some((branch: string) => branch === "RC");
    }

    this.addProductForm.get("branches")?.valueChanges.subscribe((selectedBranches) => {
      this.addProductForm.patchValue({pilot: null});
      this.addProductForm.get("pilot").enable();
      this.addProductForm.updateValueAndValidity();
      this.getSitesByBranches(selectedBranches);

      this.showSiteField = selectedBranches.some((branch: string) => branch === "RC");

      this.addOrRemoveDomainDropdownBasedOnBranches(selectedBranches);
    });

    this.addProductForm.get("squadProfile").valueChanges.subscribe((value) => {
      if (value !== "Multi Product") {
        this.addProductForm.get("associatedProductId").setValue(null);
      }
    });

    const stepControl = this.addProductForm.get("step");
    const pilotControl = this.addProductForm.get("pilot");

    if (!pilotControl?.value) {
      pilotControl.enable({emitEvent: false});
    }
    if ((stepControl?.value === "BUILD/BUY" || stepControl?.value === "OPERATIONAL PRODUCT (Transferred / Run)") && pilotControl?.value) {
      pilotControl.disable({emitEvent: false});
    }

    // Update requirements based on the initial step value.
    this.setRequirementsBasedOnChosenStep();

    // Subscribe to step changes to update validation and flag.
    stepControl?.valueChanges.subscribe(() => {
      this.setRequirementsBasedOnChosenStep();
    });
  }

  setRequirementsBasedOnChosenStep() {
    let affectedFields: Array<string> = ["pilot", "productOwners", "itScaler", "squadProfile"];

    let step = this.addProductForm.get("step")?.value;

    for (let field of affectedFields) {
      let control = this.addProductForm.get(field);
      if (step === "BUILD/BUY" || step === "OPERATIONAL PRODUCT (Transferred / Run)") {
        control?.setValidators(Validators.required);
        this.specialFieldIsRequired[field] = true;
      } else {
        control?.clearValidators();
        this.specialFieldIsRequired[field] = false;
      }
      control?.updateValueAndValidity();
    }
  }

  getSitesByBranches(selectedBranches) {
    this.productService.getAllSitesByBranches(selectedBranches).subscribe((sites) => {
      this.sites = sites;

      this.lbu = [];
      sites.forEach((site) => {
        if (!this.lbu.find((lbu) => lbu.name == site.lbu.name)) {
          this.lbu.push({name: site.lbu.name, flag: `assets/flags/${site.lbu.isoCode.toLowerCase()}.svg`});
        }
      });

      let currentLBUs = this.addProductForm.get("lbu")?.value;
      currentLBUs = currentLBUs?.length > 0 ? currentLBUs.filter((lbu) => this.lbu.find((lbuItem) => lbuItem.name == lbu)) : [];
      this.addProductForm.patchValue({lbus: currentLBUs});
    });
  }

  onSubmit(): void {
    if (this.addProductForm.valid) {
      const formValues = this.addProductForm.getRawValue();
      formValues.description = this.description;
      const productToSave = {
        id: this.data?.product?.id,
        logo: formValues.logo,
        name: formValues.name,
        branches: formValues.branches || [],

        // Transform domains into the required format
        lbus: formValues.lbu.map((lbu) => lbu.name || lbu),

        step: formValues.step,
        pilot: formValues.pilot,
        hold: formValues.hold,
        idea: formValues.ideaOrPoc === "idea",
        poc: formValues.ideaOrPoc === "poc",
        description: formValues.description || "",

        // Transform domains into the required format
        domains: Object.keys(formValues)
          .filter((key) => key.startsWith("domain-"))
          .map((key: string) => ({
            branch: key.split("-")[1],
            name: formValues[key],
          })),

        // Transform sponsors into the required format
        sponsors: formValues.sponsors.map((user: UserDTO) => ({
          email: user.email,
          fullName: user.fullName,
        })),

        // Transform productOwners into the required format
        productOwners: formValues.productOwners.map((user: UserDTO) => ({
          email: user.email,
          fullName: user.fullName,
        })),

        // Transform domainLeads into the required format
        domainLeads: formValues.domainLeads.map((user: UserDTO) => ({
          email: user.email,
          fullName: user.fullName,
        })),
        itScaler: formValues.itScaler
          ? {
            email: formValues.itScaler.email,
            fullName: formValues.itScaler.fullName,
          }
          : null,
        changeCoordinators: formValues.changeCoordinators.map((user: UserDTO) => ({
          email: user.email,
          fullName: user.fullName,
        })),
        sites: formValues.sites.map((site) => ({
          id: site.id,
        })),
        squadProfile: formValues.squadProfile,
        statusCreationDate: formValues.statusCreationDate,
        statusTransferDate: formValues.statusTransferDate,
        statusRunDate: formValues.statusRunDate,
        statusDecommissioningDate: formValues.statusDecommissioningDate,
        jiraProjectKey: formValues.jiraProjectKey,
        finopsBsName: formValues.finopsBsName,
        associatedProductId: formValues.associatedProductId, // Use associatedProductId directly
      };

      this.productService.saveProduct(productToSave).subscribe(
        () => {
          this.toastr.success("Product " + (this.data?.product?.id ? "updated" : "added") + " successfully.", "Success");
          this.dialogRef.close(productToSave);

          // Notify product update
          this.sharedEventsService.notifyProductUpdated();
        },
        () => {
          this.toastr.error("Failed to " + (this.data?.product?.id ? "update" : "add") + " product. Try again.", "Error");
        }
      );
    } else {
      this.toastr.error("Please fill out the form correctly.", "Error");
    }
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onUsersSelected(selectedUsers: UserDTO[], key: string): void {
    if (key === "itScaler") {
      const selectedUser = selectedUsers.length > 0 ? selectedUsers[0] : null;
      this.addProductForm.patchValue({
        [key]: selectedUser,
      });
    } else {
      this.addProductForm.patchValue({
        [key]: selectedUsers.map((user) => ({
          ...user,
        })),
      });
    }
  }

  triggerFileUpload(): void {
    this.fileInput.nativeElement.click();
  }

  handleKeyPress(event: KeyboardEvent): void {
    if (event.key === "Enter") {
      this.triggerFileUpload();
    }
  }

  onLogoChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.addProductForm.patchValue({
          logo: reader.result as string,
        });
      };
      reader.readAsDataURL(file);
    }
  }

  toggleRadio(value: string): void {
    const currentValue = this.addProductForm.get("ideaOrPoc")?.value;
    if (currentValue === value) {
      this.addProductForm.get("ideaOrPoc")?.setValue("");
    }
  }

  createDomainDropdowns() {
    this.domainFields = [];
    for (let branch of this.data.product.branches) {
      let items = [...this.domainsEP.map((d) => d.name), ...this.domainsRC.map((d) => d.name)];

      if (branch == "EP") items = this.domainsEP.map((d) => d.name);
      else if (branch == "RC") items = this.domainsRC.map((d) => d.name);

      this.domainFields.push({
        label: "Domain" + (this.data.product.branches.length > 1 ? " (" + branch + ")" : ""),
        items: items,
        formControlName: "domain-" + branch,
        branch: branch,
      });

      this.addProductForm.addControl(
        "domain-" + branch,
        new FormControl(this.data?.product?.domains?.find((domain) => domain.branch == branch)?.name)
      );
      this.addProductForm.get("domain-" + branch).setValidators(Validators.required);
    }
  }

  addOrRemoveDomainDropdownBasedOnBranches(selectedBranches: Array<string>) {
    if (selectedBranches.length > this.oldSelectedBranches.length) {
      // Branch added
      let branchAdded = selectedBranches.find((branch) => !this.oldSelectedBranches.includes(branch));

      let items = [...this.domainsEP.map((d) => d.name), ...this.domainsRC.map((d) => d.name)];

      if (branchAdded == "EP") items = this.domainsEP.map((d) => d.name);
      else if (branchAdded == "RC") items = this.domainsRC.map((d) => d.name);

      this.domainFields.push({
        label: "Domain" + (selectedBranches.length > 1 ? " (" + branchAdded + ")" : ""),
        type: "ng-select",
        items: items,
        formControlName: "domain-" + branchAdded,
        multiple: false,
        required: true,
        class: "select-control",
      });

      this.addProductForm.addControl("domain-" + branchAdded, new FormControl(null, Validators.required));

      if (selectedBranches.length == 2) {
        // Special case if we are going from 1 to 2, we need to change the label of the first dropdown
        this.domainFields.find((field) => field.formControlName == "domain-" + selectedBranches[0]).label = "Domain (" + selectedBranches[0] + ")";
      }
    } else {
      // Branch removed
      let branchRemoved = this.oldSelectedBranches.find((branch) => !selectedBranches.includes(branch));
      this.domainFields.splice(
        this.domainFields.findIndex((field) => field.formControlName == "domain-" + branchRemoved),
        1
      );
      this.addProductForm.removeControl("domain-" + branchRemoved);

      if (selectedBranches.length == 1) {
        // Special case if we are going from 2 to 1, we need to change the label of the first dropdown
        this.domainFields.find((field) => field.formControlName == "domain-" + selectedBranches[0]).label = "Domain";
      }
    }

    this.oldSelectedBranches = [...selectedBranches];
  }
}
