import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import moment from "moment";
import {ToastrService} from "ngx-toastr";
import {RoadmapGroupHistory, RoadmapService} from "../common/roadmap.service";
import {ProductDetailsService} from "../product-details/product-details.service";
import {ProductService} from "../product/product.service";

@Component({
  selector: "app-add-group",
  templateUrl: "./add-group.component.html",
  styleUrls: ["./add-group.component.scss"],
})
export class AddGroupComponent implements OnInit, OnDestroy {
  addGroupForm: FormGroup;
  sites: Array<{ id: number; name: string; branch: string; lbu: { name: string; isoCode: string } }> = [];
  lbUs: Array<{ name: string; isoCode: string }> = [];
  sitesOptions: Array<{ id: number; name: string }> = [];
  title: string;
  isEditMode: boolean;
  minEventStartDate: Date;
  maxEventEndDate: Date;
  dateError: string | null = null;
  groupHistory: RoadmapGroupHistory[] = [];
  saveInProgress: boolean = false;
  selectedSites: Array<string> = [];

  intervalCheckRange: any;

  statusOptions = ["To do", "On-going", "Ready for site", "1st usage", "Go Live - Full Value", "Cancelled"];

  productBranches: Array<string> = [];

  notificationDateMin = moment().add(1, "days").format("YYYY-MM-DD");

  constructor(
    private readonly fb: FormBuilder,
    private readonly roadmapService: RoadmapService,
    public dialogRef: MatDialogRef<AddGroupComponent>,
    private readonly route: ActivatedRoute,
    private readonly toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA)
    public data: { productId: number; group?: any },
    private readonly productDetailsService: ProductDetailsService,
    private readonly productService: ProductService
  ) {
    // Check if edit mode
    this.isEditMode = !!this.data.group;
    this.title = this.isEditMode ? "Edit Group of Event" : "Create Group of Event";

    // Initialise form
    this.addGroupForm = this.fb.group(
      {
        product: [{id: this.data.productId}],
        name: ["", Validators.required],
        type: ["Deploy"],
        lbu: ["", Validators.required],
        startDate: [new Date().toISOString()],
        endDate: [new Date().toISOString()],
        status: this.statusOptions[0],
        events: this.fb.array([]),
        comment: [""],
        creationDate: [new Date().toISOString()],
        notificationActivation: false,
        notificationDate: [""],
        sites: [""],
      },
      {validators: this.dateRangeValidator.bind(this)}
    );

    // If edit mode, fill with existing values
    if (this.isEditMode && this.data.group) {
      this.addGroupForm.patchValue({
        name: this.data.group.name,
        type: this.data.group.type,
        lbu: this.data.group.lbu,
        startDate: moment(this.data.group.startDate).toISOString(),
        endDate: moment(this.data.group.endDate).toISOString(),
        status: this.data.group.status,
        comment: this.data.group.comment,
        creationDate: moment(this.data.group.creationDate).toISOString(),
        notificationActivation: this.data.group.notificationActivation,
        notificationDate: this.data.group.notificationDate ? moment(this.data.group.notificationDate).format("YYYY-MM-DD") : null,
        sites: this.data.group.sites.map((site) => site.site.name),
      });
      if (this.data.group.status != "Cancelled") {
        this.statusOptions = this.statusOptions.splice(this.statusOptions.indexOf(this.data.group.status));
        if (this.statusOptions.length == 1) this.addGroupForm.controls["status"].disable();
      }

      this.setEventDateConstraints();
      // Fetch history data for the group
      this.fetchGroupHistory(this.data.group.id);
    }

    /** Fixing weird bug where the date range is considered invalid when the start date is modified before the end date **/
    this.intervalCheckRange = setInterval(() => {
      this.addGroupForm.controls["startDate"].updateValueAndValidity();
      this.addGroupForm.controls["endDate"].updateValueAndValidity();
    }, 1000);
  }

  ngOnInit(): void {
    this.productDetailsService.getBranchesOfProduct(this.data.productId).subscribe((branches) => {
      this.productBranches = branches;
      this.filterLbusAndSites();

      this.productService.getAllSitesByBranches(this.productBranches).subscribe((sites) => {
        this.sites = sites;
        this.filterLbusAndSites();

        // Initialize sitesOptions for edit mode
        if (this.isEditMode && this.data.group) {
          this.selectedSites = this.data.group.sites.map((site) => site.site.name);
          this.sitesOptions = this.sites.filter((site) => site.lbu.name === this.data.group.lbu.name);
          this.addGroupForm.patchValue({sites: this.selectedSites});
        }
      });

      this.addGroupForm.get("lbu")?.valueChanges.subscribe((lbu) => {
        this.sitesOptions = this.sites.filter((site) => site.lbu.name === lbu.name);
        this.addGroupForm.get("sites")?.reset(); // Reset the selected sites
      });
    });
  }

  private filterLbusAndSites(): void {
    if (this.sites.length > 0 && this.productBranches.length > 0) {
      const uniqueLbus = new Map();
      this.sites.forEach((site) => {
        if (!uniqueLbus.has(site.lbu.name)) {
          uniqueLbus.set(site.lbu.name, site.lbu);
        }
      });
      this.lbUs = Array.from(uniqueLbus.values());
    }
  }

  private fetchGroupHistory(groupId: number): void {
    this.roadmapService.getGroupHistory(groupId).subscribe((groupHistory) => {
      this.groupHistory = groupHistory;
    });
  }

  private setEventDateConstraints(): void {
    const eventDates = this.data.group?.events.map((event) => ({
      start: new Date(event.startDate),
      end: new Date(event.endDate),
    }));

    if (eventDates && eventDates.length > 0) {
      this.minEventStartDate = new Date(Math.min(...eventDates.map((d) => d.start.getTime())));
      this.maxEventEndDate = new Date(Math.max(...eventDates.map((d) => d.end.getTime())));
    }
  }

  private dateRangeValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const startDate = new Date(control.get("startDate")?.value);
    const endDate = new Date(control.get("endDate")?.value);

    if (this.minEventStartDate && this.maxEventEndDate && (startDate > this.minEventStartDate || endDate < this.maxEventEndDate)) {
      this.dateError = `The selected date range should be less than ${this.minEventStartDate.toLocaleDateString()} and greater than ${this.maxEventEndDate.toLocaleDateString()}.`;
      return {dateOutOfRange: true};
    }
    this.dateError = null;
    return null;
  }

  onSubmit(): void {
    if (this.addGroupForm.valid) {
      let statusWasDisabled = this.addGroupForm.controls["status"].disabled;
      if (statusWasDisabled) this.addGroupForm.controls["status"].enable();

      const formData = this.addGroupForm.value;

      if (formData.notificationDate) formData.notificationDate = new Date(formData.notificationDate).toISOString();

      formData.sites = formData.sites?.map((siteName: string) => {
        const site = this.sites.find((s) => s.name === siteName);
        return {
          site: {id: site?.id || 0},
          roadmapGroup: {id: this.data.group?.id},
        };
      });

      if (statusWasDisabled) this.addGroupForm.controls["status"].disable();
      this.saveInProgress = false;
      this.roadmapService.saveGroup(formData, this.data.group?.id).subscribe((res) => {
        this.dialogRef.close(true);
        if (res) {
          this.saveInProgress = false;
          this.toastrService.success("Group has been saved !");
        } else {
          this.saveInProgress = false;
          this.toastrService.error("An error occurred while saving the group.");
        }
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    clearInterval(this.intervalCheckRange);
  }
}
