<div id="help-page">
    <div id="help">
        <div id="help-header">
            <h4>{{ "Help & Documentation" }}</h4>
        </div>

        <div id="container">
            <div class="grid-item" id="feedback">
                <div class="item-content">
                    <div class="item-header">
                        <img src="../../assets/feedback.svg" alt="" />
                        <h5>{{ "User feedback" }}</h5>
                    </div>
                    <p>
                        Do you have an idea for improving the tool? Have you encountered a technical problem?<br>
                        Let us know what you think!
                    </p>
                </div>
                <button type="button" class="btn btn-primary" (click)="openInNewTab('https://forms.office.com/e/X90qdvMVsX')">Forms questionnaire
                    <span class="material-icons-outlined">output</span>
                </button>
            </div>

            <div class="grid-item" id="sharepoints">
                <div class="item-content">
                    <div class="item-header">
                        <span class="material-icons-outlined">help_outline</span>
                        <h5>{{ "Do you need any help?" }}</h5>
                    </div>
                    <p>
                        Don't know how to use the product? Can't find what you're looking for?...<br>
                        Consult the documentation database!
                    </p>
                </div>
                <div id="double-buttons">
                    <button type="button"
                            class="btn btn-primary"
                            (click)="openInNewTab('https://totalworkplace.sharepoint.com/:f:/r/sites/Deploy-DigitalProductsAppsSupport/Shared%20Documents/E%26P/Deploy%20Cockpit/User%20guides%20%26%20manuals%20for%20products%20%26%20apps?csf=1&web=1&e=j5L8u4')">
                        Go to the PowerPoint Sharepoint folder
                        <span class="material-icons-outlined">output</span>
                    </button>
                    <button type="button"
                            class="btn btn-primary"
                            (click)="openInNewTab('https://totalworkplace.sharepoint.com/:f:/r/sites/Deploy-DigitalProductsAppsSupport/Shared%20Documents/E%26P/Deploy%20Cockpit/Videos%20%26%20tutos?csf=1&web=1&e=HixjyJ')">
                        Go to the Video Sharepoint folder
                        <span class="material-icons-outlined">output</span>
                    </button>
                </div>
            </div>

            <div class="grid-item" id="gdpr">
                <div class="grid-item">
                    <div class="item-content">
                        <div class="item-header">
                            <span class="material-icons-outlined">lock</span>
                            <h5>{{ "GDPR" }}</h5>
                        </div>
                        <p>
                            <b>TotalEnergies</b>, as the data controller, processes your personal data in accordance with
                            the applicable regulations. These data are collected directly by the controller. They are
                            related to professional identification data.<br>
                            <br>
                            Your personal data is processed for the purposes of accessing the services of the Deploy
                            Cockpit application. This is necessary for the implementation of legitimate interests.<br>
                            <br>
                            In order to fulfill the specified purposes above, your data is transferred to <b>Capgemini</b>,
                            in charge of the maintenance of the solution. Your data is kept for the period necessary for the processing.
                            <b>TotalEnergies</b> transfers the collected data for hosting purposes to <b>Microsoft</b>, an American
                            company that adheres to the Privacy Shield*.<br>
                            <br>
                            Comment boxes or manual entries must not contain personal data.<br>
                            <br>
                            In accordance with the regulations in force, you have the right to access, rectify, or
                            delete your personal data. You can also request the limitation or oppose the processing of
                            your data, and/or lodge a complaint with the supervisory authority. You may exercise your
                            rights and ask us about the processing of your personal data by contacting <b>ot.data-protection@totalenergies.com</b>.<br>
                            <br>
                            *Privacy Shield certification: <a href="https://www.privacyshield.gov/participant?id=a2zt0000000KzNaAAK">
                                https://www.privacyshield.gov/participant?id=a2zt0000000KzNaAAK</a><br>
                            <br>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>