import {Injectable} from "@angular/core";
import {MsalService} from "@azure/msal-angular";
import {firstValueFrom} from "rxjs";
import {UserService} from "./user.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public userIsAuthenticated: boolean = false;
  public userIsAdmin: boolean = false;
  public emailValid: boolean = false;
  public email: string;
  public username: string;
  public showAlert: boolean = false;
  public alertMessage: string = "";

  constructor(private readonly authService: MsalService,
              private readonly userService: UserService) {}

  async initializeAuthentication() {
    if (this.authService.instance.getAllAccounts()[0]) {
      const account = this.authService.instance.getAllAccounts()[0];
      this.username = account.name;
      this.email = account.username;

      this.emailValid = this.emailIsValid(this.email);

      if (!this.emailValid) {
        // Display alert before reloading
        this.alertMessage =
          "Your email is invalid. Please use @totalenergies.com or @external.totalenergies.com address. You're going to be redirected to the login page.";
        this.showAlert = true;

        setTimeout(() => {
          this.authService.instance.clearCache();
          location.reload(); // Load the page after 5 seconds
        }, 5000);

        return;
      }

      // Check if user is admin
      this.userIsAdmin = await firstValueFrom(this.userService.isAdmin(this.email));
      this.userIsAuthenticated = true;
    }
  }

  emailIsValid(email): boolean {
    // Check if email is valid (containing @totalenergies.com or @external.totalenergies.com)

    const emailRegex = /@(totalenergies\.com|external\.totalenergies\.com)$/;
    return emailRegex.test(email);
  }
}
