import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {AddProductComponent} from "../add-product/add-product.component";
import {ArrayService} from "../common/array.service";
import {branches, domainsEP, domainsRC, squadProfiles} from "../common/util/const";
import {ProductService} from "../product/product.service";
import {ProductDetailsService} from "./product-details.service";

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnChanges {
  productDetails: any = {};
  isEditMode: boolean = false;
  statusFields = [];
  viewFields = [];
  isDescriptionVisible: boolean = false;

  @Input() isWriter: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() selectedProductId!: number;
  @Input() section!: string;

  branches = branches;
  lbus = [];
  domains = [...domainsEP, ...domainsRC];
  domainsEP = domainsEP;
  domainsRC = domainsRC;
  squadProfiles = squadProfiles;
  initialSites: any;

  constructor(
    private readonly productDetailsService: ProductDetailsService,
    private readonly productService: ProductService,
    private readonly arrayService: ArrayService,
    private readonly dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedProductId?.currentValue) {
      this.isEditMode = false;
      this.loadProductDetails();
    }
  }

  loadProductDetails(): void {
    this.productDetailsService.getProductDetails(this.selectedProductId).subscribe((data) => {
      this.productDetails = data;

      this.productService.getAllSitesByBranches(this.productDetails.branches).subscribe((sites) => {
        this.initialSites = sites;
        this.lbus = [];
        sites.forEach((site) => {
          if (!this.lbus.find((lbu) => lbu.name == site.lbu.name)) {
            this.lbus.push({name: site.lbu.name, flag: `assets/flags/${site.lbu.isoCode.toLowerCase()}.svg`});
          }
        });

        const mappedLBUs = (this.productDetails?.lbus || []).map((lbu: string) => {
          const country = this.lbus.find((c) => c.name === lbu);
          return country ? {name: country.name, flag: country.flag} : {name: lbu, flag: ""};
        });

        this.statusFields = [
          {
            label: "Creation",
            formControlName: "statusCreationDate",
            value: this.productDetails?.statusCreationDate || null,
          },
          {
            label: "Transfer",
            formControlName: "statusTransferDate",
            value: this.productDetails?.statusTransferDate || null,
          },
          {
            label: "Run",
            formControlName: "statusRunDate",
            value: this.productDetails?.statusRunDate || null,
          },

          {
            label: "Decommissioning",
            formControlName: "statusDecommissioningDate",
            value: this.productDetails?.statusDecommissioningDate || null,
          },
        ];

        this.viewFields = [
          {
            label: "Branch",
            value: this.arrayService.formatArrayForDisplay(this.productDetails?.branches, "No Data"),
          },
          {
            label: "LBU",
            value: mappedLBUs.length > 0 ? mappedLBUs : "No Data",
          },
          {
            label: "Sites",
            value: this.arrayService.formatArrayForDisplay(
              this.productDetails?.sites.map((site) => site.name),
              "No Data"
            ),
          },
          {
            label: "Domain",
            value: this.getFormattedDomains(),
          },
          {
            label: "Sponsor",
            value: this.arrayService.formatArrayForDisplay(
              this.productDetails?.sponsors.map((sponsor) => sponsor.fullName),
              "No Data"
            ),
          },
          {
            label: "Product Owner",
            value: this.arrayService.formatArrayForDisplay(
              this.productDetails?.productOwners.map((owner) => owner.fullName),
              "No Data"
            ),
          },
          {
            label: "Domain/Asset Lead",
            value: this.arrayService.formatArrayForDisplay(
              this.productDetails?.domainLeads.map((domainLead) => domainLead.fullName),
              "No Data"
            ),
          },
          {
            label: "IT Scaler",
            value: this.getFormattedITScaler(),
          },
          {
            label: "Change coordinators",
            value: this.arrayService.formatArrayForDisplay(
              this.productDetails?.changeCoordinators.map((changeCoordinator) => changeCoordinator.fullName),
              "No Data"
            ),
          },
          {
            label: "Squad Profile",
            value: this.productDetails?.squadProfile || "No Data",
          },
          {
            label: "Pilot",
            value: this.getFormattedPilot(),
          },
        ];
      });
    });
  }

  openEditDialog(): void {
    const dialogRef = this.dialog.open(AddProductComponent, {
      width: "800px",
      data: {product: this.productDetails},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadProductDetails();
      }
    });
  }

  /** FORMAT DATA METHODS **/
  getFormattedITScaler(): string {
    return this.productDetails?.itScaler?.fullName ? this.productDetails.itScaler.fullName : "No Data";
  }

  getFormattedPilot(): string {
    return this.productDetails?.pilot?.name ? this.productDetails.pilot.name : "No Data";
  }

  getFormattedDomains(): string {
    if (this.productDetails?.domains == null || this.productDetails?.domains.length == 0) return "";
    return this.productDetails?.domains?.length > 1
      ? this.productDetails.domains.map((productDomain) => productDomain.name + " (" + productDomain.branch + ")").join("<br />")
      : this.productDetails.domains[0].name;
  }

  toggleDescription(): void {
    this.isDescriptionVisible = !this.isDescriptionVisible;
  }
}
