import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

export interface Site {
  id: number;
  name: string;
  branch: string;
  lbu: string;
  isoCode: string;
}

export interface RoadmapGroup {
  id: number;
  product: {id: number};
  name: string;
  type: string;
  lbu: { name: string, isoCode: string };
  startDate: string;
  endDate: string;
  comment: string;
  status: string;
  events: RoadmapEvent[];
  creationDate: string;
  fromJira: boolean;
  readyForSiteDate: string;
  firstUsageDate: string;
  goLiveDate: string;
  notificationActivation: boolean;
  notificationDate: string;
  sites: Site[];
}

export interface RoadmapEvent {
  id: number;
  roadmapGroup: {id: number};
  name: string;
  type: string;
  priority: number;
  startDate: string;
  endDate: string;
  comment: string;
  status: string;
  creationDate: string;
  notificationActivation: boolean;
  notificationDate: string;
}

export interface RoadmapRes {
  roadmapGroups: RoadmapGroup[];
  roadmapLastUpdateDate: Date;
  deploymentPlans: Array<any>;
}

export interface RoadmapGroupHistory {
  id: number;
  roadmapGroupId: number;
  modification_date: string;
  previous_start_date: string;
  previous_end_date: string;
}

export interface RoadmapEventHistory {
  id: number;
  roadmapEventId: number;
  modification_date: string;
  previous_start_date: string;
  previous_end_date: string;
}

@Injectable({
  providedIn: "root",
})
export class RoadmapService {
  private readonly baseUrl = `${environment.webProtocol}://${environment.serverUrl}/api/roadmap`;

  constructor(private readonly http: HttpClient) {}

  // Get Roadmap groups for selected product
  getRoadmapDataByProductId(productId: number): Observable<RoadmapRes> {
    return this.http.get<RoadmapRes>(`${this.baseUrl}/data/${productId}`);
  }

  // Get Roadmap groups for selected product
  getRoadmapGroupsListByProductId(productId: number): Observable<
    {
      id: number;
      name: string;
      startDate: string;
      endDate: string;
    }[]
  > {
    return this.http.get<
      {
        id: number;
        name: string;
        startDate: string;
        endDate: string;
      }[]
    >(`${this.baseUrl}/groups-list/${productId}`);
  }

  saveGroup(group: RoadmapGroup, groupId?: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    if (groupId != null) httpParams = httpParams.set("groupId", groupId);
    return this.http.post<any>(this.baseUrl + "/save-group", group, {
      params: httpParams,
    });
  }

  deleteGroup(groupId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/delete-group/${groupId}`);
  }

  saveEvent(event: RoadmapEvent, eventId?: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    if (eventId != null) httpParams = httpParams.set("eventId", eventId);
    return this.http.post<any>(this.baseUrl + "/save-event", event, {
      params: httpParams,
    });
  }

  deleteEvent(eventId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/delete-event/${eventId}`);
  }

  // Method to fetch the history of a group by ID
  getGroupHistory(groupId: number): Observable<RoadmapGroupHistory[]> {
    const url = `${this.baseUrl}/history/group/${groupId}`;
    return this.http.get<RoadmapGroupHistory[]>(url);
  }

  // Method to fetch the history of an event by ID
  getEventHistory(eventId: number): Observable<RoadmapEventHistory[]> {
    const url = `${this.baseUrl}/history/event/${eventId}`;
    return this.http.get<RoadmapEventHistory[]>(url);
  }

  extractChecklist(templateName: string): Observable<any> {
    const url = `${this.baseUrl}/extract-checklist`;

    let httpParams: HttpParams = new HttpParams().set("templateName", templateName);

    return this.http.get<any>(url, {
      params: httpParams,
      responseType: "blob" as "json",
    });
  }
}
