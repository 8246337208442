import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";
import {Editor, Toolbar} from "ngx-editor";
import {DCMentionsPlugin} from "../util/plugins";
import schema from "../util/schema";

@Component({
  selector: "app-comment",
  templateUrl: "./comment.component.html",
  styleUrls: ["./comment.component.scss"],
})
export class CommentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title: string = "Comment";
  @Input() date: Date = null;
  @Input() comment: string = "";
  @Input() isEditable: boolean = false; // Define if the user can edit the comment
  @Input() lbus: any;
  @Output() saveComment = new EventEmitter<string>();

  public editor: Editor;
  public toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["ordered_list", "bullet_list"],
    [{heading: ["h1", "h2", "h3", "h4", "h5", "h6"]}],
    ["text_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
  ];
  public isEditingComment: boolean = false;
  private previousComment: string = "";

  ngOnInit() {
    this.editor = new Editor({
      schema
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.comment) {
      this.previousComment = this.comment;
    }
    if (changes.lbus) {
      this.editor = new Editor({
        schema,
        plugins: DCMentionsPlugin.getPlugins(this.lbus),
      });
    }
  }

  ngOnDestroy(): void {
    this.editor?.destroy();
  }

  startEdit() {
    this.isEditingComment = true;
  }

  cancelEditAction() {
    this.isEditingComment = false;
    this.comment = this.previousComment;
  }

  saveCommentAction() {
    this.isEditingComment = false;
    this.previousComment = this.comment;
    this.saveComment.emit(this.comment);
  }
}
