<h2 mat-dialog-title>Select Quarter</h2>
<mat-dialog-content>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Quarter</mat-label>
    <mat-select [(value)]="selectedQuarter">
      <mat-option *ngFor="let quarter of quarters" [value]="quarter">
        {{ quarter }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="selectedQuarter" [disabled]="!selectedQuarter">Save</button>
</mat-dialog-actions>
