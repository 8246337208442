import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {SimpleComment} from "../common/comment/comment.interface";

@Injectable({
  providedIn: "root",
})
export class UsageService {
  private readonly baseUrl: string = `${environment.webProtocol}://${environment.serverUrl}/api/usage`;

  constructor(private readonly http: HttpClient) {}

  getUsersCount(productId: number, duration: string): Observable<any> {
    let params = new HttpParams().set("productId", productId).set("duration", duration);
    return this.http.get<any>(`${this.baseUrl}/users-count`, {params: params});
  }

  saveComment(productId: number, comment: string): Observable<SimpleComment> {
    return this.http.post<SimpleComment>(`${this.baseUrl}/comments`, {productId, comment});
  }

  getComment(productId: number): Observable<SimpleComment> {
    return this.http.get<SimpleComment>(`${this.baseUrl}/comments/${productId}`);
  }
}
