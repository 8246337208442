<popup-header [dialogRef]="dialogRef" icon="warning" text="Save DPC Data"></popup-header>
<div class="popup-container">
  <div class="text-container">
    <b>You are about to save the DPC data.</b><br/><br/>
    This will :<br/>
    <ul>
      <li>
        Validate the state of the products, more precisely resetting the portfolio status arrows describing the movements of products, and saving their squad profile
      </li>
      <li>
        Update the pipeline evolution graph with the current portfolio status as the historical data
      </li>
      <li>
        Send a notification to the POs if the deployment plan for their products has changed
      </li>
      <li>
        Save the all comments for this quarter, making them not editable anymore (on Deployment Progress / Team Confidence / Activity Distribution and Delivery Performance sections)
      </li>
    </ul>

    If you want to proceed, please confirm the quarter affected by the DPC you are saving :
    <div class="form-group select-quarter" style="margin-top: 12px;">
      <select class="form-select" [(ngModel)]="selectedQuarter" name="selectedQuarter">
        <option *ngFor="let option of quarterOptions" [value]="option">{{option}}</option>
      </select>
    </div>
  </div>
  <div class="buttons-container">
    <button type="button" class="btn btn-secondary" (click)="close('cancel')">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="close('confirm')" data-cypress="confirm-button">Confirm</button>
  </div>
</div>
