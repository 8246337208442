import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TableService {
  constructor() {}

  sortData(data: any[], column: string, direction: "asc" | "desc"): any[] {
    return data.sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];

      if (valueA == null || valueB == null) {
        return 0;
      }

      if (direction === "asc") {
        if (valueA > valueB) {
          return 1;
        } else if (valueA < valueB) {
          return -1;
        } else {
          return 0;
        }
      } else if (valueA < valueB) {
        return 1;
      } else if (valueA > valueB) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  sortDataWithSpecificColumnValue<T>(data: T[], column: string, direction: "asc" | "desc", getColumnValue: (item: T, column: string) => any): T[] {
    return data.slice().sort((a, b) => {
      const valueA = getColumnValue(a, column);
      const valueB = getColumnValue(b, column);

      if (valueA == null || valueA === "") return direction === "asc" ? 1 : -1;
      if (valueB == null || valueB === "") return direction === "asc" ? -1 : 1;

      if (typeof valueA === "string") return direction === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      if (valueA instanceof Date) return direction === "asc" ? valueA.getTime() - valueB.getTime() : valueB.getTime() - valueA.getTime();
      return direction === "asc" ? valueA - valueB : valueB - valueA;
    });
  }
}
