import {getMentionsPlugin} from "prosemirror-mentions";
import {Plugin} from "prosemirror-state";

export class DCMentionsPlugin {
  static getPlugins(lbus: Array<any>): Plugin[] {
    let countries;
    if (lbus == null) countries = [];
    else countries = lbus.map((obj, index) => {
      const email = "";
      return {
        id: index + 1,
        code: obj.code,
        name: obj.name,
        email,
        flag: obj.flag
      };
    });

    let mentionPlugin = getMentionsPlugin({
      getSuggestions: (type, text, done) => {
        setTimeout(() => {
          let results = [];

          if (type === "mention") {
            // Filter country list based on user input
            results = countries.filter((country) => country.name.toLowerCase().includes(text.toLowerCase())).splice(0, 5); // Limit to 5 results
          }

          if (!results.length) {
            return;
          }

          done(results);
        }, 0);
      },
      getSuggestionsHTML: (items, type) => {
        if (type === "mention") {
          // Generate HTML for the mention suggestions
          return (
            '<div class="suggestion-item-list">' +
            items
              .map(
                (item) => `<div class="suggestion-item"><img src="${item.flag}" alt="${item.code}" class="suggestion-flag" />
            <span>${item.name}</span></div>`
              )
              .join("") +
            "</div>"
          );
        }
        return null;
      },
    });

    return [mentionPlugin];
  }
}

export class DCUnifiedPlugin {
  static getPlugins(lbus: any[], products: any[]): Plugin[] {
    return [
      getMentionsPlugin({
        getSuggestions: (type, text, done) => {
          setTimeout(() => {
            let results = [];

            if (type === "mention") {
              results = lbus
                .filter((lbu) => lbu.name.toLowerCase().includes(text.toLowerCase()))
                .splice(0, 5)
                .map((lbu, index) => ({
                  id: lbu.id || index + 1,
                  name: lbu.name,
                  flag: lbu.flag,
                  email: lbu.email || ""
                }));
            } else if (type === "tag") {
              results = products
                .filter((product) => product.name.toLowerCase().includes(text.toLowerCase()))
                .splice(0, 5)
                .map((product) => ({
                  tag: product.name,
                  name: product.name
                }));
            }

            if (!results.length) {
              return;
            }

            done(results);
          }, 0);
        },

        getSuggestionsHTML: (items, type) => {
          if (type === "mention") {
            return DCUnifiedPlugin.getMentionSuggestionsHTML(items);
          } else if (type === "tag") {
            return DCUnifiedPlugin.getTagSuggestionsHTML(items);
          }
          return null;
        }
      })
    ];
  }

  static getMentionSuggestionsHTML(items) {
    return (
      '<div class="suggestion-item-list">' +
      items
        .map(
          (item) => `<div class="suggestion-item lbu"><img src="${item.flag}" alt="${item.code}" class="suggestion-flag" />
        <span>${item.name}</span></div>`
        )
        .join("") +
      "</div>"
    );
  }

  static getTagSuggestionsHTML(items) {
    return (
      '<div class="suggestion-item-list">' +
      items
        .map(
          (product) => `<div class="suggestion-item product"><span>${product.name}</span></div>`
        )
        .join("") +
      "</div>"
    );
  }
}