import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {Chart, Filler} from "chart.js";
import {ToastrService} from "ngx-toastr";
import {SharedStateService} from "../common/shared-state.service";
import {SprintMetrics, SquadService} from "./squad.service";
import {Subscription} from "rxjs";

Chart.register(Filler);

interface Comment {
  text: string;
  lastUpdate: Date;
  previousText: string;
}

@Component({
  selector: "app-squad",
  templateUrl: "./squad.component.html",
  styleUrls: ["./squad.component.scss"],
})
export class SquadComponent implements OnChanges {
  @Input() productId: number;
  @Input() productName: string;
  @Input() section!: string;
  @Input() isWriter: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() canvasId: string;
  @Input() lbus: any;

  loading: boolean = true;
  loadingRepartitionGauges: boolean = true;
  sprintMetrics: SprintMetrics[] = [];
  chart: any;
  tooltipText: string = "";
  categories: { label: string; percentage: number; color: string }[] = [];

  hasData: boolean = true;
  isEditingComment: boolean = false;
  staffingLastUpdate: Date;
  previousStaffingComment = "";
  activityLastUpdate: Date;
  activityComment: string = "";
  previousActivityComment = "";

  comments: { [key: string]: Comment } = {
    staffing: {text: "", lastUpdate: new Date(), previousText: ""},
    activity: {text: "", lastUpdate: new Date(), previousText: ""},
  };

  squadLoading: Subscription;

  constructor(private readonly squadService: SquadService,
              private readonly toastrService: ToastrService,
              private readonly sharedStateService: SharedStateService) {
    this.saveComment = this.saveComment.bind(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.productId?.currentValue) {
      this.loading = true;
      this.loadingRepartitionGauges = true;

      if (this.squadLoading) this.squadLoading.unsubscribe();

      this.sharedStateService.setLoadingStatus(`Squad-${this.canvasId}`, true);
      this.sharedStateService.setLoadingStatus(`Squad-gauges-${this.canvasId}`, true);
      this.loadComments();
      this.squadLoading = this.squadService.getLastSixSprintMetrics(this.productId).subscribe(
        (data) => {
          this.loading = false;
          this.sharedStateService.setLoadingStatus(`Squad-${this.canvasId}`, false);

          if (!data) {
            this.hasData = false;
            if (this.chart) this.chart.destroy();
          } else {
            this.hasData = true;
            this.sprintMetrics = data;
            this.staffingLastUpdate = new Date();
            this.activityLastUpdate = new Date();
            this.createChart();
            this.populateCategories();
          }
          this.squadLoading = null;
        },
        () => {
          this.loading = false;
          this.sharedStateService.setLoadingStatus(`Squad-${this.canvasId}`, false);
          this.sharedStateService.setLoadingStatus(`Squad-gauges-${this.canvasId}`, false);
          this.hasData = false;
          if (this.chart) this.chart.destroy();
          this.toastrService.error("Error when loading the squad data");
          this.squadLoading = null;
        }
      );
    }
  }

  populateCategories(): void {
    const totals = {
      cyber: 0,
      funcAdapt: 0,
      funcEvo: 0,
      run: 0,
      deploy: 0,
      tech: 0,
    };

    this.sprintMetrics.forEach((metric) => {
      totals.cyber += metric.cyber || 0;
      totals.funcAdapt += metric.funcAdapt || 0;
      totals.funcEvo += metric.funcEvo || 0;
      totals.run += metric.run || 0;
      totals.deploy += metric.deploy || 0;
      totals.tech += metric.tech || 0;
    });

    const totalGlobal = Object.values(totals).reduce((acc, val) => acc + val, 0);

    this.categories = [
      {label: "CYBER", percentage: totalGlobal ? (totals.cyber / totalGlobal) * 100 : 0, color: "#FFCC80"},
      {label: "FUNC. ADAPT", percentage: totalGlobal ? (totals.funcAdapt / totalGlobal) * 100 : 0, color: "#90CAF9"},
      {label: "FUNC. EVO", percentage: totalGlobal ? (totals.funcEvo / totalGlobal) * 100 : 0, color: "#9FA8DA"},
      {label: "RUN", percentage: totalGlobal ? (totals.run / totalGlobal) * 100 : 0, color: "#80CBC4"},
      {label: "DEPLOY", percentage: totalGlobal ? (totals.deploy / totalGlobal) * 100 : 0, color: "#CE93D8"},
      {label: "TECH", percentage: totalGlobal ? (totals.tech / totalGlobal) * 100 : 0, color: "#FFAB91"},
    ];
  }

  createChart(): void {
    if (this.chart) this.chart.destroy();

    const sprintNames = this.sprintMetrics.map((item) => item.sprintName);
    const realizedUSPoints = this.sprintMetrics.map((item) => item.realizedUS);
    const realizedBugPoints = this.sprintMetrics.map((item) => item.realizedBug);
    const estimatedPoints = this.sprintMetrics.map((item) => item.commitment);
    const compliance = this.sprintMetrics.map((item) => item.compliance);

    const legendMargin = {
      id: "legendMargin",
      beforeInit(chart) {
        let fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
          fitValue.bind(chart.legend)();
          this.height += 20;
          return this.height;
        };
      },
    };

    this.chart = new Chart(`sprintChart-${this.canvasId}`, {
      data: {
        labels: sprintNames,
        datasets: [
          {
            label: "SP Completed (US)",
            data: realizedUSPoints,
            backgroundColor: "#00838F",
            stack: "stack1",
            order: 1,
            type: "bar",
            borderWidth: realizedBugPoints.map((value) => ({
              top: value === 0 ? 0 : 2, // No border if no bugs
              right: 0,
              bottom: 0,
              left: 0,
            })),
            borderColor: "#FFF",
            borderRadius: 4,
          },
          {
            label: "SP Completed (Bugs)",
            data: realizedBugPoints,
            backgroundColor: "#80CBC4",
            stack: "stack1",
            order: 2,
            type: "bar",
            borderColor: "#FFF",
            borderRadius: 4,
          },

          {
            label: "Compliance",
            data: compliance,
            type: "line",
            borderColor: "#00838F",
            borderWidth: 2,
            borderDash: [5, 5],
            fill: false,
            tension: 0,
            pointRadius: 0,
            pointHitRadius: 4,
            yAxisID: "y1",
            order: 0,
          },
          {
            label: "SP Commitment",
            data: estimatedPoints,
            backgroundColor: "rgba(128, 203, 196, 0.3)",
            type: "line",
            fill: true,
            borderColor: "rgba(75, 192, 192, 1)",
            tension: 0,
            borderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 4,
            order: 3,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              callback: function (value, index) {
                const label = this.getLabelForValue(index);
                return label.length > 10 ? label.slice(0, 10) + "..." : label; // Shorten labels
              },
              font: {
                weight: "bold",
              },
            },
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: function (value) {
                return value + "pts";
              },
              font: {
                weight: "bold",
              },
            },
            grid: {
              display: false,
            },
          },
          y1: {
            beginAtZero: true,
            position: "right",
            ticks: {
              callback: function (value) {
                return value + "%";
              },
              font: {
                weight: "bold",
              },
            },
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            position: "top",
            align: "start",
            labels: {
              font: {
                size: 12,
                family: "Roboto, sans-serif",
                weight: "bold",
              },
              color: "var(--Primary-Colors-Body, #374649)",
              boxWidth: 16,
              boxHeight: 16,
              useBorderRadius: true,
              borderRadius: 3,
              usePointStyle: false,
              padding: 10,
            },
          },
          datalabels: {display: false},
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                const datasetLabel = tooltipItem.dataset.label;
                const value = tooltipItem.raw;

                if (datasetLabel === "Compliance") {
                  return `${datasetLabel}: ${value}%`;
                }
                if (estimatedPoints[tooltipItem.dataIndex] < realizedUSPoints[tooltipItem.dataIndex] + realizedBugPoints[tooltipItem.dataIndex]) {
                  return [`${datasetLabel}: ${value} pts`, `SP Commitment: ${estimatedPoints[tooltipItem.dataIndex]} pts`];
                }
                return `${datasetLabel}: ${value} pts`;
              },
            },
          },
        },
        animation: {
          onComplete: () => {
            this.positionGauges();
            this.loadingRepartitionGauges = false;
            this.sharedStateService.setLoadingStatus(`Squad-gauges-${this.canvasId}`, false);
          },
        },
      },
      plugins: [legendMargin],
    });
  }

  positionGauges(): void {
    if (!this.chart?.scales?.x) {
      return;
    }

    const gauges = document.querySelectorAll(`.gauge-${this.canvasId}`);
    const canvas = this.chart.canvas;

    const canvasHeight = canvas.clientHeight;
    const legendHeight = this.chart.legend ? this.chart.legend.height : 0;
    const topPosition = canvasHeight + legendHeight - 30;

    gauges.forEach((gauge, index) => {
      const datasetIndex = 0;
      const dataIndex = index;
      const bar = this.chart.getDatasetMeta(datasetIndex).data[dataIndex];

      const barX = bar.x;
      const barWidth = bar.width;

      const gaugeX = barX - barWidth / 2;

      const gaugeElement = gauge as HTMLElement;
      gaugeElement.style.position = "absolute";
      gaugeElement.style.left = `${gaugeX}px`;

      const gaugeWidth = barWidth;
      gaugeElement.style.width = `${gaugeWidth}px`;

      gaugeElement.style.top = `${topPosition}px`;
    });
  }

  editComment() {
    this.isEditingComment = true;
  }

  saveComment = (type: "staffing" | "activity", comment: string) => {
    const commentKey = this.comments[type];
    if (commentKey) {
      commentKey.text = comment;
      if (comment === "<p></p>") {
        commentKey.text = null;
      }

      const saveMethod = type === "staffing" ? this.squadService.saveStaffingComment : this.squadService.saveActivityComment;

      saveMethod(this.productId, commentKey.text).subscribe(
        () => {
          this.toastrService.success(`${type.charAt(0).toUpperCase() + type.slice(1)} comment saved successfully!`);
          this.isEditingComment = false;
        },
        () => {
          this.toastrService.error(`Error while saving the ${type} comment.`);
        }
      );
    }
  };

  loadComments() {
    this.squadService.getComment(this.productId).subscribe(
      (res) => {
        this.comments.staffing.text = res?.staffingComment || "";
        this.comments.staffing.lastUpdate = res?.staffingModificationDate || null;
        this.comments.staffing.previousText = res?.staffingComment || "";

        this.comments.activity.text = res?.activityComment || "";
        this.comments.activity.lastUpdate = res?.activityModificationDate || null;
        this.comments.activity.previousText = res?.activityComment || "";
      },
      () => {
        this.toastrService.error("Error while loading comments.");
      }
    );
  }
}
