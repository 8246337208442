import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {ArrayService} from "../common/array.service";
import {SharedEventsService} from "../common/shared-events-service";
import {TableService} from "../common/table.service";
import {ProductDTO, UserService} from "../common/user.service";
import {Product} from "../product-details/product-details.service";
import {ProductService} from "../product/product.service";

@Component({
  selector: "app-search-product",
  templateUrl: "./search-product.component.html",
  styleUrls: ["./search-product.component.scss"],
})
export class SearchProductComponent implements OnInit, OnChanges {
  @Input() searchQuery: string;

  loading: boolean = true;
  products: Product[] = [];
  filteredProducts: Product[] = [];
  lbus: Array<any> = [];
  productNameFilter: string;
  productOwnerFilter: string;
  branchFilter: string;
  lbuFilter: {name: string; flagUrl: string};
  domainFilter: string;
  squadFilter: string;
  itScalerFilter: string;

  productNameOptions: string[] = [];
  productOwnerOptions: string[] = [];
  branchOptions: string[] = [];
  lbuOptions: {name: string; flagUrl: string}[] = [];
  domainOptions: string[] = [];
  squadOptions: string[] = [];
  itScalerOptions: string[] = [];

  itemsPerPage: number = 10;
  currentPage: number = 1;
  paginatedProducts: Product[] = [];
  totalPages: number = 10;

  sortedColumn: string | null = null;
  sortDirection: "asc" | "desc" = "asc";

  constructor(
    private readonly userService: UserService,
    private readonly toastrService: ToastrService,
    private readonly sharedEventsService: SharedEventsService,
    private readonly router: Router,
    public readonly arrayService: ArrayService,
    private readonly productService: ProductService,
    private readonly tableService: TableService
  ) {}

  ngOnInit() {
    this.loadProducts();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchQuery?.currentValue) {
      this.loading = true;
      this.filterProducts();
    }
  }

  loadProducts(): void {
    this.productService.getAllLbusAvailable(null, null).subscribe((allLBUs) => {
      this.lbus = allLBUs.map((lbu) => ({
        code: lbu.isoCode,
        name: lbu.name,
        flag: `assets/flags/${lbu.isoCode.toLowerCase()}.svg`,
      }));
    });

    this.userService.getProductsForSearchProduct().subscribe(
      (data) => {
        this.loading = false;
        this.products = data;
        this.filteredProducts = [...this.products];
        this.extractUniqueOptions();
        this.updatePagination();
      },
      (error) => {
        this.toastrService.error("Error when loading the products");
      }
    );
  }

  extractUniqueOptions(): void {
    const productNames = new Set<string>();
    const productOwners = new Set<string>();
    const branches = new Set<string>();
    const lbus = new Set<string>();
    const domains = new Set<string>();
    const squads = new Set<string>();
    const itScalers = new Set<string>();

    this.products.forEach((product) => {
      if (product.name) productNames.add(product.name);
      if (product.productOwners) {
        product.productOwners.forEach((owner) => productOwners.add(owner.fullName));
      }

      if (product.branches) {
        product.branches.forEach((branch) => branches.add(branch));
      }

      if (product.lbus) {
        product.lbus.forEach((lbu) => lbus.add(lbu));
      }

      if (product.domains) product.domains.forEach((productDomain) => domains.add(productDomain.name));

      if (product.squadProfile) squads.add(product.squadProfile);

      if (product.itScaler) itScalers.add(product.itScaler.fullName);
    });

    this.productNameOptions = Array.from(productNames);
    this.productOwnerOptions = Array.from(productOwners);
    this.branchOptions = Array.from(branches);
    this.lbuOptions = Array.from(lbus).map((lbu) => ({
      name: lbu,
      flagUrl: this.getFlagUrl(lbu),
    }));
    this.domainOptions = Array.from(domains);
    this.squadOptions = Array.from(squads);
    this.itScalerOptions = Array.from(itScalers);
  }

  getFlagUrl(lbuName: string): string {
    const country = this.lbus?.find((country) => country.name.toLowerCase() === lbuName.toLowerCase());
    return country ? country.flag : "";
  }

  filterProducts(): void {
    this.filteredProducts = this.products.filter((product) => {
      return (
        (this.productNameFilter ? product.name?.toLowerCase().includes(this.productNameFilter.toLowerCase()) : true) &&
        (this.productOwnerFilter
          ? product.productOwners?.some((owner) => owner.fullName.toLowerCase().includes(this.productOwnerFilter.toLowerCase()))
          : true) &&
        (this.branchFilter ? product.branches?.some((branch) => branch.toLowerCase().includes(this.branchFilter.toLowerCase())) : true) &&
        (this.lbuFilter ? product.lbus?.some((lbu) => lbu.toLowerCase().includes(this.lbuFilter.name.toLowerCase())) : true) &&
        (this.domainFilter ? product.domains.some((domain) => domain.name.toLowerCase() == this.domainFilter.toLowerCase()) : true) &&
        (this.squadFilter ? product.squadProfile?.toLowerCase().includes(this.squadFilter.toLowerCase()) : true) &&
        (this.itScalerFilter ? product.itScaler?.fullName.toLowerCase().includes(this.itScalerFilter.toLowerCase()) : true)
      );
    });
    this.updatePagination();
  }

  resetFilters(): void {
    this.productNameFilter = null;
    this.productOwnerFilter = null;
    this.branchFilter = null;
    this.lbuFilter = null;
    this.domainFilter = null;
    this.squadFilter = null;
    this.itScalerFilter = null;
    this.searchQuery = null;
    this.filteredProducts = [...this.products];

    this.updatePagination();
  }

  get upperLimit(): number {
    return Math.min(this.currentPage * this.itemsPerPage, this.filteredProducts.length);
  }

  get lowerLimit(): number {
    return (this.currentPage - 1) * this.itemsPerPage + 1;
  }

  updatePagination(): void {
    this.totalPages = Math.ceil(this.filteredProducts.length / this.itemsPerPage);
    this.currentPage = 1;
    this.paginateProducts();
  }

  paginateProducts(): void {
    const start = (Number(this.currentPage) - 1) * Number(this.itemsPerPage);
    const end = start + Number(this.itemsPerPage);
    this.paginatedProducts = this.filteredProducts.slice(start, end);
  }

  changePage(page: number): void {
    if (page < 1 || page > this.totalPages) return;
    this.currentPage = page;
    this.paginateProducts();
  }

  addToFavorites(productId: number) {
    this.userService.addFavorite(productId).subscribe(
      (response) => {
        this.toastrService.success("Product added to favorites.");
        this.loadProducts();
        this.sharedEventsService.notifyReloadHeader();
      },
      (error) => {
        this.toastrService.error("Failed to add product to favorites.");
      }
    );
  }

  removeFromFavorites(productId: number) {
    this.userService.removeFavorite(productId).subscribe(
      (response) => {
        this.toastrService.success("Product removed from favorites.");
        this.loadProducts();
        this.sharedEventsService.notifyReloadHeader();
      },
      (error) => {
        this.toastrService.error("Failed to remove product from favorites.");
      }
    );
  }

  toggleFavorite(productId: number, isFavorite: boolean) {
    if (isFavorite) {
      this.removeFromFavorites(productId);
    } else {
      this.addToFavorites(productId);
    }
  }

  redirectToProduct(product: Product): void {
    const productDto: ProductDTO = {
      id: product.id,
      name: product.name,
      logo: product.logo,
      branches: product.branches.map((branch) => {
        return {branchName: branch};
      }),
    };
    this.sharedEventsService.setSelectedProduct(productDto);
    this.router.navigate(["/product", product.name, product.id]);
  }

  getFormattedDomains(domains): string {
    if (domains == null || domains.length == 0) return "";
    return domains.length > 1
      ? domains.map((productDomain) => productDomain.name + " (" + productDomain.branch + ")").join("<br />")
      : domains[0].name;
  }

  onSort(column: string): void {
    this.sortDirection = this.sortedColumn === column ? (this.sortDirection === "asc" ? "desc" : "asc") : "asc";
    this.sortedColumn = column;

    this.filteredProducts = this.tableService.sortDataWithSpecificColumnValue(this.filteredProducts, column, this.sortDirection, (product, column) =>
      this.getColumnValue(product, column)
    );

    this.updatePagination();
  }

  getColumnValue(product: Product, column: string): any {
    switch (column) {
      case "productName":
        return product.name;
      case "productOwner":
        return product.productOwners?.[0]?.fullName || "";
      case "branch":
        return product.branches?.[0] || "";
      case "domain":
        return product.domains?.[0]?.name || "";
      case "squadProfile":
        return product.squadProfile;
      case "itScaler":
        return product.itScaler?.fullName || "";
      case "favorites":
        return product.favorite ? 1 : 0;
      default:
        return "";
    }
  }
}
