import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {PillarCommentService} from "./pillar-comment.service";

@Component({
  selector: "pillar-comment",
  templateUrl: "./pillar-comment.component.html",
  styleUrls: ["./pillar-comment.component.scss"],
})
export class PillarCommentComponent implements OnInit {
  title: string;
  comment: string;
  selectedStatus: string;
  errorMessage: string;

  saveInProgress: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PillarCommentComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly toastrService: ToastrService,
    private readonly pillarCommentService: PillarCommentService
  ) {}

  ngOnInit() {
    this.title = this.data.pillarCardComment == null ? "Write a comment" : "Edit a comment";
    this.comment = this.data.pillarCardComment?.comment;
    this.selectedStatus = this.data.pillarCardComment?.status;
  }

  closePopup() {
    this.dialogRef.close();
  }

  saveComment() {
    if (this.comment == null || this.comment == "") this.errorMessage = "Comment field is empty.";
    else if (this.selectedStatus == null || this.selectedStatus == "") this.errorMessage = "No status have been selected.";
    else {
      this.errorMessage = null;

      this.saveInProgress = true;

      // Save
      this.pillarCommentService
        .savePillarComment(this.data.pillarCardComment?.id, this.comment, this.selectedStatus, this.data.pillarCardId)
        .subscribe(
          () => {
            this.saveInProgress = false;
            this.toastrService.success("Comment has been saved !");
            this.dialogRef.close("success");
          },
          () => {
            this.saveInProgress = false;
            this.toastrService.error("An error occurred while saving the comment.");
          }
        );
    }
  }
}
