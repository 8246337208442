import {schema as baseSchema, marks} from "ngx-editor/schema";
import {addMentionNodes, addTagNodes} from "prosemirror-mentions";
import {Schema} from "prosemirror-model";

// Add mention and tag nodes to the schema
const schema = new Schema({
  nodes: addTagNodes(addMentionNodes(baseSchema.spec.nodes)),
  marks,
});

export default schema;
