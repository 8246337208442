import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ToolboxService} from "../toolbox.service";
import {ToastrService} from "ngx-toastr";
import {DndDropEvent} from "ngx-drag-drop";

@Component({
  selector: "add-toolbox-item",
  templateUrl: "./add-toolbox-item.component.html",
  styleUrls: ["./add-toolbox-item.component.scss"],
})
export class AddToolboxItemComponent implements OnInit {
  popupTitle: string;

  title: string;

  link: string;
  document: any;

  errorMessage: string;

  predefinedItemName: string = "0";

  selectedSegment: number = 0;

  saveInProgress: boolean = false;

  availablePredefinedItems: Array<string>;
  availablePredefinedLinks: Array<string> = ["Jira", "Confluence", "Sharepoint", "Teams", "Dev", "Q/A", "Prod"];
  availablePredefinedDocuments: Array<string> = ["One Pager", "Key Users/Users list"];

  type: string;

  constructor(public dialogRef: MatDialogRef<AddToolboxItemComponent>,
              @Inject(MAT_DIALOG_DATA) private readonly data: any,
              private readonly toolboxService: ToolboxService,
              private readonly toastrService: ToastrService) {
    this.type = this.data.type;
  }

  ngOnInit() {
    this.popupTitle = this.type == "link" ? 'Add a link' : "Add a document";

    if (this.data.alreadyExistingPredefinedItems) {
      if (this.type == "link") this.availablePredefinedItems = this.availablePredefinedLinks.filter((link) => this.data.alreadyExistingPredefinedItems.indexOf(link) == -1);
      else this.availablePredefinedItems = this.availablePredefinedDocuments.filter((link) => this.data.alreadyExistingPredefinedItems.indexOf(link) == -1);

      if (this.availablePredefinedItems.length == 0) this.selectedSegment = 1;
    }
  }

  closePopup() {
    this.dialogRef.close();
  }

  saveLink() {
    if (this.selectedSegment == 0 && (this.predefinedItemName == null || this.predefinedItemName == "" || this.predefinedItemName == "0")) this.errorMessage = this.capitalizeFirstLetter(this.type) + " name is empty.";
    else if (this.selectedSegment == 1 && (this.title == null || this.title == "")) this.errorMessage = this.capitalizeFirstLetter(this.type) + " name is empty.";
    else if (this.type == "link" && (this.link == null || this.link == "")) this.errorMessage = "Link field is empty.";
    else if (this.type == "document" && (this.document == null || this.link == "")) this.errorMessage = "Link field is empty.";
    else {
      this.saveInProgress = true;

      if (this.type == "link") {
        this.toolboxService.saveLink(this.data.productId, this.selectedSegment == 0 ? this.predefinedItemName : this.title, this.link).subscribe(() => {
          this.saveInProgress = false;
          this.toastrService.success("Link has been saved !");
          this.dialogRef.close("success");
        }, () => {
          this.saveInProgress = false;
          this.toastrService.error("An error occurred while saving the link.");
        });
      } else {
        this.toolboxService.saveDocument(this.data.productId, this.selectedSegment == 0 ? this.predefinedItemName : this.title, this.document).subscribe(() => {
          this.saveInProgress = false;
          this.toastrService.success("Document has been saved !");
          this.dialogRef.close("success");
        }, () => {
          this.saveInProgress = false;
          this.toastrService.error("An error occurred while saving the document.");
        });
      }
    }
  }

  capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  treatFile(file) {
    this.document = null;
    if (file.size > 100000000) {
      this.errorMessage = "Uploaded file is too large (> 100MB)."
    } else this.document = file;
  }

  getFile($event) {
    this.treatFile($event.target.files[0]);
  }

  onDrop(event: DndDropEvent) {
    this.treatFile(event.event.dataTransfer.files[0]);
  }
}
