import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "confirm-save-dpc-data",
  templateUrl: "./confirm-save-dpc-data.component.html",
  styleUrls: ["./confirm-save-dpc-data.component.scss"],
})
export class ConfirmSaveDpcDataComponent implements OnInit {
  quarterOptions: Array<string> = [];

  selectedQuarter: string;

  constructor(public dialogRef: MatDialogRef<ConfirmSaveDpcDataComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.quarterOptions = this.data.quartersToSave;
  }

  ngOnInit() {
    this.selectedQuarter = this.quarterOptions[0];
  }

  close(result: string) {
    this.dialogRef.close([result,
      {quarter: this.selectedQuarter.split("Q")[0], year: this.selectedQuarter.split("Q")[1]}]);
  }
}

