import {BrowserCacheLocation, IPublicClientApplication, LogLevel, PublicClientApplication} from "@azure/msal-browser";
import {environment} from "src/environments/environment";

export const msalInstanceFactory = (): IPublicClientApplication =>
  new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: "https://login.microsoftonline.com/" + environment.tenantId,
      redirectUri: "/",
      postLogoutRedirectUri: "/login"
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
