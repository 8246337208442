<div class="filters no-export">
  <div class="form-group">
    <label class="form-label" for="statusFilter">Status</label>
    <ng-select
      [items]="statusOptions"
      bindLabel="status"
      [(ngModel)]="statusFilter"
      [searchable]="false"
      placeholder="All"
      (change)="filterActionPlans()">
    </ng-select>
  </div>

  <div class="form-group">
    <label class="form-label" for="typeFilter">Type</label>
    <ng-select [items]="typeOptions" bindLabel="type" [(ngModel)]="typeFilter" [searchable]="false" placeholder="All" (change)="filterActionPlans()">
    </ng-select>
  </div>

  <div class="form-group">
    <label class="form-label" for="lbuFilter">LBU</label>
    <ng-select
      [items]="lbuOptions"
      bindLabel="name"
      bindValue="name"
      [(ngModel)]="lbuFilter"
      [searchable]="true"
      placeholder="All"
      (change)="filterActionPlans()"
      id="lbu-select">
      <ng-template ng-option-tmp let-item="item">
        <div class="lbu-option">
          <img *ngIf="item.flag" [src]="item.flag" alt="{{ item.name }}" class="flag-icon" />
          <span>{{ item.name }}</span>
        </div>
      </ng-template>
    </ng-select>
  </div>

  <div class="form-group" *ngIf="isProductPage">
    <label class="form-label" for="pillarFilter">Pillar</label>
    <ng-select
      [items]="pillarOptions"
      bindLabel="pillar"
      [(ngModel)]="pillarFilter"
      [searchable]="false"
      placeholder="All"
      (change)="filterActionPlans()">
    </ng-select>
  </div>

  <div class="form-group" *ngIf="isDpcPage">
    <label class="form-label" for="productFilter">Product</label>
    <ng-select
      [items]="productOptions"
      bindLabel="product"
      [(ngModel)]="productFilter"
      [searchable]="false"
      placeholder="All"
      (change)="filterActionPlans()">
    </ng-select>
  </div>

  <div class="form-group">
    <label class="form-label" for="responsibleFilter">Responsible</label>
    <ng-select
      [items]="responsibleOptions"
      bindLabel="responsible"
      [(ngModel)]="responsibleFilter"
      [searchable]="true"
      placeholder="All"
      (change)="filterActionPlans()">
    </ng-select>
  </div>

  <div class="form-group">
    <label class="form-label" for="deadlineFilter">Deadline</label>
    <input class="form-control" type="date" [(ngModel)]="deadlineFilter" (change)="filterActionPlans()" placeholder="Filter by deadline" />
    <mat-datepicker #picker></mat-datepicker>
  </div>

  <button data-cypress="reset-filter" class="btn btn-link" (click)="resetFilters()" matTooltip="Reset the filters">
    <span class="material-icons" style="color: red">filter_list_off</span>
  </button>
</div>

<div class="table-container" id="action-plan-table-container">
  <table class="table" id="action-plan-table">
    <caption>
      Action Plan
    </caption>
    <thead>
      <tr>
        <th scope="col" class="sortable-column" (click)="onSort('status')" (keydown)="onSort('status')">
          <div class="column-header">
            Status
            <div class="sort-icons">
              <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'status' && sortDirection === 'asc'}"> expand_less </span>
              <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'status' && sortDirection === 'desc'}">
                expand_more
              </span>
            </div>
          </div>
        </th>
        <th scope="col" class="sortable-column" (click)="onSort('type')" (keydown)="onSort('type')">
          <div class="column-header">
            Type
            <div class="sort-icons">
              <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'type' && sortDirection === 'asc'}"> expand_less </span>
              <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'type' && sortDirection === 'desc'}"> expand_more </span>
            </div>
          </div>
        </th>
        <th scope="col" *ngIf="isDpcPage" class="sortable-column" (click)="onSort('dpcDate')" (keydown)="onSort('dpcDate')">
          <div class="column-header">
            DPC Date
            <div class="sort-icons">
              <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'dpcDate' && sortDirection === 'asc'}">
                expand_less
              </span>
              <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'dpcDate' && sortDirection === 'desc'}">
                expand_more
              </span>
            </div>
          </div>
        </th>
        <th scope="col" *ngIf="isProductPage">Action / Decision / Info</th>
        <th scope="col" *ngIf="isProductPage" class="sortable-column" (click)="onSort('creationDate')" (keydown)="onSort('creationDate')">
          <div class="column-header">
            Creation Date
            <div class="sort-icons">
              <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'creationDate' && sortDirection === 'asc'}">
                expand_less
              </span>
              <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'creationDate' && sortDirection === 'desc'}">
                expand_more
              </span>
            </div>
          </div>
        </th>
        <th scope="col" *ngIf="isProductPage">Comments & LBU <span class="lbu-tag-title">(&#64; to tag an lbu)</span></th>
        <th scope="col" *ngIf="isProductPage" class="sortable-column" (click)="onSort('pillarName')" (keydown)="onSort('pillarName')">
          <div class="column-header">
            Pillars
            <div class="sort-icons">
              <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'pillarName' && sortDirection === 'asc'}">
                expand_less
              </span>
              <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'pillarName' && sortDirection === 'desc'}">
                expand_more
              </span>
            </div>
          </div>
        </th>
        <th scope="col" *ngIf="isDpcPage">Comment <span class="lbu-tag-title">(&#64; to tag an lbu and &#35; to tag a product)</span></th>
        <th scope="col" class="sortable-column" (click)="onSort('responsible')" (keydown)="onSort('responsible')">
          <div class="column-header">
            Responsible
            <div class="sort-icons">
              <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'responsible' && sortDirection === 'asc'}">
                expand_less
              </span>
              <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'responsible' && sortDirection === 'desc'}">
                expand_more
              </span>
            </div>
          </div>
        </th>
        <th scope="col" class="sortable-column" (click)="onSort('deadline')" (keydown)="onSort('deadline')">
          <div class="column-header">
            Deadline
            <div class="sort-icons">
              <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'deadline' && sortDirection === 'asc'}">
                expand_less
              </span>
              <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'deadline' && sortDirection === 'desc'}">
                expand_more
              </span>
            </div>
          </div>
        </th>

        <th scope="col" *ngIf="isWriter || isAdmin" class="no-export"></th>
      </tr>
    </thead>
    <tbody *ngIf="isProductPage">
      <tr *ngFor="let actionPlan of filteredProductActionPlans" [attr.data-cypress]="'action-row-' + actionPlan.title + '-' + actionPlan.type">
        <td class="status-icon">
          <span
            class="material-icons-outlined status clickable"
            data-cypress="status_icon_clickable"
            [ngStyle]="{color: getStatusColor(actionPlan.status)}"
            [title]="actionPlan.status"
            [matTooltip]="actionPlan.status"
            [matMenuTriggerFor]="menuStatus"
            *ngIf="actionPlan.status !== 'Info' && actionPlan.status !== 'Decision' && actionPlan.status !== 'Cancelled'">
            {{ getStatusIcon(actionPlan.status) }}
          </span>
          <span
            class="material-icons-outlined status non-clickable"
            [ngStyle]="{color: getStatusColor(actionPlan.status)}"
            [title]="actionPlan.status"
            [matTooltip]="actionPlan.status"
            *ngIf="actionPlan.status === 'Info' || actionPlan.status === 'Cancelled'">
            {{ getStatusIcon(actionPlan.status) }}
          </span>
          <img
            class="material-icons-outlined status non-clickable"
            [ngStyle]="{color: getStatusColor(actionPlan.status)}"
            [title]="actionPlan.status"
            [matTooltip]="actionPlan.status"
            *ngIf="actionPlan.status === 'Decision'"
            alt="Decision"
            src="../../assets/decision.svg" />

          <!-- Menu to Select New Status -->
          <mat-menu [hidden]="!isWriter && !isAdmin" #menuStatus="matMenu" class="menu-status" xPosition="after" yPosition="below">
            <!-- Rechange to take away decision and info -->
            <ng-container *ngIf="isWriter || isAdmin">
              <button
                mat-menu-item
                *ngFor="let status of ['To do', 'In progress', 'Blocked', 'Done']"
                [ngClass]="{selected: actionPlan.status === status}"
                (click)="changeActionPlanStatus(actionPlan, status)"
                [matTooltip]="status"
                matTooltipPosition="right">
                <span class="material-icons-outlined status-icon" [ngStyle]="{color: getStatusColor(status)}">
                  {{ getStatusIcon(status) }}
                </span>
              </button>
            </ng-container>
          </mat-menu>
        </td>
        <td>{{ actionPlan.type }}</td>
        <td class="title-cell">{{ actionPlan.title }}</td>
        <td>
          {{ actionPlan.committee != "No committee" && actionPlan.committee != null ? actionPlan.committee + " : " : ""
          }}{{ formatDate(actionPlan.creationDate) }}
        </td>
        <td data-cypress="comment-cell" [innerHTML]="actionPlan.comment | safeHtml"></td>
        <td>{{ actionPlan.pillarName }}</td>
        <td>{{ actionPlan.responsible.fullName }}</td>
        <td>{{ formatDate(actionPlan.deadline) }}</td>
        <td *ngIf="isWriter || isAdmin" class="no-export">
          <button *ngIf="!actionPlan.isFromDpcAction" mat-icon-button [matMenuTriggerFor]="menuAction" (click)="setCurrentAction(actionPlan)">
            <span class="material-icons">more_horiz</span>
          </button>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="isDpcPage">
      <tr *ngFor="let dpcActionPlan of filteredDpcActionPlans">
        <td class="status-icon">
          <span
            class="material-icons-outlined status clickable"
            data-cypress="status_icon_clickable"
            [ngStyle]="{color: getStatusColor(dpcActionPlan.status)}"
            [title]="dpcActionPlan.status"
            [matTooltip]="dpcActionPlan.status"
            [matMenuTriggerFor]="menuStatus"
            *ngIf="dpcActionPlan.status !== 'Info' && dpcActionPlan.status !== 'Decision' && dpcActionPlan.status !== 'Cancelled'">
            {{ getStatusIcon(dpcActionPlan.status) }}
          </span>
          <span
            class="material-icons-outlined status non-clickable"
            [ngStyle]="{color: getStatusColor(dpcActionPlan.status)}"
            [title]="dpcActionPlan.status"
            [matTooltip]="dpcActionPlan.status"
            *ngIf="dpcActionPlan.status === 'Info' || dpcActionPlan.status === 'Cancelled'">
            {{ getStatusIcon(dpcActionPlan.status) }}
          </span>
          <img
            class="material-icons-outlined status non-clickable"
            [ngStyle]="{color: getStatusColor(dpcActionPlan.status)}"
            [title]="dpcActionPlan.status"
            [matTooltip]="dpcActionPlan.status"
            *ngIf="dpcActionPlan.status === 'Decision'"
            alt="Decision"
            src="../../assets/decision.svg">

          <!-- Menu to Select New Status -->
          <mat-menu [hidden]="!isWriter && !isAdmin" #menuStatus="matMenu" class="menu-status" xPosition="after" yPosition="below">
            <!-- Rechange to take away decision and info -->
            <ng-container *ngIf="isWriter || isAdmin">
              <button
                mat-menu-item
                *ngFor="let status of ['To do', 'In progress', 'Blocked', 'Done']"
                [ngClass]="{selected: dpcActionPlan.status === status}"
                (click)="changeActionPlanStatus(dpcActionPlan, status)"
                [matTooltip]="status"
                matTooltipPosition="right">
                <span class="material-icons-outlined status-icon" [ngStyle]="{color: getStatusColor(status)}">
                  {{ getStatusIcon(status) }}
                </span>
              </button>
            </ng-container>
          </mat-menu>
        </td>
        <td data-cypress="type_column">{{ dpcActionPlan.type }}</td>
        <td>{{ formatDate(dpcActionPlan.dpcDate) }}</td>
        <td data-cypress="comment-cell" [innerHTML]="dpcActionPlan.comment | safeHtml"></td>
        <td>{{ dpcActionPlan.responsible.fullName }}</td>
        <td>{{ formatDate(dpcActionPlan.deadline) }}</td>
        <td *ngIf="isWriter || isAdmin" class="no-export">
          <button mat-icon-button [matMenuTriggerFor]="menuAction" (click)="setCurrentAction(dpcActionPlan)">
            <span class="material-icons">more_horiz</span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<mat-menu #menuAction="matMenu">
  <button mat-menu-item (click)="editAction(currentAction.id, motherPage)">Edit Action</button>
  <button mat-menu-item (click)="deleteAction(currentAction.id)">Delete Action</button>
</mat-menu>
