<div class="deploy-it-activity-distribution-graph-container">
  <h4 class="graph-title">Activity per Quarter (%)</h4>

  <!-- Toggle Buttons -->
  <div class="toggle-buttons" *ngIf="branch === 'EP'">
    <div class="segmented-control-container">
      <button class="segmented-control-item" [ngClass]="{selected: domain === 'DrillX'}" (click)="selectDomain('DrillX')">DrillX</button>
      <button class="segmented-control-item" [ngClass]="{selected: domain === 'AiO'}" (click)="selectDomain('AiO')">AiO</button>
    </div>
  </div>

  <!-- Chart -->
  <div class="activity-per-quarter-container">
    <div class="spinner-container">
      <div *ngIf="loading" class="spinner-border text-primary" output="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="chart-container" [style]="!loading && hasData ? 'height: 35vh' : 'display:none'">
      <canvas [id]="canvasId"></canvas>
    </div>
    <!-- Comment Section -->
    <div class="comment-section" [style]="hasData && !loading ? 'margin-top: 32px;' : ''">
      <app-comment
        [title]="getCommentTitle()"
        [date]="getActivityPerQuarterComment()?.postedOn"
        [comment]="getActivityPerQuarterComment()?.comment || ''"
        [isEditable]="isWriter"
        [lbus]="lbus"
        (saveComment)="saveComment($event)">
      </app-comment>
    </div>
  </div>

  <!-- Deployment Information -->
  <div class="quarter-infos-container" *ngIf="!loading && hasData">
    <div class="activity-infos-container" *ngFor="let quarter of quarters">
      <span
        ><strong>{{ quarter }}:</strong></span
      >
      <ng-container *ngIf="domain === 'DrillX' && branch === 'EP'">
        <span><strong>Number of deployments: </strong>{{ deployItActivityDistributionData[quarter]?.nbr_deployment_drillx }}</span>
        <span *ngIf="isQuarterInPastOrCurrent(quarter)">
          <strong>New products: </strong>{{ deployItActivityDistributionData[quarter]?.new_products_drillx?.join(" - ") || 0 }}</span
        >
        <span *ngIf="isQuarterInPastOrCurrent(quarter)">
          <strong>Number of products in multiproduct squads: </strong>
          {{ deployItActivityDistributionData[quarter]?.multi_product_drillx.length }}
          <span *ngIf="deployItActivityDistributionData[quarter]?.multi_product_drillx.length > 0">
            ({{ deployItActivityDistributionData[quarter]?.multi_product_drillx.join(" - ") }})
          </span>
        </span>
      </ng-container>
      <ng-container *ngIf="domain === 'AiO' || branch === 'RC'">
        <span><strong>Number of deployments: </strong>{{ deployItActivityDistributionData[quarter]?.nbr_deployment_aio }}</span>
        <span *ngIf="isQuarterInPastOrCurrent(quarter)">
          <strong>New products: </strong>{{ deployItActivityDistributionData[quarter]?.new_products_aio?.join(" - ") || 0 }}</span
        >
        <span *ngIf="isQuarterInPastOrCurrent(quarter)">
          <strong>Number of products in multiproduct squads: </strong>
          {{ deployItActivityDistributionData[quarter]?.multi_product_aio.length }}
          <span *ngIf="deployItActivityDistributionData[quarter]?.multi_product_aio.length > 0">
            ({{ deployItActivityDistributionData[quarter]?.multi_product_aio.join(" - ") }})
          </span>
        </span>
      </ng-container>
    </div>
  </div>
</div>
