<div class="accordion">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" [id]="'accordion-btn-header-' + section">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#accordion-btn-collapse-' + section"
        aria-expanded="true"
        [attr.aria-controls]="'accordion-btn-collapse-' + section">
        <div class="container-left-accordion-title">
          <span class="material-icons-outlined color_red" aria-hidden="true">rocket_launch</span>
          Deploy IT - Activity Distribution
        </div>
      </button>
    </h4>

    <div
      [id]="'accordion-btn-collapse-' + section"
      class="accordion-collapse collapse show"
      aria-labelledby="accordion-btn-header-deploy-it-activity-distribution">
      <div [class]="'accordion-body d-flex ' + section + '-container'">
        <div class="deploy-it-activity-distribution-container">
          <div class="legend-container">
            <div class="legend-item-container" *ngFor="let category of categories">
              <span class="legend-color" [style.background-color]="getCategoryColor(category)"></span>
              <span>{{ category.name }}</span>
            </div>
          </div>

          <!-- Activity per Quarter Section -->
          <deploy-it-activity-distribution-per-quarter
            id="{{ branch === 'EP' ? 'visible-component' : '' }}"
            [domain]="selectedDomain"
            [branch]="branch"
            [deployItActivityDistributionData]="deployItActivityDistributionData"
            [activityPerQuarterComment]="activityPerQuarterComment"
            [isWriter]="isWriter"
            [lbus]="lbus"
            [canvasId]="'meanActivityDistributionChart'">
          </deploy-it-activity-distribution-per-quarter>

          <div id="hidden-component" style="visibility: hidden; height: 0" *ngIf="showHiddenComponent && branch === 'EP'">
            <deploy-it-activity-distribution-per-quarter
              [domain]="'DrillX'"
              [branch]="branch"
              [deployItActivityDistributionData]="deployItActivityDistributionData"
              [activityPerQuarterComment]="activityPerQuarterComment"
              [isWriter]="isWriter"
              [lbus]="lbus"
              [canvasId]="'meanActivityDistributionChart-DrillX'">
            </deploy-it-activity-distribution-per-quarter>
            <deploy-it-activity-distribution-per-quarter
              [domain]="'AiO'"
              [branch]="branch"
              [deployItActivityDistributionData]="deployItActivityDistributionData"
              [activityPerQuarterComment]="activityPerQuarterComment"
              [isWriter]="isWriter"
              [lbus]="lbus"
              [canvasId]="'meanActivityDistributionChart-AiO'">
            </deploy-it-activity-distribution-per-quarter>
          </div>

          <!-- Activity per Product Section -->
          <div class="deploy-it-activity-distribution-graph-container">
            <h4 class="graph-title">Activity per Product (%)</h4>
            <div class="toggle-buttons no-export">
              <div class="segmented-control-container">
                <button
                  *ngFor="let quarter of quarters"
                  class="segmented-control-item"
                  [ngClass]="{selected: selectedQuarter === quarter}"
                  (click)="selectQuarter(quarter)"
                  [disabled]="!isQuarterInPastOrCurrent(quarter)">
                  {{ quarter }}
                </button>
              </div>
            </div>
            <div class="activity-per-product-container">
              <div class="spinner-container">
                <div *ngIf="loading" class="spinner-border text-primary" output="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <div class="chart-container" [style]="!loading && hasData ? 'height: 35vh;' : 'display:none;'">
                <button
                  class="btn btn-outline-primary btn-circle position-relative nav-graph-button no-export"
                  (click)="navigateLeft()"
                  [ngClass]="isMinDisabled ? 'disabled' : ''"
                  [disabled]="isMinDisabled">
                  <span class="material-icons-outlined" style="transform: rotate(90deg)">expand_circle_down</span>
                </button>
                <canvas
                  class="activity-distribution-chart-by-quarter"
                  id="activityDistributionChart-{{ selectedQuarter }}"
                  style="max-width: 93%"></canvas>
                <button
                  class="btn btn-outline-primary btn-circle position-relative nav-graph-button no-export"
                  [ngClass]="isMaxDisabled ? 'disabled' : ''"
                  (click)="navigateRight()"
                  [disabled]="isMaxDisabled">
                  <span class="material-icons-outlined" style="transform: rotate(-90deg)">expand_circle_down</span>
                </button>
              </div>
              <div class="comment-section" [style]="hasData && !loading ? 'margin-top: 32px;' : ''">
                <app-comment
                  [title]="'Activity per Product Comment'"
                  [date]="getActivityPerProductComment()?.postedOn"
                  [comment]="getActivityPerProductComment()?.comment || ''"
                  [isEditable]="isWriter"
                  [lbus]="lbus"
                  (saveComment)="saveComment($event)">
                </app-comment>
              </div>
            </div>
            <div *ngIf="!hasData && !loading" style="font-style: italic; margin-top: 20px">No Data (there must have been an issue).</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
