import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {UserDTO} from "../common/user.service";

export interface ActionPlan {
  id: number;
  status: string;
  type: string;
  title: string;
  creationDate: Date;
  comment: string;
  pillarName: string;
  responsible: UserDTO;
  deadline: Date;
  pillarCard: {id: number};
  committee: string;
}

export interface DPCActionPlan {
  id: number;
  status: string;
  type: string;
  dpcDate: Date;
  comment: string;
  responsible: UserDTO;
  deadline: Date;
  lastUpdateDate: Date;
}

export interface ActionPlanRes {
  actionPlans: ActionPlan[];
  actionPlanLastUpdateDate: Date;
}

export interface DPCActionPlanRes {
  dpcActionPlans: DPCActionPlan[];
  dpcActionPlanLastUpdateDate: Date;
}

@Injectable({
  providedIn: "root",
})
export class ActionPlanService {
  private readonly apiUrl = `${environment.webProtocol}://${environment.serverUrl}/api/action-plan`;
  private readonly apiUrlDpc = `${environment.webProtocol}://${environment.serverUrl}/api/dpc-action-plan`;


  constructor(private readonly http: HttpClient) {}

  /* Product Action Plan */
  getActionPlansByProductId(productId: number): Observable<ActionPlanRes> {
    return this.http.get<ActionPlanRes>(`${this.apiUrl}/${productId}`);
  }

  createActionPlan(actionData: ActionPlan): Observable<ActionPlan> {
    return this.http.post<ActionPlan>(`${this.apiUrl}`, actionData);
  }

  updateActionPlan(actionId: number, actionData: ActionPlan): Observable<ActionPlan> {
    return this.http.post<ActionPlan>(`${this.apiUrl}/update/${actionId}`, actionData);
  }

  deleteActionPlan(actionId: number): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}/delete/${actionId}`);
  }

  changeActionPlanStatus(actionPlanId: number, status: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/change-action-plan-status", {
      params: {
        actionPlanId: actionPlanId,
        status: status,
      },
    });
  }

  /* DPC Action Plan */
  getActionPlanByBranch(branch: string): Observable<DPCActionPlanRes> {
    return this.http.get<DPCActionPlanRes>(`${this.apiUrlDpc}/${branch}`);
  }

  createDpcActionPlan(dpcActionData: DPCActionPlan): Observable<DPCActionPlan> {
    return this.http.post<DPCActionPlan>(`${this.apiUrlDpc}`, dpcActionData);
  }

  updateDpcActionPlan(actionId: number, dpcActionData: DPCActionPlan): Observable<DPCActionPlan> {
    return this.http.post<DPCActionPlan>(`${this.apiUrlDpc}/update/${actionId}`, dpcActionData);
  }

  deleteDpcActionPlan(actionId: number): Observable<void> {
    return this.http.get<void>(`${this.apiUrlDpc}/delete/${actionId}`);
  }

  changeDpcActionPlanStatus(id: number, status: string): Observable<any> {
    return this.http.get<any>(this.apiUrlDpc + "/change-action-plan-status", {
      params: {
        id: id,
        status: status,
      },
    });
  }
}
