<div *ngIf="loading" class="loading-overlay no-export-loader">
  <div class="spinner"></div>
</div>

<nav class="navbar navbar-expand-sm flex-column">
  <!-- Accessibility link -->
  <div class="container-fluid d-flex w-100 header-with-tabs-for-desktop">
    <!-- TotalEnergies Logo -->
    <a class="navbar-brand text-primary" href="#">
      <img src="assets/logo_deploy_cockpit.svg" alt="" />
    </a>
    <div class="w-100">
      <div class="container-fluid d-flex pt-2 pe-0">
        <!-- Application Name -->
        <span class="navbar-app-name d-flex align-items-center">Digital Cockpit</span>
        <!-- Navbar content -->
        <ul class="navbar-nav ms-auto d-flex flex-row no-export">
          <li class="nav-item">
            <button class="btn btn-tertiary" id="help-btn" [routerLink]="['/help']">Help & Documentation</button>
            <button
              class="btn btn-outline-primary btn-circle position-relative"
              [ngbPopover]="notificationMenu"
              autoClose="outside"
              #notificationMenuRef="ngbPopover"
              (click)="notificationsOpened()"
              id="notifications-button">
              <span class="material-icons-outlined">notifications</span>
              <span
                class="position-absolute translate-middle badge rounded-pill notification-unopened-badge"
                [hidden]="notificationsNotOpenedCount == 0">
                {{ notificationsNotOpenedCount <= 99 ? notificationsNotOpenedCount : "99+" }}<span class="visually-hidden">unread messages</span>
              </span>
            </button>
            <ng-template #notificationMenu>
              <div class="notification-title-container">
                <div class="notification-panel">
                  <span class="material-icons-outlined color_red" aria-hidden="true" color="accent">
                    {{ "notifications" }}
                  </span>
                  <div class="notification-title">Notifications</div>
                </div>
                <button type="button" class="btn-close" (click)="notificationMenuRef.close()"></button>
              </div>
              <div class="notification-content-container">
                <div class="segmented-control-container">
                  <button
                    class="segmented-control-item"
                    [ngClass]="{selected: notificationDisplaySelected == 0}"
                    (click)="updateNotificationsDisplayed(0)">
                    All
                  </button>
                  <button
                    class="segmented-control-item"
                    [ngClass]="{selected: notificationDisplaySelected == 1}"
                    (click)="updateNotificationsDisplayed(1)">
                    Unread
                  </button>
                </div>
                <div class="notification-list">
                  <div *ngIf="notifications.length == 0" style="margin-left: 24px"><i>No notifications.</i></div>
                  <div *ngFor="let notification of notificationsToDisplay; let i = index" style="margin-bottom: 8px">
                    <ng-container *ngIf="seeMore || i < 6">
                      <div class="single-notification-container">
                        <div class="unread-dot" [hidden]="notification.read"></div>
                        <div class="single-notification-with-date">
                          <div class="single-notification-content" [ngClass]="{unread: !notification.read}">
                            <img
                              [src]="notification.productLogo ? notification.productLogo : '../../assets/logo_deploy_cockpit.svg'"
                              alt="{{ notification.productName }}"
                              style="width: 30px; height: auto" />
                            <div>
                              <span class="single-notification-product-name">{{ notification.productName }} : </span>
                              <span class="single-notification-text"><div [outerHTML]="notification.text | safeHtml"></div></span>
                            </div>
                          </div>
                          <div>{{ getDateDiff(notification.date) }}</div>
                        </div>
                      </div>
                      <div class="single-notification-actions-container">
                        <div
                          (click)="invertNotificationRead(notification)"
                          (keydown)="$event.key == 'Enter' ? invertNotificationRead(notification) : null">
                          Mark as {{ notification.read ? "unread" : "read" }}
                        </div>
                        <div class="actions-dot"></div>
                        <div (click)="goToNotification(notification)" (keydown)="$event.key == 'Enter' ? goToNotification(notification) : null">
                          Take a look
                        </div>
                        <div class="actions-dot"></div>
                        <div (click)="deleteNotification(notification)" (keydown)="$event.key == 'Enter' ? deleteNotification(notification) : null">
                          Delete
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div
                  *ngIf="!seeMore && notificationsToDisplay.length > 6"
                  (click)="seeMoreClicked()"
                  (keydown)="$event.key == 'Enter' ? seeMoreClicked() : null"
                  class="see-more-text">
                  See more
                </div>
              </div>
            </ng-template>
          </li>
          <li class="nav-item ms-md-4">
            <span class="navbar-text text-uppercase">{{ username }} </span>
            <button class="btn btn-outline-primary btn-circle" id="account-button" [matMenuTriggerFor]="popover.profileMenu">
              <span class="material-icons-outlined">account_circle</span>
            </button>
            <app-popover #popover [isAdmin]="isAdmin"></app-popover>
          </li>
        </ul>
      </div>
      <hr class="separator" />
      <div class="container-fluid nav-dpr-container no-export">
        <!-- Tab menu -->
        <ul class="nav nav-tabs">
          <li class="nav-item" *ngIf="temporaryProduct">
            <a
              class="nav-link active"
              [routerLink]="['/product', temporaryProduct.name, temporaryProduct.id]"
              (click)="onProductSelect(temporaryProduct.id)">
              {{ temporaryProduct.name }}
            </a>
          </li>
          <li class="nav-item" *ngFor="let product of userProducts; let index = index">
            <a
              class="nav-link"
              [routerLink]="['/product', product.name, product.id]"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}"
              (click)="onProductSelect(product.id)">
              {{ product.name }}
            </a>
          </li>
          <div class="vertical-separator"></div>
          <div class="portfolio" *ngIf="portfolioReviewRights.includes('RC') || portfolioReviewRights.includes('EP') || isAdmin">
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/portfolio-review/ep']"
                routerLinkActive="active"
                (click)="onPortfolioReviewClick()"
                *ngIf="portfolioReviewRights.includes('EP') || isAdmin"
                data-cypress="portfolio-review-ep"
                >Portfolio Review EP</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/portfolio-review/rc']"
                routerLinkActive="active"
                (click)="onPortfolioReviewClick()"
                *ngIf="portfolioReviewRights.includes('RC') || isAdmin"
                >Portfolio Review RC</a
              >
            </li>
            <div class="vertical-separator"></div>
          </div>
          <li class="nav-item">
            <a class="nav-link search-header" [routerLink]="['/search']" routerLinkActive="active" (click)="onSearchClick()">
              <span class="material-icons-outlined">search</span>
              Search Product</a
            >
          </li>
        </ul>
        <button *ngIf="selectedProductId" type="button" class="btn btn-secondary save-dpr-dpc-button header-button" (click)="savePage('DPR')">
          <span class="material-icons-outlined" aria-hidden="true">add_a_photo</span>
          Save this DPR
        </button>
        <div class="portfolio-buttons" *ngIf="isOnRoute('portfolio-review')">
          <button
            type="button"
            class="btn btn-primary header-button save-dpc-data-button"
            *ngIf="portfolioReviewRights.includes('save-' + portfolioReviewBranch) || isAdmin"
            (click)="saveDPCData()">
            <span class="material-icons-outlined" aria-hidden="true">save</span>
            Save DPC data
          </button>
          <button type="button" class="btn btn-secondary save-dpr-dpc-button header-button" (click)="savePage('DPC')">
            <span class="material-icons-outlined" aria-hidden="true">add_a_photo</span>
            Save this DPC
          </button>
        </div>
      </div>
    </div>
    <img src="/assets/Logo_TotalEnergies.svg" alt="totalenergies_logo" />
  </div>
</nav>

<app-alert *ngIf="authenticationService.showAlert"></app-alert>
