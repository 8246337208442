import {Component, Input, OnInit, SimpleChanges} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {DndDropEvent} from "ngx-drag-drop";
import {ToastrService} from "ngx-toastr";
import {AddProductComponent} from "../add-product/add-product.component";
import {ConfirmPopupComponent} from "../common/confirm-popup/confirm-popup.component";
import {DpcComment, DpcCommentService} from "../common/dpc-comment.service";
import {SharedEventsService} from "../common/shared-events-service";
import {domainsEP, domainsRC, steps} from "../common/util/const";
import {ProductDetailsService} from "../product-details/product-details.service";
import {PortfolioStatusDTO, PortfolioStatusService} from "./portfolio-status.service";
import {ArrayService} from "../common/array.service";

@Component({
  selector: "portfolio-status",
  templateUrl: "./portfolio-status.component.html",
  styleUrls: ["./portfolio-status.component.scss"],
})
export class PortfolioStatusComponent implements OnInit {
  @Input() branch: string = "EP";
  @Input() isWriter: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() lbus: any;
  lastUpdate: Date;
  steps = steps;
  productsByStep: { [key: string]: PortfolioStatusDTO[] } = {};
  openedProducts: Set<number> = new Set();
  isEditMode: boolean = false;
  domains: string[] = [];
  selectedDomains: Set<string> = new Set();
  filteredProducts: PortfolioStatusDTO[] = [];
  products: PortfolioStatusDTO[];
  isUpdatingStep: boolean = false;

  @Input() commentPreFramingFraming: DpcComment;
  @Input() commentBuildBuyOperational: DpcComment;

  constructor(
    private readonly portfolioStatusService: PortfolioStatusService,
    private readonly dialog: MatDialog,
    private readonly toastrService: ToastrService,
    private readonly dpcCommentService: DpcCommentService,
    private readonly productDetailsService: ProductDetailsService,
    private readonly sharedEventsService: SharedEventsService,
    public readonly arrayService: ArrayService
  ) {
  }

  ngOnInit(): void {
    // Subscribe to product updates
    this.sharedEventsService.productUpdated$.subscribe(() => {
      this.loadProducts();
    });
    this.sharedEventsService.deploymentPlanUpdated$.subscribe(() => {
      this.loadProducts();
    });
    this.sharedEventsService.dpcDataSaved$.subscribe(() => {
      this.loadProducts();
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.branch?.currentValue) {
      this.domains = this.branch === "EP" ? domainsEP.map((d) => d.name) : domainsRC.map((d) => d.name);
      this.selectedDomains = new Set(this.domains);
      this.isEditMode = false;
      this.loadProducts();
    }
  }

  loadProducts(): void {
    this.portfolioStatusService.getPortfolioStatusByBranch(this.branch).subscribe((products) => {
      this.products = products;
      this.updateFilteredProducts(this.products);
    });
  }

  onDrop(event: DndDropEvent, step: string): void {
    if (this.isUpdatingStep) return;

    this.isUpdatingStep = true;
    const product: PortfolioStatusDTO = event.data;
    const previousStep = product.step;
    product.step = step;

    if (previousStep !== step) {
      this.portfolioStatusService.updateStep(product.productId, step).subscribe(
        () => {
          this.productsByStep[previousStep] = this.productsByStep[previousStep].filter((p) => p.productId !== product.productId);
          this.productsByStep[step].push(product);
          this.isUpdatingStep = false;

          this.sharedEventsService.notifyPortfolioStatusUpdated();
        },
        () => {
          product.step = previousStep;
          this.isUpdatingStep = false;
        }
      );
    } else {
      this.isUpdatingStep = false;
    }
  }

  openEditDialog(product: PortfolioStatusDTO): void {
    this.productDetailsService.getProductDetails(product.productId).subscribe((productDetails) => {
      this.dialog.open(AddProductComponent, {
        width: "600px",
        data: {domains: this.domains, branch: this.branch, product: productDetails},
      });
    });
  }

  deleteProduct(productId: number): void {
    this.dialog
      .open(ConfirmPopupComponent, {
        width: "30vw",
        data: {
          icon: "delete",
          title: "Delete a product",
          text: "Are you sure you want to delete this product ?",
        },
        position: {
          top: "20px",
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res == "confirm") {
          this.portfolioStatusService.deleteProduct(productId).subscribe(
            () => {
              this.toastrService.success("Product has been deleted !");
              this.sharedEventsService.notifyPortfolioStatusUpdated();
              this.loadProducts();
            },
            () => {
              this.toastrService.error("An error occurred while deleting the product.");
            }
          );
        }
      });
  }

  viewProduct(productId?: number, productName?: string): void {
    if (productId) {
      window.open(`/product/${productName}/${productId}`, "_blank");
    }
  }

  toggleProductDetails(event: MouseEvent, productId: number): void {
    if ((event.target as HTMLElement).closest(".actions")) {
      return;
    }
    if (this.openedProducts.has(productId)) {
      this.openedProducts.delete(productId);
    } else {
      this.openedProducts.add(productId);
    }
  }

  isProductOpened(productId: number): boolean {
    return this.openedProducts.has(productId);
  }

  get activeDomains(): string[] {
    const activeDomains = new Set<string>();

    // Loop through the products and add their domains to the activeDomains set
    this.products?.forEach((product) => {
      if (this.domains.includes(product.domain)) activeDomains.add(product.domain);
    });

    return Array.from(activeDomains);
  }

  getDomainColorCards(domain: string): string {
    let domains = this.branch == "EP" ? domainsEP : domainsRC;

    let domainObj = domains.find((d) => d.name == domain);

    return domainObj?.color || "#F5F5F5";
  }

  getDomainFlagColor(domain: string): string {
    let domains = this.branch == "EP" ? domainsEP : domainsRC;
    let domainObj = domains.find((d) => d.name == domain);
    return domainObj?.flag_color || "#ccc";
  }

  getDomainArrowColor(domain: string): string {
    let domains = this.branch == "EP" ? domainsEP : domainsRC;
    let domainObj = domains.find((d) => d.name == domain);
    return domainObj?.arrow_color || "#999";
  }

  updateFilteredProducts(products: PortfolioStatusDTO[]): void {
    this.productsByStep = {};

    this.steps.forEach((step) => {
      this.productsByStep[step.name] = products.filter((p) => p.step === step.name && this.selectedDomains.has(p.domain));
    });

    this.filteredProducts = Object.values(this.productsByStep).flat();
  }

  getFilteredProducts(): PortfolioStatusDTO[] {
    let products: PortfolioStatusDTO[] = [];
    Object.values(this.productsByStep).forEach((stepProducts) => {
      products = products.concat(stepProducts.filter((product) => this.selectedDomains.has(product.domain)));
    });
    return products;
  }

  toggleDomain(domain: string): void {
    if (this.selectedDomains.has(domain)) {
      this.selectedDomains.delete(domain);
    } else {
      this.selectedDomains.add(domain);
    }

    this.updateFilteredProducts(this.products);
  }

  isDomainSelected(domain: string): boolean {
    return this.selectedDomains.has(domain);
  }

  productStepIsBeforePreviousStep(step: string, previousStep: string): boolean {
    const stepIndex = steps.findIndex((s) => s.name === step);
    const previousStepIndex = steps.findIndex((s) => s.name === previousStep);

    if (stepIndex === -1 || previousStepIndex === -1) {
      return false;
    }

    return stepIndex < previousStepIndex;
  }

  isRecent(date: string): boolean {
    const givenDate = new Date(date);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - givenDate.getTime();
    const monthsDifference = timeDifference / (1000 * 3600 * 24 * 30);
    return monthsDifference <= 6;
  }

  saveComment(key: "preFraming" | "buildOperational", commentText: string): void {
    const comment = key === "preFraming" ? this.commentPreFramingFraming : this.commentBuildBuyOperational;
    if (commentText === "<p></p>") {
      commentText = null;
      if (key === "preFraming") this.commentPreFramingFraming.comment = null;
      else this.commentBuildBuyOperational.comment = null;
    }

    const commentPayload: DpcComment = {
      id: comment?.id,
      comment: commentText,
      title: key === "preFraming" ? "Highlights Preframing & Framing" : "Highlights Build/Buy & Operational",
      quarter: "",
      postedOn: new Date(),
      branch: this.branch,
    };

    this.dpcCommentService.saveComment(commentPayload).subscribe(
      () => {
        this.toastrService.success(`Comment saved successfully!`);
        if (key === "preFraming") this.commentPreFramingFraming.postedOn = new Date();
        else this.commentBuildBuyOperational.postedOn = new Date();
      },
      () => {
        this.toastrService.error(`Error while saving the comment.`);
      }
    );
  }
}
