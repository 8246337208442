import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DeploymentPlanService {
  private readonly apiUrl = `${environment.webProtocol}://${environment.serverUrl}/api/deployment-plan`;

  constructor(private readonly http: HttpClient) {
  }

  getDeploymentPlanTableData(branch: string): Observable<any> {
    let httpParams: HttpParams = new HttpParams().set("branch", branch);

    return this.http.get<any>(`${this.apiUrl}/get-table-data`, {params: httpParams});
  }

  saveDeploymentPlans(allToSave: Array<any>, productId: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams().set("productId", productId);

    return this.http.post<any>(`${this.apiUrl}/save`, allToSave, {params: httpParams});
  }
}
