<div id="search-product-page">
  <div class="filters">
    <!-- Filter: Product Name -->
    <div class="form-group">
      <label class="form-label" for="productNameFilter">Product Name</label>
      <ng-select
        [items]="productNameOptions"
        bindLabel="name"
        [(ngModel)]="productNameFilter"
        [searchable]="true"
        placeholder="All"
        (change)="filterProducts()">
      </ng-select>
    </div>

    <!-- Filter: Product Owner -->
    <div class="form-group">
      <label class="form-label" for="productOwnerFilter">Product Owner</label>
      <ng-select
        [items]="productOwnerOptions"
        bindLabel="productOwner"
        [(ngModel)]="productOwnerFilter"
        [searchable]="true"
        placeholder="All"
        (change)="filterProducts()">
      </ng-select>
    </div>

    <!-- Filter: Branch -->
    <div class="form-group">
      <label class="form-label" for="branchFilter">Branch</label>
      <ng-select
        [items]="branchOptions"
        bindLabel="branch"
        [(ngModel)]="branchFilter"
        [searchable]="false"
        placeholder="All"
        (change)="filterProducts()">
      </ng-select>
    </div>

    <div class="form-group">
      <label class="form-label" for="lbuFilter">LBU</label>
      <ng-select [items]="lbuOptions" bindLabel="lbu" [(ngModel)]="lbuFilter" [searchable]="true" placeholder="All" (change)="filterProducts()">
        <ng-template ng-option-tmp let-item="item">
          <span class="lbu-flag">
            <img [src]="item.flagUrl" [alt]="item.name" class="flag-icon" />
            {{ item.name }}
          </span>
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
          <img [src]="item.flagUrl" alt="{{ item.name }}" class="flag-icon me-1" /> {{ item.name }}
        </ng-template>
      </ng-select>
    </div>

    <!-- Filter: Domain -->
    <div class="form-group">
      <label class="form-label" for="domainFilter">Domain</label>
      <ng-select
        [items]="domainOptions"
        bindLabel="domain"
        [(ngModel)]="domainFilter"
        [searchable]="false"
        placeholder="All"
        (change)="filterProducts()">
      </ng-select>
    </div>

    <!-- Filter: Squad Profile -->
    <div class="form-group">
      <label class="form-label" for="squadFilter">Squad Profile</label>
      <ng-select
        [items]="squadOptions"
        bindLabel="squad"
        [(ngModel)]="squadFilter"
        [searchable]="false"
        placeholder="All"
        (change)="filterProducts()">
      </ng-select>
    </div>

    <!-- Filter: IT Scaler -->
    <div class="form-group">
      <label class="form-label" for="itScalerFilter">IT Scaler</label>
      <ng-select
        [items]="itScalerOptions"
        bindLabel="itScaler"
        [(ngModel)]="itScalerFilter"
        [searchable]="true"
        placeholder="All"
        (change)="filterProducts()">
      </ng-select>
    </div>

    <!-- Reset Filters Button -->
    <button class="btn btn-link" (click)="resetFilters()" matTooltip="Reset the filters">
      <span class="material-icons" style="color: red">filter_list_off</span>
    </button>
  </div>

  <div id="search-result">
    <div id="search-result-header">
      <span class="material-icons-outlined color_red" aria-hidden="true" color="accent">
        {{ "search" }}
      </span>
      <h4>{{ "Search Result" }}</h4>
    </div>
    <div class="spinner-container">
      <div *ngIf="loading" class="spinner-border text-primary" output="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <table *ngIf="!loading" class="table" id="product-table">
      <caption>
        Products
      </caption>
      <thead>
        <tr>
          <th scope="col" class="sortable-column" (click)="onSort('favorites')" (keydown)="onSort('favorites')">
            <div class="column-header favorite-column">
              <div class="sort-icons">
                <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'favorites' && sortDirection === 'asc'}">
                  expand_less
                </span>
                <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'favorites' && sortDirection === 'desc'}">
                  expand_more
                </span>
              </div>
            </div>
          </th>
          <th scope="col"></th>
          <th scope="col" class="sortable-column" (click)="onSort('productName')" (keydown)="onSort('productName')">
            <div class="column-header">
              <div class="column-header">
                Product Name
                <div class="sort-icons">
                  <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'productName' && sortDirection === 'asc'}">
                    expand_less
                  </span>
                  <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'productName' && sortDirection === 'desc'}">
                    expand_more
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th scope="col" class="sortable-column" (click)="onSort('productOwner')" (keydown)="onSort('productOwner')">
            <div class="column-header">
              Product Owner
              <div class="sort-icons">
                <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'productOwner' && sortDirection === 'asc'}">
                  expand_less
                </span>
                <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'productOwner' && sortDirection === 'desc'}">
                  expand_more
                </span>
              </div>
            </div>
          </th>
          <th scope="col" class="sortable-column" (click)="onSort('branch')" (keydown)="onSort('branch')">
            <div class="column-header">
              Branch
              <div class="sort-icons">
                <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'branch' && sortDirection === 'asc'}">
                  expand_less
                </span>
                <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'branch' && sortDirection === 'desc'}">
                  expand_more
                </span>
              </div>
            </div>
          </th>
          <th scope="col">LBU</th>
          <th scope="col" class="sortable-column" (click)="onSort('domain')" (keydown)="onSort('domain')">
            <div class="column-header">
              Domain
              <div class="sort-icons">
                <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'domain' && sortDirection === 'asc'}">
                  expand_less
                </span>
                <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'domain' && sortDirection === 'desc'}">
                  expand_more
                </span>
              </div>
            </div>
          </th>
          <th scope="col" class="sortable-column" (click)="onSort('squadProfile')" (keydown)="onSort('squadProfile')">
            <div class="column-header">
              Squad Profile
              <div class="sort-icons">
                <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'squadProfile' && sortDirection === 'asc'}">
                  expand_less
                </span>
                <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'squadProfile' && sortDirection === 'desc'}">
                  expand_more
                </span>
              </div>
            </div>
          </th>
          <th scope="col" class="sortable-column" (click)="onSort('itScaler')" (keydown)="onSort('itScaler')">
            <div class="column-header">
              IT Scaler
              <div class="sort-icons">
                <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'itScaler' && sortDirection === 'asc'}">
                  expand_less
                </span>
                <span class="material-icons expand-icon" [ngClass]="{active: sortedColumn === 'itScaler' && sortDirection === 'desc'}">
                  expand_more
                </span>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let product of paginatedProducts">
          <td>
            <span
              class="material-icons-outlined icon favorites"
              title="Favorites"
              (click)="toggleFavorite(product.id, product.favorite)"
              (keydown)="$event.key == 'Enter' ? toggleFavorite(product.id, product.favorite) : null">
              {{ product.favorite ? "star" : "star_outline" }}
            </span>
          </td>
          <td>
            <span
              class="material-icons-outlined icon redirection"
              title="See Product"
              (click)="redirectToProduct(product)"
              (keydown)="$event.key == 'Enter' ? redirectToProduct(product) : null">
              visibility
            </span>
          </td>
          <td>
            <div class="product-logo-name-section">
              <img [src]="product.logo ? product.logo : '../../assets/logo_deploy_cockpit.svg'" alt="{{ product.name }}" class="product-logo-img" />
              <span>{{ product.name }}</span>
            </div>
          </td>
          <td>
            {{ arrayService.formatArrayForDisplay(arrayService.mapArrayWithProperty(product.productOwners, "fullName"), null) }}
          </td>
          <td>
            {{ arrayService.formatArrayForDisplay(product.branches, null) }}
          </td>
          <td class="lbu-column">
            <span *ngFor="let lbu of product.lbus" class="lbu-flag">
              <img [src]="getFlagUrl(lbu)" title="{{ lbu }}" alt="{{ lbu }}" class="flag-icon" />
            </span>
          </td>
          <td [innerHTML]="getFormattedDomains(product.domains)"></td>
          <td>{{ product.squadProfile }}</td>
          <td>{{ product.itScaler?.fullName }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Pagination Section -->
    <div class="d-flex align-items-center mt-3">
      <div class="align-middle">{{ lowerLimit }} - {{ upperLimit }} of {{ filteredProducts.length }}</div>

      <ul class="pagination ms-2">
        <li class="page-item" [class.disabled]="currentPage === 1">
          <button class="page-link" aria-label="Previous" (click)="changePage(currentPage - 1)">
            <span class="material-icons-outlined" aria-hidden="true">chevron_left</span>
          </button>
        </li>
        <li class="page-item" [class.disabled]="currentPage === totalPages">
          <button class="page-link" aria-label="Next" (click)="changePage(currentPage + 1)">
            <span class="material-icons-outlined" aria-hidden="true">chevron_right</span>
          </button>
        </li>
      </ul>
      <div class="ms-auto">
        <label for="itemsPerPage" class="form-label me-2">Items per page:</label>
        <select id="itemsPerPage" class="form-select" [(ngModel)]="itemsPerPage" (change)="updatePagination()">
          <option *ngFor="let option of [5, 10, 20, 50]" [value]="option">{{ option }}</option>
        </select>
      </div>
    </div>
  </div>
</div>
