import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {SharedEventsService} from "src/app/common/shared-events-service";
import {ConfirmPopupComponent} from "../../../common/confirm-popup/confirm-popup.component";
import {DateUtil} from "../../../common/util/date-util";
import {DeploymentPlanService} from "../deployment-plan.service";

@Component({
  selector: "edit-deployment-plan",
  templateUrl: "./edit-deployment-plan.component.html",
  styleUrls: ["./edit-deployment-plan.component.scss"],
})
export class EditDeploymentPlanComponent {
  saveInProgress: boolean = false;
  saveFromClose: boolean = false;

  initialDeploymentPlans: any;
  deploymentPlans: any;

  products: any;
  selectedProduct: any;

  sites: any;

  quarterOptions: Array<string>;
  statusOptions: Array<string>;

  deploymentPlanTableShouldBeReloaded: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EditDeploymentPlanComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly deploymentPlanService: DeploymentPlanService,
    private readonly toastr: ToastrService,
    private readonly dialog: MatDialog,
    private readonly sharedEventsService: SharedEventsService
  ) {
    this.initialDeploymentPlans = JSON.parse(JSON.stringify(this.data.deploymentPlans));
    this.deploymentPlans = this.data.deploymentPlans;
    this.selectedProduct = this.data.products.find((product) => product.id == this.data.initialProductId);

    this.products = this.data.products;
    this.sites = this.data.sites;

    for (let product of this.products) {
      if (this.deploymentPlans[product.id] == null) this.deploymentPlans[product.id] = {};
      this.fillMissingSites(product.id);
    }
  }

  ngOnInit() {
    this.quarterOptions = [];

    let currentQuarter: number = Math.floor((new Date().getMonth() + 3) / 3);
    let currentYear: number = parseInt(new Date().getFullYear().toString().substring(2, 4));

    this.quarterOptions.push(currentQuarter + "Q" + currentYear);

    for (let i = 0; i < 5; i++) {
      // Putting the 5 next quarters
      if (currentQuarter == 4) {
        currentQuarter = 1;
        currentYear++;
      } else currentQuarter++;

      this.quarterOptions.push(currentQuarter + "Q" + currentYear);
    }

    currentYear = parseInt(new Date().getFullYear().toString().substring(2, 4));
    this.quarterOptions.push(currentYear + 1 + "+");
    this.quarterOptions.push(currentYear + 2 + "+");

    this.statusOptions = ["In advance", "On time", "Late", "Blocked"];
  }

  closePopup() {
    this.dialogRef.close(this.deploymentPlanTableShouldBeReloaded ? "success" : "");
  }

  save(close: boolean) {
    this.saveInProgress = true;
    if (close) this.saveFromClose = true;
    let allToSave = this.getAllChangesOnProduct();
    this.deploymentPlanService.saveDeploymentPlans(allToSave, this.selectedProduct.id).subscribe(
      () => {
        this.toastr.success("Deployment plans have been saved !");
        this.deploymentPlanTableShouldBeReloaded = true;
        this.saveInProgress = false;
        this.saveFromClose = false;

        this.initialDeploymentPlans[this.selectedProduct.id] = JSON.parse(JSON.stringify(this.deploymentPlans[this.selectedProduct.id]));

        // Notify product update
        this.sharedEventsService.notifyDeploymentPlanUpdated();

        if (close) {
          this.closePopup();
        }
      },
      () => {
        this.toastr.error("An error occurred while saving the deployment plans.");
        this.saveInProgress = false;
        this.saveFromClose = false;
      }
    );
  }

  getQuarterOptionsWithCurrent(currentPlan: string): Array<string> {
    let quarterOptionsToPropose: Array<string> = [...this.quarterOptions];
    if (currentPlan != null) {
      quarterOptionsToPropose.splice(0, 0, currentPlan);
    }

    quarterOptionsToPropose.sort((a, b) => DateUtil.sortQuartersByYear(a, b));

    return [...new Set(quarterOptionsToPropose)];
  }

  getAllChangesOnProduct(): Array<any> {
    let deploymentPlanChanges = [];
    for (let siteId in this.deploymentPlans[this.selectedProduct.id]) {
      let initialCell = this.initialDeploymentPlans[this.selectedProduct.id] ? this.initialDeploymentPlans[this.selectedProduct.id][siteId] : {};
      let cell = this.deploymentPlans[this.selectedProduct.id][siteId];
      if (cell.deploymentPlan != null && (cell.deploymentPlan != initialCell?.deploymentPlan || cell.status != initialCell?.status)) {
        deploymentPlanChanges.push({siteId: siteId, deploymentPlan: cell.deploymentPlan, status: cell.status});
      }
    }

    return deploymentPlanChanges;
  }

  onProductChange($event) {
    if (this.getAllChangesOnProduct().length == 0) {
      this.selectedProduct = this.products.find((p) => p.name == $event.target.value);
    } else {
      this.dialog
        .open(ConfirmPopupComponent, {
          width: "25vw",
          data: {
            icon: "arrow_circle_down",
            title: "Switch product",
            text: "There are unsaved changes on this product.<br /> Are you sure you want to change product without saving ?",
          },
          position: {
            top: "50px",
          },
        })
        .afterClosed()
        .subscribe((res) => {
          if (res === "confirm") {
            this.deploymentPlans[this.selectedProduct.id] = JSON.parse(JSON.stringify(this.initialDeploymentPlans[this.selectedProduct.id]));
            this.fillMissingSites(this.selectedProduct.id);
            this.selectedProduct = this.products.find((p) => p.name == $event.target.value);
          }
        });
    }
  }

  fillMissingSites(productId: number) {
    for (let site of this.sites) {
      if (this.deploymentPlans[productId][site.id] == null) this.deploymentPlans[productId][site.id] = {};
    }
  }
}
