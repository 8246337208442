import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";

export interface DpcComment {
  id?: number;
  comment: string;
  title: string;
  quarter: string;
  postedOn: Date;
  branch: string;
}

@Injectable({
  providedIn: "root",
})
export class DpcCommentService {
  private readonly apiUrl = `${environment.webProtocol}://${environment.serverUrl}/api/dpc-comment`;

  constructor(private readonly http: HttpClient) {}

  saveComment(comment: DpcComment): Observable<any> {
    return this.http.post(this.apiUrl, comment);
  }

  getComments(branch: string): Observable<DpcComment[]> {
    let params = new HttpParams();
    params = params.set("branch", branch);
    return this.http.get<DpcComment[]>(this.apiUrl, {params});
  }
}
