import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {Location} from "@angular/common";
import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {ActionPlanComponent} from "../action-plan/action-plan.component";
import {ManageActionComponent} from "../action-plan/manage-action/manage-action.component";
import {ConfirmPopupComponent} from "../common/confirm-popup/confirm-popup.component";
import {EightPillarsService} from "./eight-pillars.service";
import {PillarCommentComponent} from "./pillar-comment/pillar-comment.component";
import {PillarCommentService} from "./pillar-comment/pillar-comment.service";
import {PillarCommentDTO, PillarDTO} from "./pillar-dto";

@Component({
  selector: "eight-pillars",
  templateUrl: "./eight-pillars.component.html",
  styleUrls: ["./eight-pillars.component.scss"],
})
export class EightPillarsComponent implements OnInit, OnChanges {
  @Input() selectedProductId!: number;
  @Input() selectedProductName: string;
  @Input() selectedProductOwners: Array<string>;

  @Input() isWriter: boolean;
  @Input() isAdmin: boolean = false;

  @Input() viewMode: string = "Action plan";

  @Input() lbus: any;

  selectedSegment: number;
  lastUpdatePillars: Date;
  lastUpdateActionPlan: Date;
  @Input() section!: string;
  @ViewChild(ActionPlanComponent) actionPlanComponent: ActionPlanComponent;
  pillars: PillarDTO[];
  fragment: any;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly eightPillarsService: EightPillarsService,
    private readonly dialog: MatDialog,
    private readonly pillarCommentService: PillarCommentService,
    private readonly toastrService: ToastrService,
    private readonly location: Location,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.selectedSegment = this.viewMode == "8 pillars" ? 0 : 1;

    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
      if (fragment == "action-plan") {
        this.selectedSegment = 1;
        setTimeout(() => document.getElementsByTagName("eight-pillars")[0].scrollIntoView(), 0);
        this.router.navigateByUrl(this.router.url.split("#")[0]);
        this.fragment = null;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedProductId?.currentValue) {
      this.loadPillars();
    }
  }

  loadPillars() {
    this.eightPillarsService.getAllPillarsByProduct(this.selectedProductId).subscribe((res: PillarDTO[]) => {
      this.pillars = res;

      this.getLastUpdateDate();

      if (this.fragment == "action-plan") {
        document.getElementsByTagName("eight-pillars")[0].scrollIntoView();
        this.router.navigateByUrl(this.router.url.split("#")[0]);
        this.fragment = null;
      }
    });
  }

  getLastUpdateDate() {
    let lastUpdateDate: Date;
    this.pillars.forEach((pillar) => {
      if (lastUpdateDate == null || pillar.lastUpdateDate > lastUpdateDate) lastUpdateDate = pillar.lastUpdateDate;
    });
    this.lastUpdatePillars = lastUpdateDate;
  }

  addComment(pillarCardId: number) {
    this.dialog
      .open(PillarCommentComponent, {
        width: "40vw",
        data: {
          pillarCardId: pillarCardId,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res == "success") {
          this.loadPillars();
        }
      });
  }

  editComment(pillarCardComment: PillarCommentDTO) {
    this.dialog
      .open(PillarCommentComponent, {
        width: "40vw",
        data: {
          pillarCardComment: pillarCardComment,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res == "success") {
          this.loadPillars();
        }
      });
  }

  deleteComment(commentId: number) {
    this.dialog
      .open(ConfirmPopupComponent, {
        width: "30vw",
        data: {
          icon: "delete",
          title: "Delete a comment",
          text: "Are you sure you want to delete this comment ?",
        },
        position: {
          top: "20px",
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res == "confirm") {
          this.pillarCommentService.deleteComment(commentId).subscribe(
            () => {
              this.toastrService.success("Comment has been deleted !");
              this.loadPillars();
            },
            () => {
              this.toastrService.error("An error occurred while deleting the comment.");
            }
          );
        }
      });
  }

  drop(event: CdkDragDrop<PillarCommentDTO[]>, pillar: PillarDTO) {
    moveItemInArray(pillar.comments, event.previousIndex, event.currentIndex);
    const updatedComments = pillar.comments.map((comment, index) => ({
      id: comment.id,
      commentOrder: index,
    }));
    this.eightPillarsService.updateCommentOrder(updatedComments).subscribe();
  }

  changePillarStatus(pillar: PillarDTO, status: string) {
    if (pillar.status != status) {
      this.eightPillarsService.changePillarStatus(pillar.id, status).subscribe(
        () => {
          this.toastrService.success("Status has been updated !");
          this.loadPillars();
        },
        () => {
          this.toastrService.error("An error occurred while updating the status.");
        }
      );
    }
  }

  getStatusDescriptionFromColor(status: string): string {
    if (status == "green") return "Secured";
    else if (status == "orange") return "Under control | Close follow-up";
    else if (status == "red") return "Critical point";
    else if (status == "grey") return "N/A";
  }

  getPillarDescription(pillarName: string): string {
    switch (pillarName) {
      case "Mobilizing LBUs":
        return "Ensuring the commitment of the Local Business Unit and the availability of the key stakeholders.";
      case "Product Owner":
        return "Mobilizing end-to-end product owner to frame and build the digital product he/she will deploy afterward.";
      case "Interface with IT":
        return "Exposing the data. Interfacing to write back set-points. Typical software includes SAP, telecom, data Historian PI, legacy adaptation...";
      case "Expert Metier support":
        return "Involve Branches or OneTech specialists for support on product development or deployment (e.g., H2 network modeling).";
      case "Data Preparation":
        return "Identifying data sources. Business data models, data structure, data quality assessment and cleansing.";
      case "Product Adaptation":
        return "Configuration for each deployment. Adaptation to local specificities. Minor evolutions, prepare cyber adaptation.";
      case "Data Science":
        return "Adaptating data science model and ensuring their monitoring and retraining.";
      case "Change Adoption":
        return "Preparation and execution of activities for digital product adoption by users and business process change managment.";
      default:
        return "No description available.";
    }
  }

  getPillarIcon(pillarName: string): string {
    switch (pillarName) {
      case "Product Owner":
        return "how_to_reg";
      case "Mobilizing LBUs":
        return "groups";
      case "Interface with IT":
        return "devices";
      case "Expert Metier support":
        return "business_center";
      case "Data Preparation":
        return "../../assets/data_preparation_icon.svg";
      case "Product Adaptation":
        return "settings_suggest";
      case "Data Science":
        return "psychology";
      case "Change Adoption":
        return "handshake";
      default:
        return "help_outline";
    }
  }

  /**ACTION PLAN **/
  openAddActionDialog(): void {
    const dialogRef = this.dialog.open(ManageActionComponent, {
      data: {productId: this.selectedProductId, motherPage: "product", lbus: this.lbus},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshActionPlan();
      }
    });
  }

  refreshActionPlan() {
    if (this.actionPlanComponent) {
      this.actionPlanComponent.loadActionPlansByProductId(this.selectedProductId);
    }
  }

  exportInXlsx() {
    this.actionPlanComponent.exportInXlsx();
  }

  exportInJpeg() {
    this.actionPlanComponent.exportInJpeg();
  }

  exportInPdf() {
    this.actionPlanComponent.exportInPdf();
  }
}
