<div class="accordion">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" [id]="'accordion-btn-header-' + 'action-plan'">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#accordion-btn-collapse-' + 'action-plan'"
        aria-expanded="true"
        [attr.aria-controls]="'accordion-btn-collapse-' + 'action-plan'">
        <div class="container-left-accordion-title">
          <span class="material-icons-outlined color_red" aria-hidden="true" color="accent">
            {{ "task_alt" }}
          </span>
          {{ "Action Plan Monitoring" }}
        </div>
      </button>
      <div class="container-right">
        <span class="last-update-text" *ngIf="lastUpdate"> Last Update : {{ lastUpdate | date : "dd/MM/yyyy" : "UTC" }} </span>
        <div class="additional-btn no-export">
          <button type="button" class="btn btn-secondary" [matMenuTriggerFor]="menuExport">
            <span class="material-icons-outlined" aria-hidden="true">file_download</span>Export
          </button>
        </div>
        <mat-menu #menuExport="matMenu" yPosition="below">
          <button mat-menu-item (click)="exportInXlsx()">Export in .xlsx</button>
          <button mat-menu-item (click)="exportInJpeg()">Export in .jpeg</button>
          <button mat-menu-item (click)="exportInPdf()">Export in .pdf</button>
        </mat-menu>
        <div class="additional-btn no-export" *ngIf="isWriter || isAdmin">
          <button type="button" class="btn btn-secondary" (click)="openAddActionDialog()">
            <span class="material-icons-outlined" aria-hidden="true">add</span>Add Action
          </button>
        </div>
      </div>
    </h4>
    <div [id]="'accordion-btn-collapse-' + 'action-plan'" class="accordion-collapse show">
      <div class="accordion-body">
        <app-action-plan
          [motherPage]="'dpc'"
          [branch]="branch"
          [isWriter]="isWriter"
          [isAdmin]="isAdmin"
          (sendLastUpdate)="lastUpdate = $event"
          [productId]="selectedProductId"
          [productName]="selectedProductName"
          [productOwners]="selectedProductOwners"
          [lbus]="lbus"
          [products]="products">
        </app-action-plan>
      </div>
    </div>
  </div>
</div>

<portfolio-status
  [branch]="branch"
  [isAdmin]="isAdmin"
  [isWriter]="isWriter.includes('Portfolio Status & KPI')"
  [commentPreFramingFraming]="commentPreFramingFraming"
  [commentBuildBuyOperational]="commentBuildBuyOperational"
  [lbus]="lbus">
</portfolio-status>

<pipeline-evolution
  [branch]="this.branch">
</pipeline-evolution>

<deployment-plan
  [isWriter]="isWriter.includes('Deployment Plan') || isAdmin"
  [branch]="branch">
</deployment-plan>

<deployment-progress
  [branch]="branch"
  [isWriter]="isWriter.includes('Deployment Progress') || isAdmin"
  [isWriterPotentials]="isWriter.includes('Deployment Progress - potentials') || isAdmin"
  [products]="products">
</deployment-progress>

<team-confidence
  [branch]="branch"
  [isWriter]="isWriter.includes('Team Confidence') || isAdmin">
</team-confidence>

<deploy-it-activity-distribution
  [isWriter]="isWriter.includes('Deploy IT - Activity Distribution') || isAdmin"
  [branch]="this.branch"
  [lbus]="lbus"
  [activityPerProductComment]="activityPerProductComment"
  [activityPerQuarterComment]="activityPerQuarterComment">
</deploy-it-activity-distribution>
