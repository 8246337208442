<popup-header [dialogRef]="dialogRef" icon="add_circle" text="{{ data?.product?.id ? 'Edit Product' : 'Add New Product' }}"></popup-header>
<div class="add-product-dialog" id="add-product-dialog">
  <form [formGroup]="addProductForm">
    <!-- Logo -->
    <div class="logo-container" (click)="triggerFileUpload()" (keydown.enter)="triggerFileUpload()" (keypress)="handleKeyPress($event)">
      <div class="logo-circle">
        <img *ngIf="addProductForm.get('logo')?.value" [src]="addProductForm.get('logo')?.value" alt="Uploaded Logo" />
        <span *ngIf="!addProductForm.get('logo')?.value" class="upload-text">+</span>
      </div>
      <input type="file" id="logo" #fileInput (change)="onLogoChange($event)" accept="image/*" hidden />
    </div>

    <!-- Product Name -->
    <div class="form-group">
      <label for="productName" class="form-label">Product Name <span style="color: red">*</span></label>
      <input type="text" class="form-control" id="productName" formControlName="name" required placeholder="Enter product name" />
    </div>

    <!-- Branch -->
    <div class="form-group">
      <label for="branches" class="form-label">Branch <span style="color: red">*</span></label>
      <ng-select
        class="select-control-multiple"
        [items]="branches"
        formControlName="branches"
        [multiple]="true"
        placeholder="Select branches"></ng-select>
    </div>

    <!-- LBU -->
    <div class="form-group">
      <label for="lbus" class="form-label">LBU</label>
      <ng-select class="select-control-multiple" [items]="lbu" formControlName="lbu" [multiple]="true" bindLabel="name" placeholder="Select lbu">
        <ng-template ng-option-tmp let-item="item">
          <img [src]="item.flag" alt="{{ item.name }}" class="flag-icon me-1" /> {{ item.name }}
        </ng-template>

        <!-- Template for Selected Items -->
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <span class="ng-value-icon" (click)="clear(item)" (keypress)="clear(item)"> &times; </span>
          <img [src]="item.flag" alt="{{ item.name }}" class="flag-icon me-1" /> {{ item.name }}
        </ng-template>
      </ng-select>
    </div>

    <!-- Sites -->
    <div class="form-group" *ngIf="showSiteField">
      <label for="sites" class="form-label">Sites</label>
      <ng-select
        class="select-control-multiple"
        [items]="sites"
        formControlName="sites"
        [multiple]="true"
        bindLabel="name"
        placeholder="Select site"
        data-cypress="select-Sites"></ng-select>
    </div>

    <div class="form-group">
      <label for="step">Step <span style="color: red">*</span></label>
      <ng-select [items]="steps" formControlName="step" placeholder="Select step" data-cypress="select-Step"></ng-select>
    </div>
    <div class="form-group" *ngIf="addProductForm.get('branches').value?.length > 0">
      <label for="pilot">Pilot (Site) <span *ngIf="specialFieldIsRequired['pilot']" style="color: red">*</span></label>
      <ng-select
        [items]="sites"
        bindLabel="name"
        formControlName="pilot"
        placeholder="Select site"
        [disabled]="data?.id ? true : false"
        data-cypress="select-Pilot"></ng-select>
    </div>

    <!-- Domain -->
    <div class="form-group" *ngFor="let domainField of domainFields">
      <label for="domain" class="form-label"
        ><strong>{{ domainField.label }} <span style="color: red">*</span></strong></label
      >
      <ng-select
        [items]="domainField.items"
        [formControlName]="domainField.formControlName"
        [multiple]="false"
        bindLabel="name"
        placeholder="Select domain"
        [attr.data-cypress]="'select-domain-' + domainField.branch"></ng-select>
    </div>

    <!-- Sponsor -->
    <div class="form-group">
      <label for="sponsors" class="form-label">Sponsors</label>
      <app-user-autocomplete
        [placeholder]="'Select Sponsors'"
        (onUserSelected)="onUsersSelected($event, 'sponsors')"
        [multiple]="true"
        [initialSelectedUsers]="selectedSponsors">
      </app-user-autocomplete>
    </div>

    <!-- Product Owner -->
    <div class="form-group product-owners">
      <label for="productOwner" class="form-label">Product Owners <span *ngIf="specialFieldIsRequired['productOwners']" style="color: red">*</span></label>
      <app-user-autocomplete
        [placeholder]="'Select Product Owner'"
        (onUserSelected)="onUsersSelected($event, 'productOwners')"
        [multiple]="true"
        [initialSelectedUsers]="selectedProductOwners">
      </app-user-autocomplete>
    </div>

    <!-- Domain / Asset Lead -->
    <div class="form-group">
      <label for="domainLead" class="form-label">Domain / Asset Leads</label>
      <app-user-autocomplete
        [placeholder]="'Select Domain / Asset Lead'"
        (onUserSelected)="onUsersSelected($event, 'domainLeads')"
        [multiple]="true"
        [initialSelectedUsers]="selectedDomainLeads">
      </app-user-autocomplete>
    </div>

    <!-- IT Scaler -->
    <div class="form-group it-scaler">
      <label for="itScaler" class="form-label">IT Scaler <span *ngIf="specialFieldIsRequired['itScaler']" style="color: red">*</span></label>
      <app-user-autocomplete
        [placeholder]="'Select IT Scaler'"
        (onUserSelected)="onUsersSelected($event, 'itScaler')"
        [multiple]="false"
        [initialSelectedUsers]="[selectedItScaler]">
      </app-user-autocomplete>
    </div>

    <!-- Change Coordinators -->
    <div class="form-group">
      <label for="changeCoordinators" class="form-label">Change Coordinators</label>
      <app-user-autocomplete
        [placeholder]="'Select Change Coordinators'"
        (onUserSelected)="onUsersSelected($event, 'changeCoordinators')"
        [multiple]="true"
        [initialSelectedUsers]="selectedChangeCoordinators">
      </app-user-autocomplete>
    </div>

    <!-- Squad Profile -->
    <div class="form-group">
      <label for="squadProfile" class="form-label">Squad Profile <span *ngIf="specialFieldIsRequired['squadProfile']" style="color: red">*</span></label>
      <ng-select [items]="squadProfiles" formControlName="squadProfile" [multiple]="false" placeholder="Select squad profile"></ng-select>
    </div>

    <!-- Associated Product -->
    <div class="form-group" *ngIf="addProductForm.get('squadProfile')?.value === 'Multi Product'">
      <label for="associatedProduct" class="form-label">Associated Product</label>
      <ng-select
        [items]="products"
        formControlName="associatedProductId"
        bindLabel="name"
        bindValue="id"
        placeholder="Select associated product"></ng-select>
    </div>

    <!-- Status -->
    <div class="form-group">
      <label for="statusDates" class="form-label">Status Dates</label>
      <div class="status-group">
        <div class="status-field">
          <label for="creationDate">Creation Date</label>
          <input type="date" class="form-control" formControlName="statusCreationDate" />
        </div>
        <div class="status-field">
          <label for="transferDate">Transfer Date</label>
          <input type="date" class="form-control" formControlName="statusTransferDate" />
        </div>
        <div class="status-field">
          <label for="runDate">Run Date</label>
          <input type="date" class="form-control" formControlName="statusRunDate" />
        </div>
        <div class="status-field">
          <label for="decommissioningDate">Decommissioning Date</label>
          <input type="date" class="form-control" formControlName="statusDecommissioningDate" />
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="flags">Flags</label>

      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="hold" id="hold" />
        <label class="form-check-label" for="hold">Hold</label>
      </div>
      <div class="flags-container">
        <div class="form-check">
          <input
            type="radio"
            class="form-check-input"
            formControlName="ideaOrPoc"
            value="idea"
            id="idea"
            (click)="toggleRadio('idea')"
            (keydown.enter)="toggleRadio('idea')" />
          <label class="form-check-label" for="idea">Idea</label>
        </div>
        <div class="form-check">
          <input
            type="radio"
            class="form-check-input"
            formControlName="ideaOrPoc"
            value="poc"
            id="poc"
            (click)="toggleRadio('poc')"
            (keydown.enter)="toggleRadio('poc')" />
          <label class="form-check-label" for="poc">Poc</label>
        </div>
      </div>
    </div>

    <div class="form-group description-container">
      <label for="description" class="form-label">Description</label>
      <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
      <ngx-editor
        class="ngx-editor"
        [editor]="editor"
        [disabled]="false"
        [placeholder]="'Add a description'"
        [(ngModel)]="description"
        [ngModelOptions]="{standalone: true}">
      </ngx-editor>
    </div>

    <!-- Jira Key -->
    <div class="form-group">
      <label for="jiraProjectKey" class="form-label">Jira Key</label>
      <input type="text" class="form-control" id="jiraProjectKey" formControlName="jiraProjectKey" placeholder="Enter Jira key" />
    </div>

    <!-- CMDB Key -->
    <div class="form-group">
      <label for="finopsBsName" class="form-label">CMDB Key for FinOPS</label>
      <input type="text" class="form-control" id="finopsBsName" formControlName="finopsBsName" placeholder="Enter CMDB key for FinOPS" />
    </div>

    <!-- Submit Button -->
    <div class="form-actions">
      <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="addProductForm.invalid">
        {{ data?.product?.id ? "Update Product" : "Add Product" }}
      </button>
    </div>
  </form>
</div>
