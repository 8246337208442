<div class="accordion no-export">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" [id]="'accordion-btn-header-' + section">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#accordion-btn-collapse-' + section"
        aria-expanded="true"
        [attr.aria-controls]="'accordion-btn-collapse-' + section">
        <div class="container-left-accordion-title">
          <span class="material-icons-outlined color_red" aria-hidden="true" color="accent">
            {{ "devices_fold" }}
          </span>
          {{ "Focus" }}
        </div>
      </button>
      <div class="container-right">
        <div class="error-message" *ngIf="errorMessage">{{ errorMessage }}</div>
        <button type="button" class="btn btn-secondary" (click)="getDocument(focus)" *ngIf="focus">
          <span class="material-icons-outlined" aria-hidden="true">file_download</span>
          Focus On
        </button>
        <form class="upload-file">
          <input id="upload-input-focus" type="file" name="fileUpload" #uploadinput1 (change)="getFile($event)" />
          <label for="upload-input-focus" *ngIf="focus == null">
            <button type="button" class="btn btn-secondary" *ngIf="isWriter || isAdmin" (click)="uploadinput1.click()">
              <span class="spinner-border spinner-border-sm" output="status" aria-hidden="true" *ngIf="saveInProgress"></span>
              <span class="material-icons-outlined" aria-hidden="true">file_upload</span>
              Upload Focus On slides
            </button>
          </label>
          <span class="spinner-border spinner-border-sm spinner-edit" output="status" aria-hidden="true" *ngIf="focus && saveInProgress"></span>
          <button type="button" class="btn btn-sm btn-tertiary btn-circle" *ngIf="focus" (click)="uploadinput1.click()">
            <span class="material-icons-outlined">edit</span>
          </button>
          <button type="button" class="btn btn-sm btn-tertiary btn-circle" *ngIf="focus" (click)="deleteFocus()">
            <span class="material-icons-outlined">delete</span>
          </button>
        </form>
      </div>
    </h4>
  </div>
</div>
