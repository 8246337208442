<div class="accordion">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" [id]="'accordion-btn-header-pipeline-evolution'">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#accordion-btn-collapse-pipeline-evolution'"
        aria-expanded="true"
        [attr.aria-controls]="'accordion-btn-collapse-pipeline-evolution'">
        <div class="container-left-accordion-title">
          <span class="material-icons-outlined color_red" aria-hidden="true" color="accent">
            {{ "devices" }}
          </span>
          {{ "Pipeline Evolution" }}
        </div>
      </button>
    </h4>
    <div [id]="'accordion-btn-collapse-pipeline-evolution'" class="accordion-collapse show" [attr.aria-labelledby]="'accordion-btn-header-pipeline-evolution'">
      <div class="accordion-body">
        <div class="spinner-container">
          <div *ngIf="dataLoading" class="spinner-border text-primary" output="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <ng-container *ngIf="!dataLoading && info != null">
          <div class="pipeline-line">
            <div class="quarter-title">{{lastQuarterDisplay}}</div>
            <div class="pipeline-gauges">
              <ng-container *ngFor="let step of filterSteps('historicalCount'); let i = index">
                <div *ngIf="info[step.name]?.historicalCount > 0" [style.background]="step.color"
                     [style.width]="getWidthOfStep(step.name, 'historicalCount')" class="pipeline-gauge"
                     [ngClass]="{'first-gauge': i == 0, 'last-gauge': i == filterSteps('historicalCount').length - 1}"
                     [style]="{'color': step.name != 'BUILD/BUY' ? 'white' : 'black'}">{{info[step.name].historicalCount}}</div>
              </ng-container>
            </div>
          </div>
          <div class="pipeline-line">
            <div class="quarter-title">Today</div>
            <div class="pipeline-gauges">
              <ng-container *ngFor="let step of filterSteps('currentCount'); let i = index">
                <div *ngIf="info[step.name]?.currentCount > 0" [style.background]="step.color"
                     [style.width]="getWidthOfStep(step.name, 'currentCount')" class="pipeline-gauge"
                     [ngClass]="{'first-gauge': i == 0, 'last-gauge': i == filterSteps('currentCount').length - 1}"
                     [style]="{'color': step.name != 'BUILD/BUY' ? 'white' : 'black'}">{{info[step.name].currentCount}}</div>
              </ng-container>
            </div>
          </div>
          <div class="recap-steps">
            <div style="flex: 1;"></div>
            <div class="recap-steps-panel">
              <div *ngFor="let step of steps" class="one-step-container">
                <div [style.background]="step.color" class="step-title"
                     [style]="{'color': step.name != 'BUILD/BUY' ? 'white' : 'black'}">
                  {{step.name}}
                </div>
                <div *ngIf="info[step.name]?.newProducts.length > 0" data-cypress="new-products">
                  <span class="recap-title">+ {{info[step.name].newProducts.length}} new product{{info[step.name].newProducts.length > 1 ? 's' : ''}} : <br/></span>
                  <ng-container *ngFor="let product of info[step.name].newProducts">{{product}}<br /></ng-container>
                </div>
                <div *ngFor="let stepFrom of info[step.name]?.movedProductsFrom | keyvalue" data-cypress="moved-products-from">
                  <span class="recap-title">+ {{info[step.name].movedProductsFrom[stepFrom.key].length}} product{{info[step.name].movedProductsFrom[stepFrom.key].length > 1 ? 's' : ''}}
                    moved from {{stepFrom.key}} : <br/></span>
                  <ng-container *ngFor="let product of stepFrom.value">{{product}}<br /></ng-container>
                </div>
                <div *ngFor="let stepTo of info[step.name]?.movedProductsTo | keyvalue" data-cypress="moved-products-to">
                  <span class="recap-title">- {{info[step.name].movedProductsTo[stepTo.key].length}} product{{info[step.name].movedProductsTo[stepTo.key].length > 1 ? 's' : ''}}
                    moved to {{stepTo.key}} : <br/></span>
                  <ng-container *ngFor="let product of stepTo.value">{{product}}<br /></ng-container>
                </div>
                <div *ngIf="info[step.name]?.removedProducts.length > 0" data-cypress="removed-products">
                  <span class="recap-title">- {{info[step.name].removedProducts.length}} product{{info[step.name].removedProducts.length > 1 ? 's' : ''}} discarded : <br/></span>
                  <ng-container *ngFor="let product of info[step.name].removedProducts">{{product}}<br /></ng-container>
                </div>
                <div *ngIf="stepHasNoChanges(step.name)" data-cypress="no-changes">
                  <i>No changes.</i>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
