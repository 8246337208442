export class DateUtil {
  static dateWithoutTimezone(date_string: Date): string {
    const date = new Date(date_string);
    const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
    const withoutTimezone = new Date(date.valueOf() - tzoffset).toISOString().split("T")[0];
    return withoutTimezone;
  }

  static sortQuartersByYear(a: string, b: string): number {
    // Put Display All at the top if there is
    if (a == "Display All") return -1;
    if (b == "Display All") return 1;

    let yearA: number = a.includes("Q") ? parseInt(a.split("Q")[1]) : parseInt(a.split("+")[0]);
    let yearB: number = b.includes("Q") ? parseInt(b.split("Q")[1]) : parseInt(b.split("+")[0]);

    let quarterA: number = a.includes("Q") ? parseInt(a.split("Q")[0]) : 1000; // 1000 to ensure "XX+" is displayed at the end
    let quarterB: number = b.includes("Q") ? parseInt(b.split("Q")[0]) : 1000;

    // Comparing year, then quarter
    if (yearA < yearB) {
      return -1;
    } else if (yearA > yearB) {
      return 1;
    } else if (quarterA < quarterB) return -1;
    else return 1;
  }
}
