import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {Chart} from "chart.js";
import {ToastrService} from "ngx-toastr";
import {DpcComment, DpcCommentService} from "src/app/common/dpc-comment.service";
import {categories} from "src/app/common/util/const";

@Component({
  selector: "deploy-it-activity-distribution-per-quarter",
  templateUrl: "./deploy-it-activity-distribution-per-quarter.component.html",
  styleUrls: ["./deploy-it-activity-distribution-per-quarter.component.scss"],
})
export class DeployItActivityDistributionPerQuarterComponent implements OnChanges {
  @Input() domain: string; // DrillX or AiO
  @Input() branch: string; // EP or RC
  @Input() deployItActivityDistributionData: any;
  @Input() activityPerQuarterComment: {[branch: string]: {[domain: string]: DpcComment}};
  @Input() isWriter: boolean;
  @Input() lbus: any;
  @Input() canvasId: string;

  loading: boolean = true;
  hasData: boolean = false;
  quarters: string[] = ["Q1", "Q2", "Q3", "Q4"];
  meanChart: any;
  barColors = ["#9FA8DA", "#90CAF9", "#CE93D8", "#FFAB91", "#FFCC80", "#80CBC4"];
  categories = categories;
  constructor(private readonly toastrService: ToastrService, private readonly dpcCommentService: DpcCommentService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["deployItActivityDistributionData"]?.currentValue) {
      setTimeout(() => {
        this.createMeanChart();
      }, 0);
    }
  }

  selectDomain(domain: string): void {
    this.domain = domain;
    this.getActivityPerQuarterComment();
  }

  createMeanChart(): void {
    if (this.meanChart) this.meanChart.destroy();

    let datasets = [];

    for (let category of categories) {
      let datasetData = this.quarters.map((quarter) => {
        let data = this.deployItActivityDistributionData[quarter];
        if (data && typeof data === "object") {
          this.hasData = true;
          let products = Object.keys(data).filter(
            (key) => !key.startsWith("nbr_deployment") && !key.startsWith("new_products") && !key.startsWith("multi_product")
          );
          let filteredData = products.map((product) => data[product]);
          if (this.branch === "EP") {
            filteredData = filteredData.filter((product: any) => {
              if (this.domain === "DrillX") {
                return product.domains.includes("Drilling & Wells");
              } else {
                return !product.domains.includes("Drilling & Wells");
              }
            });
          }

          // Exclude products with all metrics equal to 0
          filteredData = filteredData.filter((product: any) => {
            return categories.some((category) => Number(product.metrics[category.key]) > 0);
          });

          // Calculate the average for the category
          const total = filteredData.reduce((sum: number, product: any) => sum + (Number(product.metrics[category.key]) || 0), 0);
          return filteredData.length > 0 ? total / filteredData.length : 0;
        }
        return 0;
      });

      datasets.push({
        label: category.name,
        data: datasetData,
        backgroundColor: this.barColors[categories.indexOf(category)],
        type: "bar",
        borderWidth: {
          top: 2,
          right: 0,
          bottom: 0,
          left: 0,
        },
        borderColor: "#FFF",
        borderRadius: 4,
      });
    }

    this.meanChart = new Chart(this.canvasId, {
      data: {
        labels: this.quarters,
        datasets: datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              font: {
                weight: "bold",
              },
            },
            grid: {
              display: false,
            },
          },
          y: {
            stacked: false,
            beginAtZero: true,
            ticks: {
              callback: (value) => {
                return value;
              },
              font: {
                weight: "bold",
              },
            },
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const datasetLabel = tooltipItem.dataset.label;
                const value = parseFloat(tooltipItem.raw.toString());

                return `${datasetLabel}: ${value.toFixed(1)} %`;
              },
            },
          },
          datalabels: {
            color: "white",
            display: (context) => {
              return context.dataset.data[context.dataIndex] !== 0;
            },
            font: {
              weight: "bold",
            },
            formatter: (value) => {
              return value.toFixed(1);
            },
          },
        },
      },
    });
    this.loading = false;
  }

  getActivityPerQuarterComment(): DpcComment | undefined {
    return this.activityPerQuarterComment?.[this.branch]?.[this.domain];
  }

  getCommentTitle(): string {
    let title: string = "";
    if (this.branch === "EP") {
      title = this.domain === "DrillX" ? "Activity per Quarter DrillX Comment" : "Activity per Quarter AiO Comment";
    } else {
      title = "Activity per Quarter Comment";
    }
    return title;
  }

  saveComment(commentText: string): void {
    let commentPayload: DpcComment;

    if (this.branch === "RC") {
      commentPayload = this.activityPerQuarterComment?.[this.branch]?.[""];
    } else {
      commentPayload = this.activityPerQuarterComment?.[this.branch]?.[this.domain];
    }

    if (commentText === "<p></p>") {
      commentText = null;
      commentPayload.comment = null;
    }

    let title: string = this.getCommentTitle();

    const updatedCommentPayload: DpcComment = {
      ...commentPayload,
      comment: commentText,
      postedOn: new Date(),
      quarter: "",
      branch: this.branch,
      title: title,
    };

    this.dpcCommentService.saveComment(updatedCommentPayload).subscribe(
      () => {
        this.toastrService.success(`Comment saved successfully!`);
        // Update the comment locally

        if (this.branch === "RC") {
          this.activityPerQuarterComment[this.branch][""].comment = commentText;
        } else {
          this.activityPerQuarterComment[this.branch][this.domain].comment = commentText;
        }
      },
      () => {
        this.toastrService.error(`Error while saving the comment.`);
      }
    );
  }

  isQuarterInPastOrCurrent(quarter: string): boolean {
    const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
    const quarterNumber = parseInt(quarter.split("Q")[1], 10);
    return quarterNumber <= currentQuarter;
  }
}
